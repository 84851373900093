/*
======================================
  * CSS TABLE CONTENT *  
======================================

1. GENERAL

2. HEADINGS

3. TEXT & PARAGRAPH

4. LISTS

5. BOX SPACING (inner & outer)
	>padding
	>marging
	
6. BOX MAX-WIDTH CSS

7. POSITIONS ( absolute & relative & statice & z-index )

8. COLOR (text & background)
	>text color
	>background color

9. IMAGES OVERLAY EFFECTS (overly animation on images)

10. IMAGES EFFECTS (image animation in images)
	 >image-opacity
	 >image-zoom
	 >image-zoom-slow
	 >image-shrink
	 >image-side-pan
	 >image-vertical-pan
	 >image-fade-in
	 >image-fade-out 
	 >image-rotate
	 >image-sepia
	 >image-blurr
	 >image-blurr-invert
	 >image off color
	 >image on color
 

11. CONTENT SHOW ON IMAGE BOX
 
12. FORM ELEMENTS

13. WP DEFAULT

14. HEADER (top part of page)

15. PAGE TOP BAR (left & right content)

16. LOGO ( company identify  symbol )

17. MAIN BAR ( place logo & main-navigation )

18. NAVIGATIONS
	>mena-menu
	>mobile-menu
	>footer-menu
	
19. BANNER

20. SLIDER
	>rs slider
	>owl slider
	
21. BLOG
	
22. WIDGETS

23. CUSTOMIZE CSS
	>set-radius
	>transition
	>float-clearfix
	
24. SORTCODES 
	>Buttons
	>Title separators		
	>Deviders	
	>Lists & bullets	
	>Tabs
	>Accordians		
	>Carousels	
	>Testimonials	
	>Pricing table
	>Alert box	
	>Modal pop
	>Social icons
	>Breadcrumb
	>Tables
	>Image effects

25. PAGINATION

26. FOOTER

27. PAGE-CONTENT

28. INNER-CONTENT

29. SIDE-NAV

30. CONTACT

31. SHOPPING

======================================
  * END TABLE CONTENT *  
======================================
*/


/*--------------------------------------------------------------
1. GENERAL
---------------------------------------------------------------*/

body,
html {
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #fff;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #6f6f6f;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

a {
    color: #6f6f6f;
    outline: 0 none;
    text-decoration: none;
}

a:hover,
a:focus {
    text-decoration: none;
    outline: 0 none;
}

a:active,
a:hover {
    color: #333333;
}

p a {
    color: #333333;
}

img {
    border-style: none;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

hr {
    clear: both;
}

section,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
summary {
    display: block;
}

address {
    font-style: italic;
}

table {
    background-color: transparent;
    width: 100%;
}

table thead th {
    color: #333;
}

table td {
    padding: 15px 10px;
}

p,
address,
pre,
hr,
ul,
ol,
dl,
dd,
table {
    margin-bottom: 24px;
}

::selection {
    color: #000;
    background: #dbdbdb;
}

::-moz-selection {
    color: #000;
    background: #dbdbdb;
}

.overflow-hide {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

.clear {
    clear: both;
}

.pull-none {
    float: none;
}

::-moz-selection {
    background: #3396d1;
    color: #fff;
}

::selection {
    background: #3396d1;
    color: #fff;
}

.op {
    font-family: "Open Sans", sans-serif;
}

.dis-block {
    display: block;
    width: 100%;
}


/*---------------------------------------------------------------
2. HEADINGS
---------------------------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #111111;
    font-family: Poppins;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #111111;
}

h1 {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 25px;
    font-weight: 700;
}

h2 {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 25px;
    font-weight: 700;
}

h3 {
    font-size: 28px;
    line-height: 35px;
    margin-bottom: 20px;
    font-weight: 700;
}

h4 {
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 15px;
    font-weight: 600;
}

h5 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: 600;
}

h6 {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
    font-weight: 600;
}


/*---------------------------------------------------------------
3. TEXT & PARAGRAPH
---------------------------------------------------------------*/

p {
    line-height: 28px;
    margin-bottom: 24px;
}

strong {
    font-weight: 400;
}

.bg-primary a:hover {
    color: #fff;
}


/*text align*/

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}


/*text vertical align*/

.v-align-t {
    vertical-align: top;
}

.v-align-m {
    vertical-align: middle;
}

.v-align-b {
    vertical-align: bottom;
}


/*text no line brack*/

.text-nowrap {
    white-space: nowrap;
}


/*text transform*/

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}


/*text size*/

.font-10 {
    font-size: 10px;
}

.font-12 {
    font-size: 12px;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-26 {
    font-size: 26px;
}

.font-30 {
    font-size: 30px;
    line-height: 40px;
}

.font-35 {
    font-size: 35px;
    line-height: 45px;
}

.font-40 {
    font-size: 40px;
    line-height: 50px;
}

.font-45 {
    font-size: 45px;
    line-height: 55px;
}

.font-50 {
    font-size: 50px;
    line-height: 65px;
}

.font-55 {
    font-size: 55px;
    line-height: 65px;
}

.font-60 {
    font-size: 60px;
    line-height: 70px;
}

.font-70 {
    font-size: 70px;
    line-height: 80px;
}

.font-75 {
    font-size: 75px;
    line-height: 85px;
}

.font-80 {
    font-size: 80px;
    line-height: 90px;
}

.font-90 {
    font-size: 90px;
    line-height: 100px;
}

.font-100 {
    font-size: 100px;
    line-height: 110px;
}


/*text weight*/

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-weight-800 {
    font-weight: 800;
}

.font-weight-900 {
    font-weight: 900;
}


/*---------------------------------------------------------------
4. LISTS
---------------------------------------------------------------*/

dl,
ul,
ol {
    list-style-position: outside;
    padding: 0;
}

ul,
ol {
    margin-bottom: 24px;
}

ul li,
ol li {
    padding: 0;
}

dl {
    margin-left: 0;
    margin-bottom: 30px;
}

dl dd {
    margin-bottom: 10px;
}

.list-inline>li {
    padding-left: 2px;
    padding-right: 2px;
    display: inline-block;
}


/*---------------------------------------------------------------
5. BOX SPACING (inner & outer)
	>padding
	>marging
---------------------------------------------------------------*/


/*== >padding (around) ======*/

.p-a0 {
    padding: 0;
}

.p-a5 {
    padding: 5px;
}

.p-a10 {
    padding: 10px;
}

.p-a15 {
    padding: 15px;
}

.p-a20 {
    padding: 20px;
}

.p-a25 {
    padding: 25px;
}

.p-a30 {
    padding: 30px;
}

.p-a40 {
    padding: 40px;
}

.p-a50 {
    padding: 50px;
}

.p-a60 {
    padding: 60px;
}

.p-a70 {
    padding: 70px;
}

.p-a80 {
    padding: 80px;
}

.p-a90 {
    padding: 90px;
}

.p-a100 {
    padding: 100px;
}


/*== >Padding (top) ==*/

.p-t0 {
    padding-top: 0;
}

.p-t5 {
    padding-top: 5px;
}

.p-t10 {
    padding-top: 10px;
}

.p-t15 {
    padding-top: 15px;
}

.p-t20 {
    padding-top: 20px;
}

.p-t30 {
    padding-top: 30px;
}

.p-t40 {
    padding-top: 40px;
}

.p-t50 {
    padding-top: 50px;
}

.p-t60 {
    padding-top: 60px;
}

.p-t70 {
    padding-top: 70px;
}

.p-t80 {
    padding-top: 80px;
}

.p-t90 {
    padding-top: 90px;
}

.p-t100 {
    padding-top: 100px;
}


/*== >Padding (bottom) ==*/

.p-b0 {
    padding-bottom: 0;
}

.p-b5 {
    padding-bottom: 5px;
}

.p-b10 {
    padding-bottom: 10px;
}

.p-b15 {
    padding-bottom: 15px;
}

.p-b20 {
    padding-bottom: 20px;
}

.p-b30 {
    padding-bottom: 30px;
}

.p-b40 {
    padding-bottom: 40px;
}

.p-b50 {
    padding-bottom: 50px;
}

.p-b60 {
    padding-bottom: 60px;
}

.p-b70 {
    padding-bottom: 70px;
}

.p-b80 {
    padding-bottom: 80px;
}

.p-b90 {
    padding-bottom: 90px;
}

.p-b100 {
    padding-bottom: 100px;
}


/*== >Padding (left) ==*/

.p-l0 {
    padding-left: 0;
}

.p-l5 {
    padding-left: 5px;
}

.p-l10 {
    padding-left: 10px;
}

.p-l15 {
    padding-left: 15px;
}

.p-l20 {
    padding-left: 20px;
}

.p-l30 {
    padding-left: 30px;
}

.p-l40 {
    padding-left: 40px;
}

.p-l50 {
    padding-left: 50px;
}

.p-l60 {
    padding-left: 60px;
}

.p-l70 {
    padding-left: 70px;
}

.p-l80 {
    padding-left: 80px;
}

.p-l90 {
    padding-left: 90px;
}

.p-l100 {
    padding-left: 100px;
}


/*== >Padding (right) ==*/

.p-r0 {
    padding-right: 0;
}

.p-r5 {
    padding-right: 5px;
}

.p-r10 {
    padding-right: 10px;
}

.p-r15 {
    padding-right: 15px;
}

.p-r20 {
    padding-right: 20px;
}

.p-r30 {
    padding-right: 30px;
}

.p-r40 {
    padding-right: 40px;
}

.p-r50 {
    padding-right: 50px;
}

.p-r60 {
    padding-right: 60px;
}

.p-r70 {
    padding-right: 70px;
}

.p-r80 {
    padding-right: 80px;
}

.p-r90 {
    padding-right: 90px;
}

.p-r100 {
    padding-right: 100px;
}


/*== >Padding (left right) ==*/

.p-lr0 {
    padding-left: 0;
    padding-right: 0;
}

.p-lr5 {
    padding-left: 5px;
    padding-right: 5px;
}

.p-lr10 {
    padding-left: 10px;
    padding-right: 10px;
}

.p-lr15 {
    padding-left: 15px;
    padding-right: 15px;
}

.p-lr20 {
    padding-left: 20px;
    padding-right: 20px;
}

.p-lr30 {
    padding-left: 30px;
    padding-right: 30px;
}

.p-lr40 {
    padding-left: 40px;
    padding-right: 40px;
}

.p-lr50 {
    padding-left: 50px;
    padding-right: 50px;
}

.p-lr60 {
    padding-left: 60px;
    padding-right: 60px;
}

.p-lr70 {
    padding-left: 70px;
    padding-right: 70px;
}

.p-lr80 {
    padding-left: 80px;
    padding-right: 80px;
}

.p-lr90 {
    padding-left: 90px;
    padding-right: 90px;
}

.p-lr100 {
    padding-left: 100px;
    padding-right: 100px;
}


/*== >Padding (top bottom) ==*/

.p-tb0 {
    padding-bottom: 0;
    padding-top: 0;
}

.p-tb5 {
    padding-bottom: 5px;
    padding-top: 5px;
}

.p-tb10 {
    padding-bottom: 10px;
    padding-top: 10px;
}

.p-tb15 {
    padding-bottom: 15px;
    padding-top: 15px;
}

.p-tb20 {
    padding-bottom: 20px;
    padding-top: 20px;
}

.p-tb30 {
    padding-bottom: 30px;
    padding-top: 30px;
}

.p-tb40 {
    padding-bottom: 40px;
    padding-top: 40px;
}

.p-tb50 {
    padding-bottom: 50px;
    padding-top: 50px;
}

.p-tb60 {
    padding-bottom: 60px;
    padding-top: 60px;
}

.p-tb70 {
    padding-bottom: 70px;
    padding-top: 70px;
}

.p-tb80 {
    padding-bottom: 80px;
    padding-top: 80px;
}

.p-tb90 {
    padding-bottom: 90px;
    padding-top: 90px;
}

.p-tb100 {
    padding-bottom: 100px;
    padding-top: 100px;
}


/*== >margin (around) ======*/

.m-auto {
    margin: auto;
}

.m-a0 {
    margin: 0;
}

.m-a5 {
    margin: 5px;
}

.m-a10 {
    margin: 10px;
}

.m-a15 {
    margin: 15px;
}

.m-a20 {
    margin: 20px;
}

.m-a30 {
    margin: 30px;
}

.m-a40 {
    margin: 40px;
}

.m-a50 {
    margin: 50px;
}

.m-a60 {
    margin: 60px;
}

.m-a70 {
    margin: 70px;
}

.m-a80 {
    margin: 80px;
}

.m-a90 {
    margin: 90px;
}

.m-a100 {
    margin: 100px;
}


/*== >marging (top) ==*/

.m-t0 {
    margin-top: 0;
}

.m-t5 {
    margin-top: 5px;
}

.m-t10 {
    margin-top: 10px;
}

.m-t15 {
    margin-top: 15px;
}

.m-t20 {
    margin-top: 20px;
}

.m-t30 {
    margin-top: 30px;
}

.m-t40 {
    margin-top: 40px;
}

.m-t50 {
    margin-top: 50px;
}

.m-t60 {
    margin-top: 60px;
}

.m-t70 {
    margin-top: 70px;
}

.m-t80 {
    margin-top: 80px;
}

.m-t90 {
    margin-top: 90px;
}

.m-t100 {
    margin-top: 100px;
}


/*== >marging (bottom) ==*/

.m-b0 {
    margin-bottom: 0;
}

.m-b5 {
    margin-bottom: 5px;
}

.m-b10 {
    margin-bottom: 10px;
}

.m-b15 {
    margin-bottom: 15px;
}

.m-b20 {
    margin-bottom: 20px;
}

.m-b30 {
    margin-bottom: 30px;
}

.m-b40 {
    margin-bottom: 40px;
}

.m-b50 {
    margin-bottom: 50px;
}

.m-b60 {
    margin-bottom: 60px;
}

.m-b70 {
    margin-bottom: 70px;
}

.m-b80 {
    margin-bottom: 80px;
}

.m-b90 {
    margin-bottom: 90px;
}

.m-b100 {
    margin-bottom: 100px;
}


/*== >marging (left) ==*/

.m-l0 {
    margin-left: 0;
}

.m-l5 {
    margin-left: 5px;
}

.m-l10 {
    margin-left: 10px;
}

.m-l15 {
    margin-left: 15px;
}

.m-l20 {
    margin-left: 20px;
}

.m-l30 {
    margin-left: 30px;
}

.m-l40 {
    margin-left: 40px;
}

.m-l50 {
    margin-left: 50px;
}

.m-l60 {
    margin-left: 60px;
}

.m-l70 {
    margin-left: 70px;
}

.m-l80 {
    margin-left: 80px;
}

.m-l90 {
    margin-left: 90px;
}

.m-l100 {
    margin-left: 100px;
}


/*== >marging (right) ==*/

.m-r0 {
    margin-right: 0;
}

.m-r5 {
    margin-right: 5px;
}

.m-r10 {
    margin-right: 10px;
}

.m-r15 {
    margin-right: 15px;
}

.m-r20 {
    margin-right: 20px;
}

.m-r30 {
    margin-right: 30px;
}

.m-r40 {
    margin-right: 40px;
}

.m-r50 {
    margin-right: 50px;
}

.m-r60 {
    margin-right: 60px;
}

.m-r70 {
    margin-right: 70px;
}

.m-r80 {
    margin-right: 80px;
}

.m-r90 {
    margin-right: 90px;
}

.m-r100 {
    margin-right: 100px;
}


/*== >marging (left right) ==*/

.m-lr0 {
    margin-left: 0;
    margin-right: 0;
}

.m-lr5 {
    margin-left: 5px;
    margin-right: 5px;
}

.m-lr10 {
    margin-left: 10px;
    margin-right: 10px;
}

.m-lr15 {
    margin-left: 15px;
    margin-right: 15px;
}

.m-lr20 {
    margin-left: 20px;
    margin-right: 20px;
}

.m-lr30 {
    margin-left: 30px;
    margin-right: 30px;
}

.m-lr40 {
    margin-left: 40px;
    margin-right: 40px;
}

.m-lr50 {
    margin-left: 50px;
    margin-right: 50px;
}

.m-lr60 {
    margin-left: 60px;
    margin-right: 60px;
}

.m-lr70 {
    margin-left: 70px;
    margin-right: 70px;
}

.m-lr80 {
    margin-left: 80px;
    margin-right: 80px;
}

.m-lr90 {
    margin-left: 90px;
    margin-right: 90px;
}

.m-lr100 {
    margin-left: 100px;
    margin-right: 100px;
}


/*== >marging (top bottom) ==*/

.m-tb0 {
    margin-bottom: 0;
    margin-top: 0;
}

.m-tb5 {
    margin-bottom: 5px;
    margin-top: 5px;
}

.m-tb10 {
    margin-bottom: 10px;
    margin-top: 10px;
}

.m-tb15 {
    margin-bottom: 15px;
    margin-top: 15px;
}

.m-tb20 {
    margin-bottom: 20px;
    margin-top: 20px;
}

.m-tb30 {
    margin-bottom: 30px;
    margin-top: 30px;
}

.m-tb40 {
    margin-bottom: 40px;
    margin-top: 40px;
}

.m-tb50 {
    margin-bottom: 50px;
    margin-top: 50px;
}

.m-tb60 {
    margin-bottom: 60px;
    margin-top: 60px;
}

.m-tb70 {
    margin-bottom: 70px;
    margin-top: 70px;
}

.m-tb80 {
    margin-bottom: 80px;
    margin-top: 80px;
}

.m-tb90 {
    margin-bottom: 90px;
    margin-top: 90px;
}

.m-tb100 {
    margin-bottom: 100px;
    margin-top: 100px;
}


/*== > colum gap less ==*/

.no-col-gap [class*="col-xs-"],
.no-col-gap [class*="col-sm-"],
.no-col-gap [class*="col-md-"],
.no-col-gap [class*="col-lg-"] {
    padding-left: 0;
    padding-right: 0;
}


/*---------------------------------------------------------------
6. BOX MAX-WIDTH CSS
---------------------------------------------------------------*/

.max-w50 {
    max-width: 50px;
}

.max-w60 {
    max-width: 60px;
}

.max-w80 {
    max-width: 80px;
}

.max-w100 {
    max-width: 100px;
}

.max-w200 {
    max-width: 200px;
}

.max-w300 {
    max-width: 300px;
}

.max-w400 {
    max-width: 400px;
}

.max-w500 {
    max-width: 500px;
}

.max-w600 {
    max-width: 600px;
}

.max-w700 {
    max-width: 700px;
}

.max-w800 {
    max-width: 800px;
}

.max-w900 {
    max-width: 900px;
}

.max-w1000 {
    max-width: 1000px;
}


/*---------------------------------------------------------------
7. POSITIONS ( absolute & relative & statice & z-index )
---------------------------------------------------------------*/

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.static {
    position: static;
}

.z-index1 {
    z-index: 1;
}

.z-index2 {
    z-index: 2;
}

.z-index3 {
    z-index: 3;
}

.z-index4 {
    z-index: 4;
}

.z-index5 {
    z-index: 5;
}

.z-index6 {
    z-index: 6;
}

.z-index7 {
    z-index: 7;
}

.z-index8 {
    z-index: 8;
}

.z-index9 {
    z-index: 9;
}

.z-index10 {
    z-index: 10;
}

.z-index100 {
    z-index: 100;
}

.z-index999 {
    z-index: 999;
}


/*---------------------------------------------------------------
8. COLOR (text & background)
	>text color
	>background color
---------------------------------------------------------------*/


/*=== >text color ===*/

.text-white {
    color: #fff;
}

.text-black {
    color: #000;
}

.text-gray {
    color: #f5f6f6;
}

.text-gray-dark {
    color: #d3d3d3;
}

.text-red {
    color: #ff4045;
}

.text-yellow {
    color: #ffb822;
}

.text-yellow-light {
    color: #fec215;
}

.text-pink {
    color: #ff3c85;
}

.text-blue {
    color: #00c5dc;
}

.text-green {
    color: #34bfa3;
}

.text-orange {
    color: #ef9800;
}


/*=== >background color ===*/

.bg-transparent {
    background-color: transparent;
}

.bg-primary {
    background-color: ;
}

.bg-white {
    background-color: #fff;
}

.bg-black {
    background-color: #000;
}

.bg-black-light {
    background-color: #1c1c1c;
}

.bg-gray {
    background-color: #f7f9fb;
}

.bg-gray-dark {
    background-color: #d3d3d3;
}

.bg-red {
    background-color: #ff4045;
}

.bg-green {
    background-color: #34bfa3;
}

.bg-yellow {
    background-color: #ffb822;
}

.bg-pink {
    background-color: #ff3c85;
}

.bg-blue {
    background-color: #00c5dc;
}

.bg-orange {
    background-color: #ef9800;
}


/* bg transperent */

.bg-tpw1 {
    background-color: rgba(255, 255, 255, 0.1);
}

.bg-tpw2 {
    background-color: rgba(255, 255, 255, 0.2);
}

.bg-tpw3 {
    background-color: rgba(255, 255, 255, 0.3);
}

.bg-tpw4 {
    background-color: rgba(255, 255, 255, 0.4);
}

.bg-tpw5 {
    background-color: rgba(255, 255, 255, 0.5);
}

.bg-tpw6 {
    background-color: rgba(255, 255, 255, 0.6);
}

.bg-tpw7 {
    background-color: rgba(255, 255, 255, 0.7);
}

.bg-tpw8 {
    background-color: rgba(255, 255, 255, 0.8);
}

.bg-tpw9 {
    background-color: rgba(255, 255, 255, 0.9);
}


/* Tb Black */

.bg-tpb1 {
    background-color: rgba(0, 0, 0, 0.05);
}

.bg-tpb2 {
    background-color: rgba(0, 0, 0, 0.2);
}

.bg-tpb3 {
    background-color: rgba(0, 0, 0, 0.3);
}

.bg-tpb4 {
    background-color: rgba(0, 0, 0, 0.4);
}

.bg-tpb5 {
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-tpb6 {
    background-color: rgba(0, 0, 0, 0.6);
}

.bg-tpb7 {
    background-color: rgba(0, 0, 0, 0.7);
}

.bg-tpb8 {
    background-color: rgba(0, 0, 0, 0.8);
}

.bg-tpb9 {
    background-color: rgba(0, 0, 0, 0.9);
}


/*=== >background image ===*/

.bg-img-fix {
    background-attachment: fixed;
    background-size: cover;
}

.dlab-bnr-inr.bg-pt,
.bg-pt {
    background-position: top;
    background-size: cover;
}

.dlab-bnr-inr.bg-pb,
.bg-pb {
    background-position: bottom;
    background-size: cover;
}


/*---------------------------------------------------------------
9. IMAGES OVERLAY EFFECTS (overly animation on images)
---------------------------------------------------------------*/

.dlab-img-overlay1,
.dlab-img-overlay2,
.dlab-img-overlay3,
.dlab-img-overlay4,
.dlab-img-overlay5,
.dlab-img-overlay6,
.dlab-img-overlay7,
.dlab-img-overlay8,
.dlab-img-overlay9,
.dlab-img-overlay11,
.dlab-img-overlay12 {
    position: relative;
}

.dlab-img-overlay1:before,
.dlab-img-overlay1:after,
.dlab-img-overlay2:before,
.dlab-img-overlay2:after,
.dlab-img-overlay3:before,
.dlab-img-overlay3:after,
.dlab-img-overlay4:before,
.dlab-img-overlay4:after,
.dlab-img-overlay5:before,
.dlab-img-overlay5:after,
.dlab-img-overlay6:before,
.dlab-img-overlay6:after,
.dlab-img-overlay7:before,
.dlab-img-overlay7:after,
.dlab-img-overlay8:before,
.dlab-img-overlay8:after,
.dlab-img-overlay9:before,
.dlab-img-overlay9:after,
.dlab-img-overlay11:before,
.dlab-img-overlay11:after,
.dlab-img-overlay12:before,
.dlab-img-overlay12:after {
    content: "";
    background: #000;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0.4;
    z-index: 1;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    transition: all 0.3s linear;
}


/*effect 1*/

.dlab-img-overlay1:before {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.dlab-img-overlay1:hover:before,
.dlab-box:hover .dlab-img-overlay1:before {
    opacity: 0.5;
}


/*effect 2*/

.dlab-img-overlay2:before {
    width: 100%;
    height: 100%;
    opacity: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
    background: -moz-linear-gradient( top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient( top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient( to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
    /* IE6-9 */
}

.dlab-img-overlay2.on:before {
    opacity: 1;
}

.dlab-img-overlay2:hover:before,
.dlab-box:hover .dlab-img-overlay2:before {
    opacity: 0.9;
}


/*effect 3*/

.dlab-img-overlay3:before {
    left: 50%;
    top: 50%;
}

.dlab-img-overlay3:hover:before,
.dlab-box:hover .dlab-img-overlay3:before {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}


/*effect 4*/

.dlab-img-overlay4:before {
    left: 50%;
    top: 0;
    height: 100%;
}

.dlab-img-overlay4:hover:before,
.dlab-box:hover .dlab-img-overlay4:before {
    width: 100%;
    left: 0;
}


/*effect 5*/

.dlab-img-overlay5:before {
    left: 0;
    top: 50%;
    width: 100%;
}

.dlab-img-overlay5:hover:before,
.dlab-box:hover .dlab-img-overlay5:before {
    height: 100%;
    top: 0;
}


/*effect 6*/

.dlab-img-overlay6:before {
    left: 0;
    top: auto;
    bottom: 0;
    width: 100%;
}

.dlab-img-overlay6:hover:before,
.dlab-box:hover .dlab-img-overlay6:before {
    height: 100%;
}


/*effect 7*/

.dlab-img-overlay7:before,
.dlab-img-overlay7:after {
    height: 100%;
}

.dlab-img-overlay7:after {
    left: auto;
    right: 0;
}

.dlab-img-overlay7:hover:before,
.dlab-img-overlay7:hover:after,
.dlab-box:hover .dlab-img-overlay7:before,
.dlab-box:hover .dlab-img-overlay7:after {
    width: 50%;
}


/*effect 8*/

.dlab-img-overlay8:before,
.dlab-img-overlay8:after {
    height: 100%;
}

.dlab-img-overlay8:after {
    left: auto;
    right: 0;
}

.dlab-img-overlay8:hover:before,
.dlab-img-overlay8:hover:after,
.dlab-box:hover .dlab-img-overlay8:before,
.dlab-box:hover .dlab-img-overlay8:after {
    width: 100%;
    opacity: 0.3;
}


/*effect 9*/

.dlab-img-overlay9:before,
.dlab-img-overlay9:after {}

.dlab-img-overlay9:after {
    left: auto;
    right: 0;
    top: auto;
    bottom: 0;
}

.dlab-img-overlay9:hover:before,
.dlab-img-overlay9:hover:after,
.dlab-box:hover .dlab-img-overlay9:before,
.dlab-box:hover .dlab-img-overlay9:after {
    width: 100%;
    height: 100%;
    opacity: 0.3;
}


/*effect 10*/

.dlab-img-overlay10:before,
.dlab-img-overlay10:after {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.dlab-img-overlay10:hover:before,
.dlab-box:hover .dlab-img-overlay10:before {
    opacity: 0.5;
}

.dlab-img-overlay10:hover:after,
.dlab-box:hover .dlab-img-overlay10:after {
    width: 90%;
    height: 80%;
    left: 5%;
    top: 10%;
    opacity: 0.5;
    background: none;
    border: 1px dashed #ffffff;
}

.dlab-img-overlay11:hover:after,
.dlab-box:hover .dlab-img-overlay11:after {
    height: 95%;
    opacity: 1;
    right: 7.5%;
    top: 2.5%;
    -moz-transform: skewY(0deg);
    -webkit-transform: skewY(0deg);
    -o-transform: skewY(0deg);
    -ms-transform: skewY(0deg);
    transform: skewY(0deg);
    width: 85%;
}

.dlab-img-overlay11:hover:before,
.dlab-box:hover .dlab-img-overlay11:before {
    height: 85%;
    left: 2.5%;
    opacity: 1;
    top: 7.5%;
    -moz-transform: skewY(0deg);
    -webkit-transform: skewY(0deg);
    -o-transform: skewY(0deg);
    -ms-transform: skewY(0deg);
    transform: skewY(0deg);
    width: 95%;
}

.dlab-img-overlay11:after {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    bottom: 5%;
    height: 80%;
    left: auto;
    right: 5%;
    top: auto;
    -moz-transform: skewY(70deg);
    -webkit-transform: skewY(70deg);
    -o-transform: skewY(70deg);
    -ms-transform: skewY(70deg);
    transform: skewY(70deg);
    width: 90%;
}

.form-area {
    min-height: 670px;
}

.submit-button {
    margin-left: 10px;
}

.consultation-form {
    min-height: 576.84px;
}

.dlab-img-overlay11:before {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    bottom: auto;
    height: 90%;
    left: 5%;
    right: auto;
    top: 5%;
    -moz-transform: skewX(-70deg);
    -webkit-transform: skewX(-70deg);
    -o-transform: skewX(-70deg);
    -ms-transform: skewX(-70deg);
    transform: skewX(-70deg);
    width: 80%;
}

.dlab-img-overlay11 {
    background-color: #000 !important;
}

.dlab-img-overlay11:hover img {
    opacity: 0.7 !important;
}


/* overlay-12 */

.dlab-img-overlay12:hover:after,
.dlab-box:hover .dlab-img-overlay12:after {
    opacity: 1;
    transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1);
    -moz-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1);
    -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1);
    -o-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 1, 1);
}

.dlab-img-overlay12:after {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 2px solid #ffffff;
    border-top: 2px solid #ffffff;
    transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
    -moz-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
    -o-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
    -webkit-transform: rotate3d(0, 0, 1, 45deg) scale3d(1, 0, 1);
    -moz-transform-origin: 50% 50% 0;
    -webkit-transform-origin: 50% 50% 0;
    transform-origin: 50% 50% 0;
    -o-transform-origin: 50% 50% 0;
    width: 100%;
    height: 100%;
}

.dlab-img-overlay12.left:hover:after,
.dlab-box:hover .dlab-img-overlay12.left:after {
    transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
    -moz-transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
    -webkit-transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
    -o-transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 1, 1);
}

.dlab-img-overlay12.left:after {
    border-bottom: 2px solid #ffffff;
    border-top: 2px solid #ffffff;
    transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 0, 1);
    -moz-transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 0, 1);
    -o-transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 0, 1);
    -webkit-transform: rotate3d(0, 0, 1, -45deg) scale3d(1, 0, 1);
    -moz-transform-origin: 50% 50% 0;
    -webkit-transform-origin: 50% 50% 0;
    transform-origin: 50% 50% 0;
    -o-transform-origin: 50% 50% 0;
}

.dlab-img-overlay12:before {
    width: 100%;
    height: 100%;
    opacity: 0;
}

.dlab-img-overlay12:hover:before,
.dlab-box:hover .dlab-img-overlay12:before {
    opacity: 0.4;
}


/* Overlay Gradient */


/*---------------------------------------------------------------
10. IMAGES EFFECTS (image animation in images)
	 >image-opacity
	 >image-zoom
	 >image-zoom-slow
	 >image-shrink
	 >image-side-pan
	 >image-vertical-pan
	 >image-fade-in
	 >image-fade-out 
	 >image-rotate
	 >image-sepia
	 >image-blurr
	 >image-blurr-invert
	 >image off color
	 >image on color
---------------------------------------------------------------*/

.dlab-img-effect {
    position: relative;
    overflow: hidden;
    display: block;
    vertical-align: middle;
}

.dlab-img-effect img {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: all 0.25s;
    -moz-transition: all 0.25s;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
}


/* >image-opacity */

.dlab-img-effect.opacity img:hover {
    opacity: 0.8;
    -moz-opacity: 0.8;
    -webkit-opacity: 0.8;
}


/* >image-zoom */

.dlab-img-effect.zoom-slow img {
    transition: all 10s;
    -moz-transition: all 10s;
    -webkit-transition: all 10s;
    -o-transition: all 10s;
}

.dlab-img-effect.zoom-slow:hover img {
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}


/* >image-zoom-slow */

.dlab-img-effect.zoom:hover img {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
}


/* >image-shrink */

.dlab-img-effect.shrink:hover img {
    transform: scale(0.8);
    -ms-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -o-transform: scale(0.8);
    -moz-transform: scale(0.8);
}


/* >image-side-pan */

.dlab-img-effect.side-pan:hover img {
    margin-left: -11%;
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -moz-transform: scale(1.2);
}


/* >image-vertical-pan */

.dlab-img-effect.vertical-pan:hover img {
    margin-top: -10%;
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -o-transform: scale(1.2);
    -moz-transform: scale(1.2);
}


/* >image-fade-in */

.dlab-img-effect.fade-in {
    background: #000;
}

.dlab-img-effect.fade-in img {
    opacity: 0.65;
    -moz-opacity: 0.65;
    -webkit-opacity: 0.65;
}

.dlab-img-effect.fade-in:hover img {
    opacity: 1;
    -moz-opacity: 1;
    -webkit-opacity: 1;
}


/* >image-fade-out */

.dlab-img-effect.fade-out {
    background: #000;
}

.dlab-img-effect.fade-out:hover img {
    opacity: 0.7;
    -moz-opacity: 0.7;
    -webkit-opacity: 0.7;
}


/* >image-rotate */

.dlab-box:hover .dlab-img-effect.rotate img,
.dlab-img-effect.rotate:hover img {
    -moz-transform: scale(1.5) rotate(-20deg);
    -webkit-transform: scale(1.5) rotate(-20deg);
    -o-transform: scale(1.5) rotate(-20deg);
    -ms-transform: scale(1.5) rotate(-20deg);
    transform: scale(1.5) rotate(-20deg);
}


/* >image-sepia */

.dlab-img-effect.sepia img {
    -webkit-filter: sepia(100%);
    filter: sepia(100%);
}

.algo-image-hover.sepia:hover img {
    -webkit-filter: sepia(0);
    filter: sepia(0);
}


/* >image-blurr */

.dlab-img-effect.blurr img {
    transition: all 0.2s;
    -moz-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
}

.dlab-img-effect.blurr:hover img {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
}


/* >image-blurr-invert */

.dlab-img-effect.blurr-invert img {
    transition: all 0.2s;
    -moz-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
}

.dlab-img-effect.blurr-invert img {
    filter: blur(3px);
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
}

.dlab-img-effect.blurr-invert:hover img {
    filter: blur(0px);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
}


/* >image off color */

.dlab-img-effect.off-color img {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -o-filter: grayscale(0);
    -ms-filter: grayscale(0);
}

.dlab-img-effect.off-color:hover img {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);
    -ms-filter: grayscale(1);
}


/* >image on color */

.dlab-img-effect.on-color img {
    filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);
    -ms-filter: grayscale(1);
}

.dlab-img-effect.on-color:hover img {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -o-filter: grayscale(0);
    -ms-filter: grayscale(0);
}


/*---------------------------------------------------------------
11. CONTENT SHOW ON IMAGE BOX
---------------------------------------------------------------*/


/* info box show */

.dlab-info-has {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: 30px 15px 40px;
    opacity: 0;
    margin-bottom: -100px;
    z-index: 2;
}

.dlab-info-has.p-a15 {
    padding: 15px;
}

.dlab-info-has.p-a20 {
    padding: 20px;
}

.dlab-info-has.bg-primary {
    background-color: rgba(27, 188, 232, 0.9);
}

.dlab-info-has.bg-green {
    background-color: rgba(106, 179, 62, 0.9);
}

.dlab-info-has.bg-black {
    background-color: rgba(0, 0, 0, 0.6);
}

.dlab-info-has.bg-white {
    background-color: rgba(255, 255, 255, 0.8);
}

.dlab-info-has.bg-white h1,
.dlab-info-has.bg-white h2,
.dlab-info-has.bg-white h3,
.dlab-info-has.bg-white h4,
.dlab-info-has.bg-white h5,
.dlab-info-has.bg-white h6 {
    color: #3d474a;
}

.dlab-info-has.bg-white h1 a,
.dlab-info-has.bg-white h2 a,
.dlab-info-has.bg-white h3 a,
.dlab-info-has.bg-white h4 a,
.dlab-info-has.bg-white h5 a,
.dlab-info-has.bg-white h6 a {
    color: #3d474a;
}

.dlab-info-has.bg-white .dlab-info-has-text,
.dlab-info-has.bg-white p {
    color: #767676;
}

.dlab-info-has h1,
.dlab-info-has h2,
.dlab-info-has h3,
.dlab-info-has h4,
.dlab-info-has h5,
.dlab-info-has h6,
.dlab-info-has p,
.dlab-info-has-text,
.dlab-info-has a {
    color: #fff;
}

.dlab-info-has .dlab-info-has-text {
    margin-bottom: 20px;
    line-height: 24px;
}

.dlab-media {
    overflow: hidden;
    position: relative;
}

.dlab-box:hover .dlab-info-has,
.dlab-media:hover .dlab-info-has {
    opacity: 1;
    width: 100%;
    margin-bottom: 0;
}


/* without hover */

.overlay-bx.no-hover,
.dlab-info-has.no-hover {
    opacity: 1;
    width: 100%;
    margin-bottom: 0;
    visibility: visible;
}


/* Skew */

.dlab-info-has.skew-has {
    border-top: 4px solid #2d3239;
    padding: 30px 40px 60px;
    -moz-transform: skewY(10deg);
    -webkit-transform: skewY(10deg);
    -o-transform: skewY(10deg);
    -ms-transform: skewY(10deg);
    transform: skewY(10deg);
    bottom: -35px;
}

.dlab-info-has.skew-has>* {
    -moz-transform: skewY(-10deg);
    -webkit-transform: skewY(-10deg);
    -o-transform: skewY(-10deg);
    -ms-transform: skewY(-10deg);
    transform: skewY(-10deg);
}


/*---------------------------------------------------------------
12. FORM ELEMENTS
---------------------------------------------------------------*/

label {
    font-weight: 600;
    margin-bottom: 10px;
}

.panel-default,
.panel-default>.panel-heading {
    border-color: #e7ecf1;
    color: inherit;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #e7ecf1;
}

.form-group {
    margin-bottom: 25px;
}

.form-control {
    border-color: #e1e6eb;
    box-shadow: none;
    height: 40px;
    font-size: 13px;
    line-height: 20px;
    padding: 9px 12px;
}

.form-control:focus {
    border-color: #e1e1e1;
    outline: 0;
}

.form-control:focus,
.has-error .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-control.kv-fileinput-caption {
    height: 39px;
}

.form-group.form-inline .radio,
.form-group.form-inline .checkbox {
    margin-right: 15px;
}


/*input group*/

.input-group {
    width: 100%;
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
    display: table-cell;
}

.input-group-addon {
    background: #fff;
    border-color: #e1e1e1;
    padding: 6px 14px;
    font-size: 16px;
}

.input-group-addon.font-size-20 {
    font-size: 20px;
}

.input-group-addon.fixed-w {
    text-align: center;
    padding: 6px 0;
    width: 40px;
}

.input-group-addon.v-align-t {
    line-height: 30px;
}


/*select box*/

.dropdown-menu {
    font-size: 13px;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}

.bootstrap-select .dropdown-toggle {
    border: 1px solid #e7ecf1 !important;
    background-color: #fff !important;
    height: 40px;
    font-size: 13px;
    color: #999;
}

.bootstrap-select .dropdown-toggle:after {
    display: none;
}

.bootstrap-select .dropdown-toggle:active,
.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select .dropdown-toggle:hover {
    background-color: #fff !important;
    border: 1px solid #e9e9e9 !important;
    box-shadow: none !important;
    outline: 0px !important;
}

.bootstrap-select:active,
.bootstrap-select:focus {
    border: 0px !important;
    box-shadow: none !important;
    outline: 0px !important;
}

.bootstrap-select.show {
    box-shadow: none !important;
    outline: 0px !important;
}

.bootstrap-select.show .dropdown-toggle {
    box-shadow: none !important;
    outline: 0px !important;
}

.bootstrap-select.show.btn-group .dropdown-menu.inner {
    display: block;
}

.bootstrap-select div.dropdown-menu {
    border: 1px solid #e9e9e9;
    border-radius: 0;
    box-shadow: none;
    margin-top: -1px;
    padding: 0;
    font-size: 13px;
}

.bootstrap-select div.dropdown-menu ul li {
    padding: 0;
}

.bootstrap-select div.dropdown-menu ul li a {
    padding: 5px 15px;
}

.bootstrap-select div.dropdown-menu ul li a img {
    border-radius: 100%;
    -webkit-border-radius: 100%;
    margin-right: 5px;
}

.bootstrap-select .bs-searchbox .form-control {
    padding: 5px 10px;
    height: 30px;
}

.bootstrap-select .dropdown-menu>li>a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
}

.bootstrap-select .dropdown-menu>li>a:hover {
    background-color: #f2f2f4;
}


/*radio & checkbox*/

input[type="checkbox"],
input[type="radio"] {
    /* display: ; */
    /* opacity: 0; */
    margin-left: 1px !important;
    margin-right: 8px !important;
    /* position: absolute; */
}

input[type="checkbox"]+label,
input[type="radio"]+label {
    display: block;
    padding-left: 25px !important;
    position: relative;
}

input[type="checkbox"]+label:hover:before,
input[type="radio"]+label:hover:before {
    border-color: #3396d1;
}

input[type="checkbox"]+label:before,
input[type="radio"]+label:before {
    background-color: #ffffff;
    border: 2px solid #b6b7b8;
    border-radius: 0;
    content: "";
    display: inline-block;
    height: 16px;
    left: 1px;
    line-height: 1;
    position: absolute;
    top: 50%;
    transition: all 0.1s linear 0s;
    -moz-transition: all 0.1s linear 0s;
    -webkit-transition: all 0.1s linear 0s;
    -o-transition: all 0.1s linear 0s;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    width: 16px;
}

input[type="checkbox"]+label:after {
    color: #fff;
    content: "\f00c";
    font-family: FontAwesome;
    font-size: 0px;
    -webkit-font-smoothing: antialiased;
    left: 6px;
    position: absolute;
    top: 7px;
    transition: all linear 0.1s;
    -moz-transition: all linear 0.1s;
    -webkit-transition: all linear 0.1s;
    -o-transition: all linear 0.1s;
    width: 14px;
    text-align: center;
}

input[type="checkbox"]:checked+label:before {
    border-width: 7px;
}

input[type="checkbox"]:checked+label:after {
    font-size: 10px;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

input[type="radio"]+label:before {
    border-radius: 50%;
    content: "";
}

input[type="radio"]:checked+label:before {
    border-color: #3396d1;
    border-width: 5px;
}

.form-inline .checkbox input[type="checkbox"],
.form-inline .radio input[type="radio"] {
    position: absolute;
    left: 0;
    bottom: 0;
}


/*touchspin input type number*/

.bootstrap-touchspin .input-group-btn-vertical {
    position: relative;
    white-space: nowrap;
    width: 1%;
    vertical-align: middle;
    display: table-cell;
}

.bootstrap-touchspin .input-group-btn-vertical>.btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 10px 10px 9px;
    margin-left: -1px;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: #e1e1e1;
    background: #fff;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
    border-radius: 0;
    border-top-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    margin-top: -2px;
    border-radius: 0;
    border-bottom-right-radius: 0;
}

.bootstrap-touchspin .input-group-btn-vertical i {
    position: absolute;
    top: 4px;
    left: 5px;
    font-size: 9px;
    font-weight: normal;
    color: #9fa0a1;
}


/*rating star*/

.input-rating input[type="radio"] {
    display: none;
}

.input-rating input[type="radio"]+label {
    display: inline-block;
    margin-left: -4px;
    padding-left: 0px !important;
    padding-right: 0px;
    width: 24px;
}

.input-rating input[type="radio"]+label:first-of-type {
    margin-left: 0px;
}

.input-rating input[type="radio"]+label:before {
    background-color: transparent;
    border: none;
    color: #ffd925;
    content: "\f006";
    display: inline-block;
    font-family: FontAwesome;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    height: 14px;
    line-height: 1;
    margin-left: 0px;
    position: static;
    text-align: center;
    top: 2px;
    transition: none;
    width: 14px;
}

.input-rating input[type="radio"]:checked+label:before,
.input-rating input[type="radio"]+label.marked:before,
.input-rating input[type="radio"]+label.filled:before,
.input-rating input[type="radio"]+label.hovered.filled:before {
    content: "\f005";
}

.input-rating input[type="radio"]+label.hovered:before {
    content: "\f006";
}


/*simple rating list*/

.rating-bx {
    color: #ffc300;
    font-size: 14px;
}

.rating-bx i {
    margin: 0 2px;
}

.rating-container .rating-stars::before {
    text-shadow: none;
}

.rating-container {
    color: #c8c8c8;
}

.rating-container .rating-stars {
    color: #3396d1;
}


/*validation symbol*/

.has-feedback label~.form-control-feedback {
    top: 31px;
}


/*loading*/

.loading-box {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0.7;
    z-index: 9999;
}

.loading-pic {
    width: 70px;
    height: 70px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: -35px;
    z-index: 99999;
}


/*loading - 2*/

.loading-cover,
.loading-srh-bar,
.loading-map {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.loading-cover .fa-spinner,
.loading-srh-bar .fa-spinner,
.loading-map .fa-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -10px;
    font-size: 25px;
    color: #333;
}

.dlab-banner-outer {
    margin-bottom: 0;
}

.dlab-success-top,
.dlab-error-top {
    margin-bottom: 0;
}

.alert-bx.alert-info {
    padding: 15px;
    border: 1px solid #bce8f1;
}


/*---------------------------------------------------------------
13. WP DEFAULT
---------------------------------------------------------------*/

.alignnone {
    margin: 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 15px;
}

.alignright {
    float: right;
    margin: 5px 0 25px 25px;
}

.alignleft {
    float: left;
    margin: 5px 25px 25px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 25px 25px;
}

a img.alignnone {
    margin: 5px 0 25px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 25px 25px 0;
}

a img.aligncenter {
    display: block;
    margin: 5px auto 15px;
}

.wp-caption {
    max-width: 100%;
    text-align: center;
}

.wp-caption img[class*="wp-image-"] {
    display: block;
    margin: 0;
}

.wp-caption.alignnone {
    margin: 5px 0 25px 0;
}

.wp-caption.alignleft {
    margin: 5px 25px 25px 0;
}

.wp-caption.alignright {
    margin: 5px 0 25px 25px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    color: #9d9d9d;
    font-size: 13px;
    line-height: 18px;
    margin: 0;
    padding: 10px 0;
    text-align: left;
}

blockquote {
    padding: 16px 20px 16px 80px;
    font-size: 18px;
    font-style: italic;
    color: #555555;
    margin: 40px 0;
    border-left: none;
    line-height: 30px;
    position: relative;
    background: #f6f6f6;
    clear: both;
    border-left: 4px solid #efbb20;
}

blockquote p {
    font-size: 18px;
    color: #555555;
    line-height: 30px;
    margin-bottom: 0px;
}

blockquote:before {
    content: "\f10d";
    font-family: "FontAwesome";
    color: #efbb20;
    font-size: 35px;
    position: absolute;
    left: 20px;
    top: 20px;
    font-style: normal;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
    max-width: 100%;
    height: auto;
}


/*---------------------------------------------------------------
14. HEADER (top part of page)
---------------------------------------------------------------*/

.site-header {
    position: relative;
    z-index: 99999;
}

.site-header ul,
.site-header ol {
    margin-bottom: 0;
}


/*without top bar*/

.extra-nav {
    float: right;
    padding: 26px 0;
    position: relative;
    z-index: 9;
}

.extra-nav .site-button-link {
    font-size: 15px;
}

.extra-nav .extra-cell {
    display: inline-block;
    margin-left: 10px;
}

@media only screen and (max-width: 767px) {
    .extra-nav {
        margin-right: 15px;
    }
}

@media only screen and (max-width: 480px) {
    .extra-nav {
        margin: 0 1px 0 0;
        text-align: right;
    }
}

.new-page {
    padding: 2px 5px;
    font-size: 10px;
    background: #ff0000;
    color: #fff;
    border-radius: 4px;
    vertical-align: middle;
    margin-left: 2px;
}

.new-page.menu-new {
    font-size: 10px;
    position: absolute;
    right: 10px;
    top: 8px;
    padding: 3px 4px;
    line-height: 10px;
}

@media only screen and (max-width: 1200px) {
    .new-page.menu-new {
        display: none;
    }
}


/* map page header*/

#header-part.fix-map-header {
    height: 90px;
}

#header-part.fix-map-header .main-bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

@media only screen and (max-width: 480px) {
    #header-part.fix-map-header {
        height: 160px;
    }
}


/*---------------------------------------------------------------
15. PAGE TOP BAR (left & right content)
---------------------------------------------------------------*/

.top-bar {
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    color: #444444;
    padding: 11px 0;
}

.dlab-topbar-left {
    float: left;
}

.dlab-topbar-right {
    float: right;
}

.dlab-topbar-center,
.dlab-topbar-left,
.dlab-topbar-right {
    padding-left: 15px;
    padding-right: 15px;
}

.dlab-topbar-left ul,
.dlab-topbar-right ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.dlab-topbar-left ul li,
.dlab-topbar-right ul li {
    display: inline-block;
}

.dlab-topbar-left ul li {
    padding-right: 15px;
}

.dlab-topbar-left ul li a,
.dlab-topbar-right ul li a {
    color: #444444;
}

.topbar-social li a {
    padding: 0 6px;
}

@media only screen and (max-width: 991px) {
    .dlab-topbar-right {
        padding-left: 0;
        padding-right: 15px;
    }
    .dlab-topbar-left {
        padding-left: 15px;
        padding-right: 0;
    }
}

@media only screen and (max-width: 767px) {
    .top-bar [class*="col-"] {
        width: 100%;
        text-align: right;
        padding: 10px 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .top-bar [class*="col-"]:last-child {
        border-bottom: none;
    }
    .top-bar {
        padding: 5px 0;
    }
    .dlab-topbar-center,
    .dlab-topbar-left,
    .dlab-topbar-right {
        display: black;
        width: 100%;
        text-align: center;
        padding: 3px 15px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .dlab-topbar-left ul li {
        padding: 0 5px;
    }
}


/*=== >eamil-phone (left) ===*/

.e-p-bx li {
    display: inline;
    font-size: 14px;
    padding-right: 10px;
}

.e-p-bx li a {
    color: #fff;
}

.e-p-bx li i {
    margin-right: 10px;
    vertical-align: middle;
}


/*=== >social-links (right) ===*/

.social-bx,
.login-bx {
    margin: 0 0 0 15px;
    float: right;
}

.social-bx li,
.login-bx li {
    font-size: 12px;
    margin: 0;
    position: relative;
}

.social-bx li {
    padding-left: 1px;
    padding-right: 1px;
}

.social-bx li a,
.login-bx li a {
    color: #ffffff;
}

.social-bx li i,
.login-bx li i {
    vertical-align: baseline;
    margin: 0 5px 0 0;
}

.social-bx li span,
.login-bx li span {
    vertical-align: baseline;
    margin: 0 2px 0;
    font-size: 8px;
}

.login-bx li ul {
    top: 25px;
    left: auto;
    right: 0;
    border: none;
    padding: 0;
}

.login-bx li ul li {
    border-bottom: 1px solid #f7f7f7;
}

.login-bx li ul li a {
    color: #767676;
    padding: 9px 15px;
}

.arrow-up-border:before,
.arrow-up:after {
    position: absolute;
    display: inline-block;
    content: "";
}

.arrow-up-border:before {
    top: -7px;
    right: 19px;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #f00;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
}

.arrow-up:after {
    top: -6px;
    right: 20px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
}

.arrow-left:before {
    position: absolute;
    top: -7px;
    left: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: "";
}

.arrow-left:after {
    position: absolute;
    top: -6px;
    left: 10px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    content: "";
}


/*=== >language-list () ===*/

.language-bx li {
    display: inline;
    margin: 0 10px;
}


/*---------------------------------------------------------------
16. LOGO ( company identify  symbol )
---------------------------------------------------------------*/


/*=== >logo for header ===*/

.logo-header {
    display: table;
    float: left;
    vertical-align: middle;
    padding: 0;
    color: #efbb20;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 140px;
    height: 80px;
    position: relative;
    z-index: 9;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.logo-footer {
    display: table;
    vertical-align: middle;
    padding: 0;
    color: #efbb20;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    height: 60px;
}

.logo-header>a,
.logo-footer>a {
    display: table-cell;
    vertical-align: middle;
}

.logo-header.center-block {
    margin-left: auto;
    margin-right: auto;
    float: none;
}

.logo-header img,
.logo-footer img {
    height: auto;
    width: auto;
    max-width: 200px;
}

.logo-header span,
.logo-footer span {
    font-size: 20px;
    color: #efbb20;
    letter-spacing: 20px;
}


/*= When header is sticky =*/

.is-fixed .logo-header,
.is-fixed.header-curve .logo-header {
    height: 70px;
    width: 140px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
}


/*= Set width for mobile  =*/

@media only screen and (max-width: 767px) {
    .logo-header,
    .is-fixed.header-curve .logo-header,
    .is-fixed .logo-header,
    .is-fixed.header-curve .logo-header {
        width: 100px;
        max-width: 100px;
        height: 50px;
    }
    .logo-header img,
    .logo-footer img {
        max-width: 100px;
    }
    .is-fixed .logo-header a img,
    .is-fixed.header-curve .logo-header a img {
        vertical-align: sub;
    }
    .site-header .navbar-toggler,
    .site-header .is-fixed .navbar-toggler {
        margin: 18px 0 14px 10px;
    }
    .site-header .extra-nav,
    .site-header .is-fixed .extra-nav {
        padding: 11px 0;
    }
}


/*---------------------------------------------------------------
17. MAIN BAR ( place logo & main-navigation )
---------------------------------------------------------------*/

.main-bar {
    background: #ffffff;
    width: 100%;
    position: relative;
}

.header-curve .logo-header:before,
.header-curve .logo-header:after {
    background-color: #fff;
    content: "";
    position: absolute;
    bottom: 0;
    height: 100%;
    z-index: -1;
}

.header-curve .logo-header:before {
    right: -50px;
    width: 80%;
    -webkit-transform: skew(30deg);
    -moz-transform: skew(30deg);
    -o-transform: skew(30deg);
    -ms-transform: skew(30deg);
    transform: skew(30deg);
}

.header-curve .logo-header:after {
    right: 10%;
    width: 2000px;
}

.is-fixed.header-curve .logo-header img {
    margin-top: -0;
}

@media only screen and (max-width: 767px) {
    .header-curve .logo-header:after {
        right: 0;
        left: auto;
    }
}


/*---------------------------------------------------------------
18. NAVIGATIONS
	>mena-menu
	>mobile-menu
	>footer-menu
---------------------------------------------------------------*/


/*== >mena-menu ==*/

.navbar-toggler {
    border: 0 solid #efbb20;
    font-size: 16px;
    line-height: 24px;
    margin: 32px 0 30px 10px;
    padding: 0;
}

.navbar-toggler:focus {
    outline: none;
}

.navbar-toggler span {
    background: #666;
}

.is-fixed .navbar-toggler {
    margin: 28px 0 20px 10px;
}

.header-nav {
    position: relative;
    padding: 0;
}

.header-nav .nav {
    float: right;
}

.header-nav .nav i {
    font-size: 9px;
    margin-left: 3px;
    margin-top: -3px;
    vertical-align: middle;
}

.header-nav .nav>li {
    margin: 0px;
    font-weight: 400;
    text-transform: uppercase;
    position: relative;
}

.header-nav .nav>li>a {
    border-radius: 0px;
    color: #555555;
    font-size: 15px;
    padding: 30px 12px;
    cursor: pointer;
    font-weight: 600;
    display: inline-block;
}

.header-nav .nav>li>a:hover {
    background-color: transparent;
    color: #efbb20;
}

.header-nav .nav>li>a:active,
.header-nav .nav>li>a:focus {
    background-color: transparent;
}

.header-nav .nav>li.active>a,
.header-nav .nav>li.current-menu-item>a {
    background-color: transparent;
    color: #efbb20;
}

.header-nav .nav>li:hover>a {
    color: #efbb20;
}

.header-nav .nav>li:hover>.sub-menu,
.header-nav .nav>li:hover>.mega-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.header-nav .nav>li>.sub-menu,
.header-nav .nav>li>.mega-menu {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
}

.header-nav .nav>li .sub-menu {
    background-color: #ffffff;
    border: 1px solid #f4f4f4;
    display: block;
    left: 0;
    list-style: none;
    opacity: 0;
    padding: 10px 0;
    position: absolute;
    visibility: hidden;
    width: 220px;
    z-index: 10;
}

.header-nav .nav>li .sub-menu li {
    border-bottom: 0px solid #f4f4f4;
    position: relative;
}

.header-nav .nav>li .sub-menu li a {
    color: #505050;
    display: block;
    font-size: 14px;
    padding: 8px 20px;
    text-transform: capitalize;
    transition: all 0.15s linear;
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
}

.header-nav .nav>li .sub-menu li a:hover {
    background-color: #f2f2f2;
    color: #efbb20;
    text-decoration: none;
}

.header-nav .nav>li .sub-menu li:hover>a {
    color: #efbb20;
}

.header-nav .nav>li .sub-menu li:last-child {
    border-bottom: 0px;
}

.header-nav .nav>li .sub-menu li>.sub-menu.left,
.header-nav .nav>li .sub-menu li:hover .sub-menu.left {
    left: auto;
    right: 220px;
}

.header-nav .nav>li .sub-menu li .fa {
    color: inherit;
    display: block;
    float: right;
    font-size: 15px;
    position: absolute;
    right: 15px;
    top: 12px;
    opacity: 1;
}

.header-nav .nav>li .sub-menu li .fa.fa-nav {
    color: inherit;
    display: inline-block;
    float: none;
    font-size: 13px;
    margin-right: 5px;
    opacity: 1;
    position: unset;
    right: 10px;
    top: 12px;
}

.header-nav .nav>li .sub-menu li>.sub-menu {
    left: 220px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.header-nav .nav>li .sub-menu li:hover>.sub-menu {
    left: 220px;
    margin: 0px;
    opacity: 1;
    top: -1px;
    visibility: visible;
}

.header-nav .nav>li .sub-menu li:hover>.sub-menu:before {
    background-color: transparent;
    bottom: 0px;
    content: "";
    display: block;
    height: 100%;
    left: -6px;
    position: absolute;
    top: 0px;
    width: 6px;
}

.header-nav .nav>li.has-mega-menu {
    position: inherit;
}

.header-nav .nav>li .mega-menu {
    background-color: #ffffff;
    border: 1px solid #f8f8f8;
    display: table;
    left: 0px;
    list-style: none;
    opacity: 0;
    position: absolute;
    right: 0px;
    visibility: hidden;
    width: 100%;
    font-family: Montserrat;
    margin-top: 20px;
    z-index: 9;
}

.header-nav .nav>li .mega-menu>li {
    display: table-cell;
    padding: 30px 0 25px;
    position: relative;
    vertical-align: top;
    width: 25%;
}

.header-nav .nav>li .mega-menu>li:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.02);
    position: absolute;
    right: 0px;
    top: 0px;
    display: block;
    width: 1px;
    height: 100%;
}

.header-nav .nav>li .mega-menu>li:last-child:after {
    display: none;
}

.header-nav .nav>li .mega-menu>li>a {
    color: #000;
    display: block;
    font-size: 14px;
    padding: 0 20px;
    font-size: 13px;
    font-weight: 600;
}

.header-nav .nav>li .mega-menu>li ul {
    list-style: none;
    margin: 10px 0px 0px 0px;
    padding: 0px;
    width: 100%;
}

.header-nav .nav>li .mega-menu>li ul a {
    color: #505050;
    display: block;
    font-size: 13px;
    line-height: 34px;
    text-transform: capitalize;
    padding: 0 20px;
    font-weight: 500;
}

.header-nav .nav>li .mega-menu>li ul a:hover {
    color: #efbb20;
}

.header-nav .nav .mega-menu a i {
    font-size: 14px;
    margin-right: 5px;
    text-align: center;
    width: 15px;
}

.header-nav .nav>li .sub-menu,
.header-nav .nav>li .mega-menu {
    border-top: 2px solid #000;
}

.header-nav .nav>li.menu-item-has-children:before {
    content: "\f078";
    display: block;
    font-family: "FontAwesome";
    right: 4px;
    position: absolute;
    top: 50%;
    color: #999;
    margin-top: -8px;
    font-size: 8px;
}


/* Header Center */

.site-header.center .header-nav .nav {
    display: inline-block;
    float: left;
    width: 35%;
}

.site-header.center .navbar-nav>li {
    display: inline-block;
    float: none;
}

.site-header.center .header-nav .nav:first-child {
    text-align: right;
}

.site-header.center .header-nav .nav:first-child li {
    text-align: left;
}

.site-header.center .header-nav .nav:last-child {
    float: right;
}

.site-header.center .logo-header {
    position: absolute;
    transform: translate(-50%);
    -moz-transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -o-transform: translate(-50%);
    position: absolute;
    left: 50%;
    text-align: center;
}

.site-header.center .logo-header img {
    height: 100px;
    margin-bottom: -50px;
    position: relative;
    width: 100px;
    z-index: 2;
    object-fit: contain;
}

.site-header.center .logo-header:after {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    content: "";
    height: 100px;
    position: absolute;
    width: 140%;
    bottom: -99px;
    left: -20%;
}


/* Mega Menu */

.header-nav .nav>li.has-mega-menu.demos .mega-menu {
    left: auto;
    right: auto;
    max-width: 600px;
    z-index: 9;
}

@media only screen and (max-width: 991px) {
    .site-header.center .header-nav .nav {
        width: 100%;
    }
    .site-header.center .navbar-nav>li {
        display: block;
        float: left;
        width: 100%;
    }
    .header-nav .nav>li.has-mega-menu.demos .mega-menu {
        max-width: 100%;
    }
    .header-nav .nav>li .mega-menu {
        border: 0;
    }
    .site-header.center .is-fixed .header-nav .nav {
        height: auto;
    }
    .header-nav .nav>li .mega-menu>li {
        padding: 10px 0;
    }
    .header-nav .nav>li .sub-menu,
    .header-nav .nav>li .mega-menu {
        border-radius: 0;
    }
}

@media only screen and (max-width: 767px) {
    .site-header.center .logo-header img {
        height: 60px;
        margin-bottom: -30px;
    }
}


/* Nav  Badge */

.shop-cart.navbar-right {
    margin-right: 0;
    float: none;
    display: inline-block;
}

.shop-cart .dropdown-menu.cart-list {
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);
    top: 50px;
    border: 0;
}

.shop-cart .badge {
    font-size: 9px;
    height: 15px;
    line-height: 15px;
    min-width: 15px;
    padding: 0;
    position: absolute;
    right: -5px;
    text-align: center;
    top: -5px;
}

.shop-cart li {
    padding: 10px 20px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}

.shop-cart li:last-child {
    border-bottom: 0;
}

.shop-cart .media-left a {
    display: block;
    width: 60px;
}

.shop-cart .site-button-link {
    position: relative;
}

.cart-list.dropdown-menu {
    width: 260px;
    margin-top: 30px;
}

.is-fixed .cart-list.dropdown-menu {
    margin-top: 18px;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

.cart-list .media-object {
    width: 60px;
}

.cart-item .media-body {
    position: relative;
}

.item-close {
    background-color: #303030;
    border-radius: 20px;
    color: #ffffff;
    display: block;
    font-size: 9px;
    height: 18px;
    line-height: 18px;
    margin-top: -9px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    width: 18px;
    cursor: pointer;
}

.item-close:hover {
    background-color: #000;
}

.header-nav .nav>li.demos {
    position: inherit;
}

.header-nav .nav>li.demos .mega-menu {
    max-width: 700px;
    width: 700px;
    left: auto;
    right: 0;
}

.header-nav .nav>li .mega-menu>li.header-add {
    padding: 18px 14px;
}

@media only screen and (max-width: 991px) {
    header .logo {
        display: block;
        padding: 4px 0;
    }
    .header-nav .nav>li>a {}
    .header-nav .nav i {
        margin-top: 6px;
    }
    .header-nav .nav>li.demos .mega-menu.left {
        width: 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .shop-cart .dropdown-menu.cart-list {
        left: auto;
        right: 0;
    }
    .cart-list.dropdown-menu,
    .is-fixed .cart-list.dropdown-menu {
        margin-top: 0;
    }
}

@media only screen and (max-width: 991px) {
    /* responsive strat when media screen [991px] css START*/
    /* .navbar-header {
        float: none;
    }
    .navbar-toggler {
        display: block;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-collapse.collapse.in {
        display: block!important;
    } */
    /* responsive strat when medai screen [991px] css END*/
    .header-nav {
        clear: both;
        margin: 0 -15px;
        border-bottom: 1px solid #e9e9e9;
    }
    .header-nav .nav {
        float: none;
        margin: 0;
        background: #fff;
        width: 100%;
        display: block;
        height: auto;
    }
    .header-nav .nav li {
        float: none;
        display: block;
        width: 100%;
    }
    .header-nav .nav>li .sub-menu>li,
    .header-nav .nav>li .mega-menu>li {
        float: none;
        display: block;
        width: auto;
    }
    .header-nav .nav>li>a {
        padding: 10px 15px;
        border-top: 1px solid #e9e9e9;
        display: block;
    }
    .header-nav .nav i {
        float: right;
    }
    .header-nav .nav>li>a:hover,
    .header-nav .nav>li>a:active,
    .header-nav .nav>li>a:focus {
        background-color: #f0f0f0;
        text-decoration: none;
    }
    .header-nav .nav>li .mega-menu>li:after {
        display: none;
    }
    .header-nav .nav>li ul,
    .header-nav .nav>li .sub-menu,
    .header-nav .nav>li .mega-menu {
        display: none;
        position: static;
        visibility: visible;
        width: auto;
        background: #f6f6f6;
    }
    .header-nav .nav>li ul.mega-menu ul {
        display: none;
    }
    .header-nav .nav>li:hover>ul,
    .header-nav .nav>li:hover .sub-menu,
    .header-nav .nav>li:hover .mega-menu,
    .header-nav .nav>li .sub-menu li>.sub-menu {
        opacity: 1;
        visibility: visible;
        display: block;
        margin: 0;
    }
    .header-nav .nav>li ul.mega-menu li:hover ul {
        display: block;
    }
    /* Mobile Responsive */
    .side-nav .nav.navbar-nav li a i.fa-chevron-down:before,
    .nav.navbar-nav li a i.fa-chevron-down:before {
        content: "\f078";
    }
    .side-nav .nav.navbar-nav li.open a i.fa-chevron-down:before,
    .nav.navbar-nav li.open a i.fa-chevron-down:before {
        content: "\f054";
    }
    .side-nav .nav.navbar-nav li a i.fa-chevron-down,
    .nav.navbar-nav li a i.fa-chevron-down,
    .nav.navbar-nav li a i.fa-angle-right {
        background-color: rgba(0, 0, 0, 0.2);
        color: #ffffff;
        height: 100%;
        line-height: 40px;
        position: absolute;
        right: 0;
        text-align: center;
        top: -6px;
        width: 40px;
        z-index: 3;
    }
    .header-nav .nav>li .sub-menu li i.fa-angle-right:before {
        content: "\f078";
        font-size: 10px;
    }
    .header-nav .nav>li .sub-menu li.open i.fa-angle-right:before {
        content: "\f054";
    }
    .header-nav .nav>li .sub-menu .sub-menu,
    .header-nav .nav>li:hover .sub-menu .sub-menu,
    .header-nav .nav>li:hover .sub-menu,
    .header-nav .nav>li:hover .mega-menu {
        display: none;
        opacity: 1;
        margin-top: 0;
    }
    .header-nav .nav li .sub-menu .sub-menu {
        display: none;
        opacity: 1;
        margin-top: 0;
    }
    .header-nav .nav>li.open>.sub-menu .sub-menu {
        display: none;
    }
    .header-nav .nav>li.open>.sub-menu li.open .sub-menu,
    .header-nav .nav>li.open>.mega-menu,
    .header-nav .nav>li.open>.sub-menu,
    .header-nav .nav>li ul.mega-menu ul {
        display: block;
        opacity: 1;
        margin-top: 0;
    }
    .header-nav .nav>li .sub-menu li .fa {
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        margin: 0;
        right: -1px;
        color: #fff;
    }
    .header-nav .nav>li .mega-menu>li {
        padding: 0;
    }
    .header-nav .nav>li .mega-menu>li>a {
        display: none;
    }
    .header-nav .nav .mega-menu a i {
        display: inline-block;
        float: none;
        margin-top: 0;
    }
    .header-nav .nav .open>a,
    .header-nav .nav .open>a:focus,
    .header-nav .nav .open>a:hover {
        background-color: inherit;
        border-color: #e9e9e9;
    }
    .header-nav.nav-dark.nav .open>a,
    .header-nav.nav-dark .nav .open>a:focus,
    .header-nav.nav-dark .nav .open>a:hover {
        border-color: rgba(0, 0, 0, 0.2);
    }
}


/*= nav dark version =*/

.nav-dark.header-nav .nav>li .sub-menu,
.nav-dark.header-nav .nav>li .mega-menu {
    background-color: #232323;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.nav-dark.header-nav .nav>li .sub-menu li {
    border-bottom: 0px solid rgba(0, 0, 0, 0.2);
}

.nav-dark.header-nav .nav>li .sub-menu li a,
.nav-dark.header-nav .nav>li .mega-menu>li ul a {
    color: rgba(255, 255, 255, 0.6);
}

.header-nav.nav-dark .nav>li .mega-menu>li>a {
    color: #fff;
}

.nav-dark.header-nav .nav>li .sub-menu li a:hover {
    background-color: rgba(255, 255, 255, 0);
}

.nav-dark.header-nav .nav>li .sub-menu li:hover>a,
.nav-dark.header-nav .nav>li ul li a:hover,
.nav-dark.header-nav .nav>li .mega-menu>li ul a:hover {
    background-color: rgba(255, 255, 255, 0);
    color: #fff !important;
}

.nav-dark.header-nav .nav>li .mega-menu>li:after {
    background-color: rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 991px) {
    .nav-dark.header-nav .nav>li>a {
        color: #9d9d9d;
    }
    .nav-dark.header-nav {
        border-bottom: 1px solid rgba(0, 0, 0, 0.8);
    }
    .nav-dark.header-nav .nav {
        background-color: #202020;
    }
    .nav-dark.header-nav .nav>li>a {
        border-top: 0px solid rgba(0, 0, 0, 0.2);
    }
    [class*="bg-"] .nav-dark.header-nav .nav>li>a {
        color: #999;
    }
    [class*="bg-"] .nav-dark.header-nav .nav>li.active>a,
    [class*="bg-"] .nav-dark.header-nav .nav>li:hover>a {
        color: #efbb20;
    }
    .nav-dark.header-nav .nav>li>a:hover,
    .nav-dark.header-nav .nav>li>a:active,
    .nav-dark.header-nav .nav>li>a:focus {
        background-color: #282828;
        color: #efbb20;
    }
    .nav-dark.header-nav .nav>li ul,
    .nav-dark.header-nav .nav>li .sub-menu,
    .nav-dark.header-nav .nav>li .mega-menu {
        background-color: #282828;
    }
}


/*= when header is sticky =*/

.sticky-no .main-bar {
    position: static !important;
}


/*= when header is sticky =*/

.is-fixed .main-bar {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.is-fixed .header-nav .nav>li>a {
    padding: 25px 12px;
}

.is-fixed .extra-nav {
    padding: 21px 0;
}

@media only screen and (max-width: 991px) {
    .is-fixed .header-nav .nav>li>a {
        padding: 12px 15px;
    }
}

@media only screen and (max-width: 767px) {
    .is-fixed .header-nav .nav {
        overflow: auto;
        height: 225px;
    }
    .is-fixed .header-nav .nav>li>a {
        padding: 12px 15px;
    }
    .is-fixed#header-part .navbar-toggler {
        margin: 15px 0;
    }
}


/*= header full width =*/

#header-part.full-width .top-bar .container,
#header-part.full-width .main-bar .container {
    width: auto;
}


/*= Header style designs strat =*/


/*header with bg primary color 1 */

[class*="bg-"] .header-nav .nav>li>a {
    color: #fff;
}

[class*="bg-"] .header-nav .nav>li.active>a,
[class*="bg-"] .header-nav .nav>li.current-menu-item>a {
    color: #fff;
}

[class*="bg-"] .header-nav .nav>li>a:hover,
[class*="bg-"] .header-nav .nav>li:hover>a {
    color: #fff;
}

[class*="bg-"] .navbar-toggler {
    border-color: #fff;
}

[class*="bg-"] .navbar-toggler span {
    background-color: #fff;
}

@media only screen and (max-width: 991px) {
    [class*="bg-"] .header-nav .nav>li>a {
        color: #555;
    }
}


/*header style 1 (default) */


/* Header Dark */

.header.dark,
.header.dark .main-bar {
    background: #252525;
}

.header.dark .header-nav .nav>li>a,
.header.dark .extra-nav .site-button-link,
.header.dark .navbar-toggler {
    color: #dfdfdf;
}

.header.dark .navbar-toggler span {
    background-color: #dfdfdf;
}

.header.dark .top-bar {
    background-color: #252525;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    color: #dfdfdf;
}


/* Header Transparent  */

.header-transparent {
    position: absolute;
    width: 100%;
}

.header-transparent .main-bar {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.header-transparent .header-nav .nav>li>a {
    color: #fff;
}

.header-transparent .header-nav .nav>li>a,
.header-transparent .extra-nav .site-button-link,
.header-transparent .navbar-toggler {
    color: #fff;
}

.header-transparent .navbar-toggler span {
    background-color: #fff;
}

.header-transparent .is-fixed .main-bar {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);
}

.header-transparent .top-bar {
    background-color: rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    color: #dfdfdf;
}


/* Overlay */

.header-transparent.overlay .top-bar,
.header-transparent.overlay .main-bar {
    background-color: rgba(0, 0, 0, 0.5);
}


/* Header Full Width */

.fullwidth .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
}

@media only screen and (max-width: 767px) {
    .fullwidth .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
}


/* Colored Bg */


/*=== Primary */

.header.primary,
.header.primary .main-bar {
    background: #3b68e7;
}

.header.primary .header-nav .nav>li>a,
.header.primary .extra-nav .site-button-link,
.header.primary .navbar-toggler {
    color: #dfdfdf;
}

.header.primary .navbar-toggler span {
    background-color: #dfdfdf;
}

.header.primary .top-bar {
    background-color: #3b68e7;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    color: #dfdfdf;
}


/*=== Red */

.header.red,
.header.red .main-bar {
    background: #f22d4e;
}

.header.red .header-nav .nav>li>a,
.header.red .extra-nav .site-button-link,
.header.red .navbar-toggler {
    color: #dfdfdf;
}

.header.red .navbar-toggler span {
    background-color: #dfdfdf;
}

.header.red .top-bar {
    background-color: #f22d4e;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    color: #dfdfdf;
}


/*=== Blue */

.header.blue,
.header.blue .main-bar {
    background: #00c5dc;
}

.header.blue .header-nav .nav>li>a,
.header.blue .extra-nav .site-button-link,
.header.blue .navbar-toggler {
    color: #fff;
}

.header.blue .navbar-toggler span {
    background-color: #fff;
}

.header.blue .top-bar {
    background-color: #00c5dc;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    color: #fff;
}


/*=== Purpal */

.header.purpal,
.header.purpal .main-bar {
    background: #8862b9;
}

.header.purpal .header-nav .nav>li>a,
.header.purpal .extra-nav .site-button-link,
.header.purpal .navbar-toggler {
    color: #fff;
}

.header.purpal .navbar-toggler span {
    background-color: #fff;
}

.header.purpal .top-bar {
    background-color: #8862b9;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    color: #fff;
}


/*=== Yellow */

.header.yellow,
.header.yellow .main-bar {
    background: #ffb822;
}

.header.yellow .header-nav .nav>li>a,
.header.yellow .extra-nav .site-button-link,
.header.yellow .navbar-toggler {
    color: #fff;
}

.header.yellow .navbar-toggler span {
    background-color: #fff;
}

.header.yellow .top-bar {
    background-color: #ffb822;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    color: #fff;
}


/* Top Bar Bg */

.top-bar.bg-dark {
    background-color: #282a3c;
    color: #fff;
}

.top-bar.bg-black {
    background-color: #000;
    color: #fff;
}

.top-bar.bg-light {
    background-color: #a5a5a5;
    color: #ffffff;
    border-bottom: 0;
}


/* Box */

.header.box {
    position: absolute;
    width: 100%;
}

.header.box .main-bar {
    background-color: rgba(0, 0, 0, 0);
    margin-top: 20px;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
}

.header.box .header-nav .nav>li>a,
.header.box .extra-nav .site-button-link,
.header.box .navbar-toggler {
    color: #000;
}

.header.box .navbar-toggler span {
    background-color: #000;
}

.header.box .is-fixed .main-bar {
    position: fixed;
    background-color: #fff;
    margin-top: 0px;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
}

.header.box .container {
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 0 0 4px -2px #000000;
}

.header.box .is-fixed .container {
    box-shadow: none;
}


/* Box Overlay */

.header.box.overlay .header-nav .nav>li>a,
.header.box.overlay .extra-nav .site-button-link,
.header.box.overlay .navbar-toggler {
    color: #fff;
}

.header.box.overlay .navbar-toggler span {
    background-color: #fff;
}

.header.box.overlay .container {
    background-color: rgba(0, 0, 0, 0.5);
}

.header.box.overlay .is-fixed .container {
    background-color: rgba(0, 0, 0, 0);
}

.header.box.overlay .is-fixed .main-bar {
    background-color: rgba(0, 0, 0, 0.7);
}


/* Box Dark */

.header.box.dark .header-nav .nav>li>a,
.header.box.dark .extra-nav .site-button-link,
.header.box.dark .navbar-toggler {
    color: #fff;
}

.header.box.dark .navbar-toggler span {
    background-color: #fff;
}

.header.box.dark .container {
    background-color: #252525;
}

.header.box.dark .is-fixed .container,
.header.box.dark {
    background-color: rgba(0, 0, 0, 0);
}

.header.box.dark .is-fixed .main-bar {
    background-color: #252525;
}


/* One Page Nav */

.onepage .navbar {
    margin-bottom: 0;
    border: 0;
}

@media only screen and (max-width: 991px) {
    .header.dark .header-nav .nav>li>a,
    .header-transparent .header-nav .nav>li>a,
    .header.primary .header-nav .nav>li>a,
    .header.blue .header-nav .nav>li>a,
    .header.purpal .header-nav .nav>li>a,
    .header.red .header-nav .nav>li>a,
    .header.yellow .header-nav .nav>li>a,
    .header.box.dark .header-nav .nav>li>a,
    .header.box.overlay .header-nav .nav>li>a,
    .header-curve [class*="bg-"] .header-nav .nav>li.active>a {
        color: #000;
    }
    .header.dark .header-nav.nav-dark .nav>li>a,
    .header-transparent .header-nav.nav-dark .nav>li>a,
    .header.primary .header-nav.nav-dark .nav>li>a,
    .header.blue .header-nav.nav-dark .nav>li>a,
    .header.purpal .header-nav.nav-dark .nav>li>a,
    .header.red .header-nav.nav-dark .nav>li>a,
    .header.yellow .header-nav.nav-dark .nav>li>a,
    .header.box.dark .header-nav.nav-dark .nav>li>a,
    .header.box.overlay .header-nav.nav-dark .nav>li>a,
    .header-curve [class*="bg-"] .header-nav.nav-dark .nav>li.active>a {
        color: #fff;
    }
}


/* Left Nav  */


/* ============== Nav Style ============== */


/* Responsive CSS End */


/* ================== Header Style 5 End ================== */


/*---------------------------------------------------------------
19. BANNER
---------------------------------------------------------------*/

.dlab-banner-row,
.dlab-banner-outer {
    position: relative;
}

.dlab-bnr-pic {
    width: 100%;
    overflow: hidden;
}

.dlab-bnr-pic img {
    width: 100%;
    height: auto;
    display: block;
}

.dlab-bnr-text {
    position: absolute;
    left: 0;
    top: 35%;
    width: 100%;
    margin: 0;
    color: #ffffff;
}

.dlab-bnr-text-has {
    max-width: 600px;
}

.dlab-bnr-text strong,
.dlab-bnr-text span {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    -webkit-text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.dlab-bnr-text strong {
    display: block;
    font-size: 60px;
    font-weight: 700;
    line-height: 70px;
    margin: 0 0 25px;
    color: #ffffff;
}

.dlab-bnr-text span {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    display: block;
    margin-bottom: 30px;
}

.dlab-bnr-text .btn {
    margin-right: 30px;
}

@media only screen and (max-width: 1200px) {
    .dlab-bnr-text strong {
        font-size: 60px;
        line-height: 60px;
        margin: 0 0 15px;
    }
    .dlab-bnr-text span {
        font-size: 20px;
        line-height: 24px;
    }
}

@media only screen and (max-width: 991px) {
    .dlab-bnr-text strong {
        font-size: 50px;
        line-height: 50px;
        margin: 0 0 10px;
    }
}

@media only screen and (max-width: 767px) {
    .dlab-bnr-text strong {
        font-size: 40px;
        line-height: 40px;
        margin: 0 0 0;
    }
}

@media only screen and (max-width: 680px) {
    .dlab-bnr-text strong {
        font-size: 30px;
        line-height: 30px;
        margin: 0 0 0;
    }
    .dlab-bnr-text span {
        font-size: 14px;
        line-height: 18px;
    }
}

@media only screen and (max-width: 480px) {
    .dlab-bnr-text strong {
        font-size: 20px;
        line-height: 20px;
    }
    .dlab-bnr-text span {
        font-size: 12px;
        line-height: 16px;
    }
}


/*== > inner page banner ==*/

.dlab-bnr-inr.dlab-bnr-inr-lg {
    height: 800px;
}

.dlab-bnr-inr.dlab-bnr-inr-md {
    height: 600px;
}

.dlab-bnr-inr.dlab-bnr-inr-sm {
    height: 450px;
}

.dlab-bnr-inr {
    height: 300px;
    background-size: cover;
    background-position: center center;
    display: table;
    width: 100%;
    text-align: left;
    padding-bottom: 40px;
}

.dlab-bnr-inr .container {
    display: table;
    height: 100%;
}

.dlab-bnr-inr-entry {
    display: table-cell;
    vertical-align: bottom;
}

.dlab-bnr-inr-entry.align-m {
    vertical-align: middle;
}

.banner-inner-row h1,
.banner-inner-row h2,
.banner-inner-row h3,
.banner-inner-row h4,
.banner-inner-row h5,
.banner-inner-row h6 {
    color: #fff;
}


/* Banner Contant */

.dlab-bnr-inr.banner-content h1 {
    font-weight: 500;
}

.dlab-bnr-inr.banner-content p {
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 1px;
    line-height: 36px;
    max-width: 750px;
    display: inline-block;
}

.dlab-bnr-inr.banner-content .site-button {
    padding: 13px 35px;
    font-size: 16px;
}


/* Banner  */

.dlab-bnr-inr .breadcrumb-row {
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
}

.dlab-bnr-inr h1 {
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 15px;
}

.next-element {
    color: #ffffff;
    float: right;
    opacity: 0.1;
}

.next-element:hover {
    color: #fff;
    opacity: 0.8;
}

.next-element i {}

.dlab-bnr-inr .breadcrumb-row ul li:after,
.dlab-bnr-inr .breadcrumb-row ul li:last-child {
    color: #fff;
}

.dlab-bnr-inr .breadcrumb-row ul li a {
    color: #fff;
}

@media only screen and (max-width: 767px) {
    .ele-bnr .bnr-title,
    .ele-bnr h1 a,
    .ele-bnr .breadcrumb-row h5 {
        display: none;
    }
    .ele-bnr .dlab-bnr-inr-entry {
        text-align: center;
    }
    .dlab-bnr-inr-entry {
        vertical-align: middle;
    }
    .dlab-bnr-inr {
        padding-bottom: 0;
    }
    .dlab-bnr-inr .breadcrumb-row ul {
        display: block;
        float: none;
        width: 100%;
        text-align: center;
    }
    .dlab-bnr-inr {
        height: 200px;
    }
    .dlab-bnr-inr h1 {
        line-height: 24px;
        font-size: 20px;
        text-align: center;
    }
    .dlab-bnr-inr.dlab-bnr-inr-sm,
    .dlab-bnr-inr {
        height: 250px;
    }
}

.navicon {
    width: 20px;
    height: 18px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.navicon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 1px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}

.navicon span:nth-child(1) {
    top: 0px;
}

.navicon span:nth-child(2) {
    top: 7px;
}

.navicon span:nth-child(3) {
    top: 14px;
}

.navicon.open span:nth-child(1) {
    top: 7px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.navicon.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.navicon.open span:nth-child(3) {
    top: 7px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}


/*---------------------------------------------------------------
20. SLIDER
	>rs slider
	>owl slider
---------------------------------------------------------------*/


/*== >rs slider ==*/

.tp-caption .font-weight-300 {
    font-weight: 300;
}

.tp-caption .font-weight-400 {
    font-weight: 400;
}

.tp-caption .font-weight-500 {
    font-weight: 500;
}

.tp-caption .font-weight-600 {
    font-weight: 600;
}

.tp-caption .font-weight-700 {
    font-weight: 700;
}

.tp-caption .font-weight-800 {
    font-weight: 800;
}

.tp-caption .font-weight-900 {
    font-weight: 900;
}

.tp-caption h1,
.tp-caption h2,
.tp-caption h3,
.tp-caption h4,
.tp-caption h5,
.tp-caption h6 {
    font-weight: 800;
    margin-bottom: 24px;
    color: #ffffff;
}

.tp-caption h1 {
    font-size: 70px;
    line-height: 60px;
}

.tp-caption h2 {
    font-size: 60px;
    line-height: 50px;
}

.tp-caption h3 {
    font-size: 50px;
    line-height: 40px;
    margin-bottom: 20px;
}

.tp-caption h4 {
    font-size: 40px;
    line-height: 30px;
    margin-bottom: 15px;
}

.tp-caption h5 {
    font-size: 30px;
    line-height: 4px;
    margin-bottom: 10px;
}

.tp-caption h6 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 10px;
}

.tp-caption h4,
.tp-caption h5,
.tp-caption h6 {
    font-weight: 600;
}

.tp-caption .text {
    color: #ffffff;
    font-size: 20px;
    line-height: 24px;
    width: 500px;
}

.overlay-row {
    background: #000 none repeat scroll 0 0;
    height: 100%;
    left: 0;
    opacity: 0.3;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}


/*== >owl slider ==*/


/* next pre btn */

.owl-none .owl-nav {
    display: none;
}

.owl-theme .owl-nav,
.owl-theme .owl-dots {
    margin-top: 20px;
    text-align: center;
}

.owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default;
}


/* owl dots button */

.owl-theme .owl-dots {
    text-align: center;
}

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline;
}

.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #d6d6d6;
    display: block;
    -webkit-backface-visibility: visible;
    -webkit-transition: opacity 200ms ease;
    -moz-transition: opacity 200ms ease;
    -ms-transition: opacity 200ms ease;
    -o-transition: opacity 200ms ease;
    transition: opacity 200ms ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #869791;
}


/* owl dots style */

.owl-theme.dots-style-1 .owl-dots .owl-dot {}

.owl-theme.dots-style-1 .owl-dots .owl-dot span,
.owl-theme.dots-style-2 .owl-dots .owl-dot span {
    background: rgba(0, 0, 0, 0.2);
    width: 8px;
    height: 8px;
    margin: 5px;
}

.owl-theme.dots-style-1 .owl-dots .owl-dot.active span,
.owl-theme.dots-style-1 .owl-dots .owl-dot:hover span {
    background: #000;
}


/* owl dots style 3 */

.owl-theme.dots-style-3 .owl-dots {
    margin-top: 30px;
    position: unset;
    right: auto;
    text-align: center;
    top: auto;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: auto;
}

.owl-theme.dots-style-3 .owl-dots .owl-dot span {
    height: 8px;
    width: 8px;
    margin: 0;
}

.owl-theme.dots-style-3 .owl-dots .owl-dot {
    border: 1px solid;
    border-radius: 20px;
    display: inline-block;
    margin: 4px;
    padding: 3px;
}

.owl-theme.dots-style-3 .owl-dots .owl-dot.active,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover {
    border: 1px solid #ff3b68;
}

.owl-theme.dots-style-3 .owl-dots .owl-dot.active span,
.owl-theme.dots-style-3 .owl-dots .owl-dot:hover span {
    background-color: #ff3b68;
}

.owl-item.dots-style-3 .slide-content-box {
    opacity: 0;
}

.owl-item.active.dots-style-3 .slide-content-box {
    opacity: 1;
    transition: all 2s ease 2s;
    -moz-transition: all 2s ease 2s;
    -webkit-transition: all 2s ease 2s;
    -o-transition: all 2s ease 2s;
}


/*owl button top to center  */

.owl-btn-top-c .owl-nav {
    text-align: center;
}


/*owl button top to left  */

.owl-btn-top-l .owl-nav {
    text-align: left;
}

.owl-btn-top-l .owl-nav .owl-prev {
    margin-left: 0;
}


/*owl button top to right  */

.owl-btn-top-r .owl-nav {
    text-align: right;
}

.owl-btn-top-r .owl-nav .owl-next {
    margin-right: 0;
}


/*owl button top to left & right  */

.owl-btn-top-lr .owl-nav {
    text-align: left;
}

.owl-btn-top-lr .owl-nav .owl-next {
    margin-right: 0;
    float: right;
}

.owl-btn-full .owl-prev,
.owl-btn-full .owl-next {
    padding: 40px 8px;
}


/*owl button < > CENTER to left & right */

.owl-btn-center-lr .owl-nav {
    margin: 0;
}

.owl-btn-center-lr+.owl-theme .owl-nav {
    margin: 0;
}

.owl-btn-center-lr .owl-nav .owl-prev,
.owl-btn-center-lr .owl-nav .owl-next {
    position: absolute;
    left: 0;
    top: 50%;
    margin: 0;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.owl-btn-center-lr .owl-nav .owl-next {
    left: auto;
    right: 0;
}

.owl-carousel .owl-item img {
    transform-style: inherit;
}


/* Owl Button Style */

.owl-btn-1 .owl-prev,
.owl-btn-1 .owl-next,
.owl-btn-2 .owl-prev,
.owl-btn-2 .owl-next,
.owl-btn-3 .owl-prev,
.owl-btn-3 .owl-next {
    border-radius: 3px;
    height: 40px;
    line-height: 40px;
    margin: 0 10px !important;
    padding: 0;
    text-align: center;
    width: 40px;
}

.owl-btn-1 .owl-prev span,
.owl-btn-1 .owl-next span,
.owl-btn-2 .owl-prev span,
.owl-btn-2 .owl-next span,
.owl-btn-3 .owl-prev span,
.owl-btn-3 .owl-next span {
    color: #fff;
}

.owl-btn-1 .owl-prev:focus,
.owl-btn-1 .owl-next:focus,
.owl-btn-2 .owl-prev:focus,
.owl-btn-2 .owl-next:focus,
.owl-btn-3 .owl-prev:focus,
.owl-btn-3 .owl-next:focus {
    outline: none;
}

.owl-btn-2 .owl-prev,
.owl-btn-2 .owl-next,
.owl-btn-3 .owl-prev,
.owl-btn-3 .owl-next {
    margin: 0 -70px !important;
}

.owl-btn-3 .owl-prev,
.owl-btn-3 .owl-next {
    background-color: rgba(0, 0, 0, 0);
    color: #000000 !important;
    font-size: 35px !important;
    height: auto;
    width: auto;
    opacity: 0.2;
}

.owl-btn-3 .owl-prev:hover,
.owl-btn-3 .owl-next:hover {
    background-color: rgba(0, 0, 0, 0) !important;
    color: #000 !important;
    opacity: 1;
}

.owl-btn-3.btn-white .owl-prev,
.owl-btn-3.btn-white .owl-next {
    color: #fff !important;
}

.owl-btn-3.btn-white .owl-prev:hover,
.owl-btn-3.btn-white .owl-next:hover {
    color: #fff !important;
    background: none !important;
}

.owl-btn-4 {
    overflow: hidden;
}

.owl-btn-4 .owl-prev,
.owl-btn-4 .owl-next {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 30px;
    box-shadow: 0 0 20px 7px rgba(0, 0, 0, 0.5);
    color: #000000;
    font-size: 24px;
    height: 50px;
    line-height: 50px;
    margin: 0 -10px !important;
    overflow: hidden;
    padding: 0;
    width: 50px;
    opacity: 0.7;
}

.owl-btn-4 .owl-prev:hover,
.owl-btn-4 .owl-next:hover {
    background: #ffffff none repeat scroll 0 0;
    color: #000;
    opacity: 1;
}

.owl-btn-1.primary .owl-prev,
.owl-btn-1.primary .owl-next {
    color: #fff !important;
}


/* Thumb */

#sync2 .owl-item.active.current .item img {
    outline: 5px solid rgba(0, 0, 0, 0.2);
    outline-offset: -9px;
}


/* owl-dots */

.owl-dots-none .owl-dots {
    display: none;
}

.owl-dots-primary-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-black-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-white-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-primary-big.owl-theme .owl-dots .owl-dot span,
.owl-dots-black-big.owl-theme .owl-dots .owl-dot span,
.owl-dots-white-big.owl-theme .owl-dots .owl-dot span {
    opacity: 0.5;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    margin: 4px;
}

.owl-dots-primary-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-primary-big.owl-theme .owl-dots .owl-dot span {
    background-color: #1abc9c;
}

.owl-dots-black-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-black-big.owl-theme .owl-dots .owl-dot span {
    background-color: #333 !important;
}

.owl-dots-white-full.owl-theme .owl-dots .owl-dot span,
.owl-dots-white-big.owl-theme .owl-dots .owl-dot span {
    background-color: #f5f5f5;
}

.owl-dots-primary-full.owl-theme .owl-dots .owl-dot.active span,
.owl-dots-black-full.owl-theme .owl-dots .owl-dot.active span,
.owl-dots-white-full.owl-theme .owl-dots .owl-dot.active span {
    opacity: 1;
    width: 25px;
}

.owl-dots-primary-big.owl-theme .owl-dots .owl-dot.active span,
.owl-dots-black-big.owl-theme .owl-dots .owl-dot.active span,
.owl-dots-white-big.owl-theme .owl-dots .owl-dot.active span {
    width: 14px;
    height: 14px;
    opacity: 1;
    margin: 0px 4px 2px 4px;
}

.owl-theme .owl-dots .owl-dot:focus {
    outline: none;
}

@media only screen and (max-width: 1200px) {
    .owl-btn-2 .owl-prev,
    .owl-btn-2 .owl-next,
    .owl-btn-3 .owl-prev,
    .owl-btn-3 .owl-next {
        margin: 0 10px !important;
    }
}


/*---------------------------------------------------------------
21. BLOG
---------------------------------------------------------------*/

.blog-post {
    position: relative;
    margin-bottom: 30px;
}

.dlab-post-title {
    margin-bottom: 5px;
}

.dlab-post-title .post-title {
    margin-top: 20px;
    margin-bottom: 5px;
}

.dlab-post-meta {
    margin-bottom: 15px;
}

.dlab-post-meta ul {
    margin: 0 -4px;
    list-style: none;
    text-transform: capitalize;
}

.blog-post .dlab-post-info .site-button i {
    margin-left: 5px;
}

.dlab-post-meta ul li {
    padding: 0;
    display: inline-block;
    color: #707070;
    font-weight: 500;
    font-size: 14px;
}

.dlab-post-meta ul li strong {
    font-weight: 500;
}

.dlab-post-meta li:after {
    content: "|";
    display: inline-block;
    font-weight: normal;
    margin-left: 5px;
    opacity: 0.5;
}

.dlab-post-meta li:last-child:after {
    display: none;
}

.dlab-post-meta a {
    color: #707070;
}

.dlab-post-meta i {
    margin: 0 5px;
    font-size: 15px;
}

.text-white .dlab-post-meta ul li,
.text-white .dlab-post-meta a,
.text-white .dlab-post-meta i {
    color: #fff;
}

.dlab-post-meta.text-white i,
.dlab-post-meta.text-white ul li,
.dlab-post-meta.text-white ul li a {
    color: #fff;
}

.dlab-post-meta.text-black i,
.dlab-post-meta.text-black ul li,
.dlab-post-meta.text-black ul li a {
    color: #000;
}

.dlab-post-meta.text-gray i,
.dlab-post-meta.text-gray ul li,
.dlab-post-meta.text-gray ul li a {
    color: #808080;
}

.dlab-post-text {
    margin-bottom: 20px;
}

.dlab-post-text p:last-child {
    margin: 0;
}

.dlab-post-readmore {
    margin-bottom: 15px;
}

.dlab-post-tags {
    border-top: 1px solid #e9e9e9;
    padding-top: 10px;
}

.dlab-post-tags .post-tags {
    margin: 0 -3px;
    list-style: none;
}

.dlab-post-tags .post-tags a {
    border: 1px solid #ebedf2;
    padding: 2px 8px;
    color: #a9a9a9;
    margin: 0 3px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
}

.dlab-info[class*="border-"] .post-title {
    margin-top: 0;
}

.text-white .post-title a {
    color: #fff;
}

.dlab-info>div:last-child,
.dlab-info>p:last-child {
    margin-bottom: 0;
}

@media only screen and (max-width: 1200px) {
    .dlab-post-meta .d-flex {
        display: block !important;
    }
}


/*== post date syle css ==*/

.date-style-1 .post-date {
    color: #fff;
    background-color: #efbb20;
    padding: 3px 5px 3px 5px;
}

.date-style-1 .post-date i {
    color: #fff;
    left: 5px;
    top: 5px;
}

.date-style-1 .post-date i,
.date-style-2 .post-date i,
.date-style-3 .post-date i,
.date-style-4 .post-date i {
    display: none;
}

.date-style-2 .post-date,
.date-style-4 .post-date {
    color: #fff;
    position: absolute;
    left: 20px;
    top: 0;
    width: 50px;
    text-align: center;
    text-transform: uppercase;
}

.date-style-2 .post-date strong,
.date-style-2 .post-date span,
.date-style-4 .post-date strong,
.date-style-4 .post-date span {
    display: block;
    padding: 10px 5px;
}

.date-style-2 .post-date strong,
.date-style-4 .post-date strong {
    font-size: 10px;
    padding: 10px 5px 5px;
    position: relative;
}

.date-style-2 .post-date>strong:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 1px;
    background-color: #fff;
    margin: 0 -10px;
    width: 20px;
}

.date-style-2 .post-date span {
    font-size: 16px;
    padding: 5px 5px 10px;
}

.date-style-2 .post-date:after,
.date-style-2 .post-date .fa,
.date-style-3 .post-date:after,
.date-style-3 .post-date .fa,
.date-style-4 .post-date .fa {
    display: none;
}

.date-style-3 .dlab-post-info {
    position: relative;
}

.date-style-3 .post-date {
    font-family: oswald;
    text-transform: uppercase;
    position: absolute;
    padding: 8px 15px;
    color: #fff;
    top: -36px;
    left: 0;
    height: 36px;
}

.date-style-3 .post-date strong {
    color: #30353c;
    margin-right: 2px;
}

.date-style-3.skew-date .post-date {
    padding-right: 8px;
}

.date-style-3.skew-date .post-date:before {
    content: "";
    position: absolute;
    right: -15px;
    top: 0;
    width: 22px;
    height: 100%;
    border-right: 3px solid #2d3239;
    -moz-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    -o-transform: skewX(20deg);
    -ms-transform: skewX(20deg);
    transform: skewX(20deg);
}

.date-style-4 .post-date span {
    background-color: #2e1e13;
}

.date-style-4 .post-date {
    top: 10px;
    left: 10px;
}

.date-style-4 .dlab-post-info {
    box-shadow: 0 3px 0 0 #dbdbdb;
}

.date-style-4 .dlab-post-meta ul li {
    font-weight: normal;
}


/*== post without media ==*/

.no-image-blog.date-style-2 .algo-post-info {
    padding-top: 70px;
}

.no-image-blog.date-style-2 .post-date {
    top: 0;
    left: 20px;
}


/*if no image with blog post*/

.blog-post.no-img-post .post-date {
    position: static;
    float: left;
}


/*blog post half iamge*/

.blog-md .dlab-post-media {
    width: 350px;
    border-radius: 4px;
}

.blog-md .dlab-post-info {
    border: none;
    padding-left: 30px;
}

.blog-md .dlab-post-tags {
    border: none;
    display: inline-block;
    padding: 0;
}

.blog-md .dlab-post-info .post-title {
    margin-top: 0;
}

.blog-md,
.blog-md .dlab-post-info {
    overflow: hidden;
}

.blog-md .dlab-post-info,
.blog-md .dlab-post-media {
    display: table-cell;
    vertical-align: middle;
}

.blog-md .dlab-post-media {
    vertical-align: top;
}

.blog-md .dlab-post-info>div:last-child {
    margin-bottom: 0;
}

.blog-lg .dlab-post-media {
    border-radius: 4px;
}


/* blog-rounded */

.blog-rounded [class*="border-"] {
    border-radius: 0 0 4px 4px;
}

.blog-rounded .dlab-post-media {
    border-radius: 4px 4px 0 0;
}


/* blog-effect1 */

.blog-effect1 {
    transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    -webkit-transition: all 1s;
}

.blog-effect1:hover {
    border-radius: 4px;
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
    -o-box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
    -ms-box-shadow: 0 5px 40px rgba(0, 0, 0, 0.05);
}


/* Blog Share */

.blog-share {
    position: relative;
}

.share-btn {
    position: absolute;
    right: 0;
    bottom: 0;
}

.share-btn ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.share-btn ul li {
    display: inline-block;
    margin-left: -40px;
    float: left;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
}

.share-btn ul li a.site-button {
    border-radius: 3px;
    width: 40px;
    line-height: 44px;
    height: 44px;
    display: block;
    color: #000;
    background: #e6e6e6;
}

.share-btn ul li a.site-button:hover {
    background: #d6d6d6;
}

.share-btn ul:hover li.share-button a.site-button {
    background: #a0a0a0;
}

.share-btn ul:hover li {
    margin-left: 4px;
}

.share-details-btn ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.share-details-btn ul li {
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 6px;
}

@media only screen and (max-width: 767px) {
    .blog-md.blog-post .dlab-pfost-media,
    .blog-md.blog-post .dlab-post-info {
        float: none;
        margin: 0 0 20px;
        width: 100%;
        display: block;
        padding-left: 0;
    }
    .blog-md.blog-post .dlab-post-info {
        margin-top: 15px;
    }
    .blog-md .dlab-post-media {
        width: 100%;
    }
}


/*Blog page pagination main*/

.nav-links:after {
    clear: both;
    content: "";
    display: table;
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
}

.pagination .page-numbers {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    color: #767676;
    padding: 9px 15px;
    font-weight: 600;
    font-size: 12px;
    display: block;
    float: left;
    margin-left: -1px;
}

.pagination .page-numbers.current,
.pagination a.page-numbers:hover {
    background: #3396d1;
    color: #fff;
}

.pagination i {
    font-size: 12px;
}

.pagination li.active a {
    background-color: #c5c5c5;
    color: #000000;
}


/*Blog paging Next & Prev css*/

.nav-links .nav-previous a,
.nav-links .nav-next a {
    display: inline-block;
    color: #3396d1;
    font-weight: 600;
    margin-bottom: 20px;
    outline: none !important;
}

.nav-links .nav-previous a:hover,
.nav-links .nav-next a:hover {
    color: #0099cc;
}

.nav-links .nav-previous a:after,
.nav-links .nav-next a:after {
    content: "\f105";
    font-family: "FontAwesome";
    vertical-align: middle;
    margin-left: 2px;
    color: #333;
}


/*Post navigation*/

.post-navigation {
    background-color: #fff;
    padding: 30px;
    border-bottom: 2px solid #e3e3e3;
}

.post-navigation .screen-reader-text {
    margin-top: 0;
}

.post-navigation .nav-previous,
.post-navigation .nav-next {
    display: inline-block;
    width: 50%;
}

.post-navigation .nav-next {
    text-align: right;
}

.post-navigation .nav-previous a:after,
.post-navigation .nav-next a:after {
    display: none;
}

.post-navigation .nav-previous .post-title,
.post-navigation .nav-next .post-title {
    display: block;
    font-size: 15px;
    color: #777777;
    margin-top: 10px;
}


/*post panging*/

.dlab-link-pages {
    padding: 20px 0 30px;
}

.dlab-link-pages a {
    color: #fff;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: 600;
    background-color: #ccc;
    margin: 0 2px;
}


/*Sticky Post*/

.sticky-post {
    position: absolute;
    right: 0;
    top: 0;
    background: #77c04b;
    color: #fff;
    padding: 10px 15px;
    text-transform: uppercase;
    font-weight: 600;
}


/*Post password form*/

.post-password-form {
    position: relative;
    clear: both;
}

.post-password-form input[type="password"] {
    width: 100%;
    border: 1px solid #ebedf2;
    padding: 10px 100px 10px 15px;
    height: 40px;
}

.post-password-form label {
    display: block;
}

.post-password-form input[type="submit"] {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border: none;
    height: 40px;
    text-transform: uppercase;
    font-weight: 600;
}


/*gallery css for blog post*/

.blog [class*="galleryid-"] {
    clear: both;
    margin: 0 auto;
    overflow: hidden;
}

.gallery .gallery-item {
    float: left;
    list-style: none;
    margin: 0px 0 0;
    overflow: hidden;
    padding: 0px 0px 5px 15px;
    text-align: center;
}

.gallery .gallery-caption {
    padding-top: 10px;
    font-size: 13px;
}

.gallery .gallery-item img {
    float: left;
    padding: 0 0px;
    width: 100%;
    border: none !important;
}

.gallery-columns-2 .gallery-item {
    max-width: 50%;
}

.gallery-columns-3 .gallery-item {
    max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
    max-width: 25%;
}

.gallery-columns-5 .gallery-item {
    max-width: 20%;
}

.gallery-columns-6 .gallery-item {
    max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
    max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
    max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
    max-width: 11.11%;
}

.gallery-icon img {
    margin: 0 auto;
}

.gallery-caption {
    color: #707070;
    color: rgba(51, 51, 51, 0.7);
    display: block;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1.5;
    padding: 0.5em 0;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
    display: none;
}


/*side bar*/

.side-bar .widget {
    margin-bottom: 30px;
}

@media only screen and (max-width: 991px) {
    .side-bar {
        margin-bottom: 40px;
    }
}


/*Blog single*/

.blog-single .dlab-post-meta {
    margin-bottom: 20px;
}

.blog-single .dlab-post-text {
    margin-top: 20px;
}

.blog-single .dlab-post-tags {
    margin-top: 20px;
}

.blog-single .dlab-post-media {
    border-radius: 4px;
}


/*= comment list = */

.comments-area {
    padding: 0;
}

.comments-area .comments-title {
    text-transform: uppercase;
    font-size: 20px;
}

ol.comment-list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

ol.comment-list li.comment {
    position: relative;
    padding: 0;
}

ol.comment-list li.comment .comment-body {
    position: relative;
    padding: 20px;
    margin-bottom: 40px;
    margin-left: 80px;
    position: relative;
    border: 1px solid #e9e9e9;
}

ol.comment-list li.comment .comment-body:before,
ol.comment-list li.comment .comment-body:after {
    content: "";
    position: absolute;
    top: 20px;
    width: 0;
    height: 0;
    border-width: 10px 12px 10px 0;
    border-style: solid;
}

ol.comment-list li.comment .comment-body:before {
    border-color: transparent #fff transparent #fff;
    z-index: 1;
    left: -12px;
    top: 22px;
}

ol.comment-list li.comment .comment-body:after {
    border-color: transparent #e9e9e9 transparent #e9e9e9;
    border-width: 12px 14px 12px 0;
    left: -14px;
}

ol.comment-list li.comment .comment-author {
    display: block;
    margin-bottom: 10px;
}

ol.comment-list li.comment .comment-author .avatar {
    position: absolute;
    top: 0;
    left: -80px;
    width: 56px;
    height: 56px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    border: 2px solid #fff;
}

ol.comment-list li.comment .comment-author .fn {
    display: inline-block;
    color: #555555;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 700;
    font-style: normal;
}

ol.comment-list li.comment .comment-author .says {
    display: none;
    color: #999999;
    font-weight: 600;
}

ol.comment-list li.comment .comment-meta {
    color: #8d8d8d;
    text-transform: capitalize;
    margin-bottom: 15px;
}

ol.comment-list li.comment .comment-meta a {
    color: #8d8d8d;
}

ol.comment-list li.comment .comment-meta a {
    color: #8d8d8d;
}

ol.comment-list li.comment .comment-meta:before,
ol.comment-list li.comment .reply a:before {
    font-family: "FontAwesome";
    font-size: 15px;
    vertical-align: top;
}

ol.comment-list li.comment .comment-meta:before {
    content: "\f133";
}

ol.comment-list li.comment .reply a:before {
    content: "\f064";
    font-weight: normal;
    color: #555555;
    margin-right: 5px;
    vertical-align: middle;
}

ol.comment-list li.comment p {
    line-height: 22px;
    margin: 0 0 5px;
    font-size: 14px;
}

ol.comment-list li.comment .reply a {
    position: absolute;
    top: 50px;
    right: 30px;
    margin-top: -5px;
    color: #efbb20;
    font-weight: 700;
}

ol.comment-list li .children {
    list-style: none;
    margin-left: 80px;
}

ol.comment-list li .children li {
    padding: 0;
}

@media only screen and (max-width: 767px) {
    .comments-area .padding-30 {
        padding: 15px;
    }
    ol.comment-list li.comment .comment-body {
        margin-bottom: 30px;
        margin-left: 70px;
    }
    ol.comment-list li.comment .comment-author .avatar {
        left: -75px;
        height: 60px;
        width: 60px;
    }
    ol.comment-list li .children {
        margin-left: 20px;
    }
    ol.comment-list li.comment .reply a {
        position: static;
    }
}

@media only screen and (max-width: 480px) {
    ol.comment-list li.comment .comment-body {
        margin-left: 52px;
    }
    ol.comment-list li.comment .comment-author .avatar {
        left: -55px;
        top: 12px;
        width: 40px;
        height: 40px;
    }
}


/*= comment form = */

.comment-respond {
    padding: 30px 30px;
    border: 1px solid #e9e9e9;
}

.comment-respond .comment-reply-title {
    text-transform: uppercase;
    font-size: 20px;
}

.comment-respond .comment-reply-title {
    font-size: 16px;
    font-weight: 600;
}

.comments-area .comment-form {
    margin: 0 -15px;
}

.comments-area .comment-form .comment-notes {
    display: none;
}

.comments-area .comment-form p {
    width: 33.333%;
    float: left;
    padding: 0 15px;
    margin-bottom: 30px;
    position: relative;
}

.comments-area .comment-form p.form-allowed-tags {
    width: 100%;
}

ol.comment-list li.comment .comment-respond .comment-form p {
    padding: 0 15px !important;
}

.comments-area .comment-form p label {
    display: none;
    line-height: 18px;
    margin-bottom: 10px;
}

.comments-area p:before {
    font-family: "themify";
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 0;
    font-size: 16px;
    color: #ccc;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #e1e6eb;
    text-align: center;
    border-radius: 4px 0 0 4px;
}

.comments-area p.comment-form-author:before {
    content: "\e602";
}

.comments-area p.comment-form-email:before {
    content: "\e75a";
}

.comments-area p.comment-form-url:before {
    content: "\e665";
}

.comments-area p.comment-form-comment:before {
    content: "\e645";
}

.comments-area .comment-form p input[type="text"],
.comments-area .comment-form p textarea {
    width: 100%;
    height: 40px;
    line-height: 6px 12px;
    padding: 10px 10px 10px 50px;
    border: 1px solid #e1e6eb;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    text-transform: capitalize;
}

.comments-area .comment-form p.comment-form-comment {
    width: 100%;
    display: block;
    clear: both;
}

.comments-area .comment-form p textarea {
    height: 120px;
}

.comments-area .comment-form p.form-submit {
    clear: both;
    float: none;
    width: 100%;
    margin: 0;
}

.comments-area .comment-form p input[type="submit"] {
    background-color: #efbb20;
    border: none;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    color: #fff;
    display: inline-block;
    font-weight: 600;
    padding: 10px 20px;
    text-transform: uppercase;
}

.comments-area .comment-form p input[type="submit"]:hover,
.comments-area .comment-form p input[type="submit"]:focus,
.comments-area .comment-form p input[type="submit"]:active {
    background-color: #6ab33e;
    border-color: #6ab33e;
    color: #fff;
}

@media only screen and (max-width: 767px) {
    .comments-area .comment-form p {
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }
    .comment-respond {
        padding: 20px;
    }
}


/*---------------------------------------------------------------
22. WIDGETS
---------------------------------------------------------------*/

.list-2 {
    display: table;
}

.list-2 li {
    float: left;
    padding: 5px 5px 5px 20px;
    width: 50%;
    position: relative;
}

.list-2 li:before {
    content: "\e60a";
    font-family: "themify";
    font-size: 11px;
    left: 0;
    position: absolute;
}

.list-2 li:hover:before {
    color: #1abc9c;
}

.widget {
    margin-bottom: 40px;
}

.widget.bg-white {
    border: 1px solid #ebedf2;
}

.widget.border-0 ul li {
    border: 0 none;
}

.widget-title {
    margin: 0 0 25px;
}

.widget-title {
    padding-bottom: 15px;
    position: relative;
    margin-bottom: 30px;
    vertical-align: middle;
}

.widget-title:after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 0;
    height: 3px;
    margin: 0 0 0;
    width: 50px;
    margin: 10px 0;
}

.widget .widget-title,
.widget .post-title {
    text-transform: uppercase;
}

.widget-title.style-1 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
    padding-bottom: 15px;
    font-weight: 600;
    line-height: 12px;
}

.widget-title.style-1:after {
    border-radius: 0;
    bottom: -2px;
    content: "";
    display: inline-block;
    height: 3px;
    left: 0;
    margin: 0;
    position: absolute;
    vertical-align: bottom;
    width: 50px;
}

.recent-posts-entry ul,
.category-entry ul {
    margin: 0;
    list-style: none;
    padding: 0;
}


/* widget link*/

.widget-link ul {
    margin: 0px;
    padding: 0px;
}

.widget-link ul li {
    list-style: none;
    display: inline-block;
    padding-left: 3px;
    padding-right: 10px;
    position: relative;
}

.widget-link ul li:last-child {
    padding-right: 3px;
}

.widget-link ul li:last-child:after {
    content: none;
}

.widget-link li:after {
    content: "/";
    position: absolute;
    right: 0;
}


/* widget listing*/

.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_rss ul,
.widget_recent_entries ul,
.widget_services ul,
.widget_getintuch ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.widget_categories ul li,
.widget_archive ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_nav_menu li,
.widget_recent_entries ul li,
.widget_services ul li {
    padding-bottom: 18px;
    margin-bottom: 13px;
    border-bottom: 1px solid rgba(102, 102, 102, 0.11);
    position: relative;
    padding: 10px 10px 10px 15px;
    margin-bottom: 0;
    line-height: 20px;
}

.widget_categories ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_pages ul li a,
.widget_recent_comments ul li a,
.widget_nav_menu li a,
.widget_recent_entries ul li a,
.widget_services ul li a {
    color: #767676;
    text-transform: capitalize;
}

.widget_categories ul li:before,
.widget_archive ul li:before,
.widget_meta ul li:before,
.widget_pages ul li:before,
.widget_recent_comments ul li:before,
.widget_nav_menu ul li:before,
.widget_recent_entries ul li:before,
.widget_services ul li:before {
    content: "\f105";
    position: absolute;
    left: 0;
    top: 10px;
    display: block;
    font-family: "FontAwesome";
}

.widget_categories ul li li,
.widget_archive ul li li,
.widget_meta ul li li,
.widget_pages ul li li,
.widget_recent_comments ul li li,
.widget_nav_menu li li,
.widget_services li li {
    border-bottom: none;
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 28px;
}

.widget_categories ul li li:before,
.widget_archive ul li li:before,
.widget_meta ul li li:before,
.widget_pages ul li li:before,
.widget_recent_comments ul li li:before,
.widget_nav_menu li li:before,
.widget_services li li:before {
    top: 0;
    left: -8px;
}


/*widget search custom*/

.search-bx .site-button {
    padding: 9px 12px;
    border: 1px solid transparent;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}


/*widget search wp-default*/

.widget_search .screen-reader-text {
    display: block;
}

.searchform {
    position: relative;
}

.searchform input[type="text"] {
    width: 100%;
    height: 40px;
    padding: 10px 90px 10px 15px;
    border: 1px solid #cccccc;
}

.searchform input[type="submit"] {
    height: 40px;
    padding: 10px 15px;
    background-color: #77c04b;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #ffffff;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: none;
}

.searchform input[type="submit"]:hover,
.searchform input[type="submit"]:focus,
.searchform input[type="submit"]:active {
    background-color: #6ab33e;
    color: #ffffff !important;
    border-bottom-color: #5a9e2f;
}


/* Search BX style 1 */

.search-bx.style-1 .input-group {
    border: 1px solid #efefef;
}

.search-bx.style-1 .input-group-btn button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 0;
    color: #000000;
    font-size: 15px;
    height: 45px;
    width: 40px;
    border-width: 0;
}

.search-bx.style-1 .form-control {
    border: 1px solid #efefef;
    border-width: 0;
    font-size: 15px;
    height: 45px;
    padding: 5px 20px;
}

.search-bx.style-1 .input-group:hover,
.search-bx.style-1 .input-group:focus {
    border: 1px solid #000;
}


/*widget recent-posts*/

.recent-posts-entry .post-date,
.tweets-feed-entry .tweet-date {
    color: #3396d1;
    font-style: normal;
}

.widget .post-title {
    line-height: 16px;
    margin-bottom: 8px;
    margin-top: 0;
    text-transform: capitalize;
    font-size: 15px;
    color: #404040;
    font-weight: 700;
}

.recent-posts-entry .widget-post {
    margin-bottom: 10px;
}

.recent-posts-entry .widget-post-bx:last-child {
    border-bottom: none;
}

.recent-posts-entry .dlab-post-media {
    padding-right: 15px;
    width: 110px;
}

.recent-posts-entry .dlab-post-media img {
    border-radius: 3px;
}

.recent-posts-entry .dlab-post-info {
    background: transparent;
    padding: 0;
    margin-left: 110px;
    border: none;
}

.recent-posts-entry .post-meta span {
    margin-right: 10px;
}

.recent-posts-entry .dlab-post-meta {
    margin-bottom: 0;
}

.recent-posts-entry .dlab-post-info,
.recent-posts-entry .dlab-post-media {
    display: table-cell;
    vertical-align: middle;
}

.recent-posts-entry .widget-post,
.recent-posts-entry .dlab-post-info {
    overflow: hidden;
}


/*widget recent-comment*/

.widget_recent_comments ul li:before {
    content: "\f0e6";
}

.widget_recent_comments ul li {
    padding-left: 20px;
    color: #999;
}


/* widget meta*/

.widget_meta ul li a abbr[title] {
    color: #333;
    border-bottom: none;
}


/*widget calender*/

.widget_calendar caption::after {
    color: #707070;
    content: ">";
    font-family: "FontAwesome";
    margin: 0 0 0 5px;
}

.widget_calendar table {
    border-collapse: separate;
    border-spacing: 2px;
    width: 100%;
}

.widget_calendar thead {
    background-color: #999999;
}

.widget_calendar tfoot tr td {
    border: none;
    padding: 0px;
}

.widget_calendar tfoot tr td a {
    background-color: #fff;
    padding: 4px 10px;
}

.widget_calendar table thead tr th {
    font-size: 11px;
    padding: 5px;
    text-align: center;
    border: none;
    color: #fff;
}

.widget_calendar table tbody td {
    font-size: 13px;
    padding: 6px 5px;
    text-align: center;
    background-color: #fff;
    border: none;
    color: #444;
}

.widget_calendar table tbody td#today {
    background-color: #77c04b;
    color: #fff;
}

.widget_calendar table tbody td#today a {
    color: #fff;
}


/*widget tags-list*/

.widget_tag_cloud a {
    padding: 8px 10px;
    background-color: #ffffff;
    border: 1px solid #ebedf2;
    font-size: 12px;
    display: inline-block;
    margin: 0 0 5px;
    color: #555555;
    font-weight: 600;
}

.side-bar .widget_tag_cloud a:hover {
    background-color: #cccccc;
    color: #fff;
    box-shadow: 5px 5px 10px 0 rgba(37, 201, 65, 0.15);
    -webkit-box-shadow: 5px 5px 10px 0 rgba(37, 201, 65, 0.15);
    -moz-box-shadow: 5px 5px 10px 0 rgba(37, 201, 65, 0.15);
    -o-box-shadow: 5px 5px 10px 0 rgba(37, 201, 65, 0.15);
    -ms-box-shadow: 5px 5px 10px 0 rgba(37, 201, 65, 0.15);
}

.widget_tag_cloud.radius a {
    border-radius: 40px;
    padding: 7px 10px;
}


/*widget archive*/

.widget_archive select {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
}


/*widget text*/

.widget_text select {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
}

.widget_text select option {
    width: 100%;
}


/*widget categories*/

.widget_categories li {
    text-align: right;
}

.widget_categories li a {
    float: left;
    text-transform: capitalize;
}


/*widget RSS*/

.widget_rss ul {
    margin: 0;
    line-height: 20px;
}

.widget_rss ul li {
    line-height: 20px;
    margin-bottom: 15px;
}

.widget_rss ul .rsswidget {
    color: #333;
}

.widget_rss ul .rss-date {
    color: #3396d1;
}

.widget_rss ul .rssSummary {
    padding: 5px 0;
}

.widget_rss ul cite {
    color: #333;
    font-weight: 600;
}


/* widget get in tuch */

.widget_getintuch {
    padding-top: 10px;
}

.widget_getintuch li {
    margin-bottom: 20px;
    position: relative;
    padding-left: 40px;
}

.widget_getintuch li,
.widget_getintuch b,
.widget_getintuch strong {
    display: block;
    text-transform: capitalize;
}

.widget_getintuch strong {
    font-weight: 600;
    font-size: 16px;
}

.widget_getintuch i {
    position: absolute;
    left: 0;
    top: 5px;
    text-align: center;
    font-size: 22px;
    width: 30px;
    height: 30px;
    line-height: 30px;
}


/* widget gallery */

.widget_gallery ul {
    padding-left: 0;
    display: table;
}

.widget_gallery.gallery-grid-4 li {
    width: 25%;
}

.gallery-grid-8 li {
    width: 12.5%;
}

@media only screen and (max-width: 767px) {
    .gallery-grid-8 li {
        width: 25%;
    }
}

.widget_gallery li {
    display: inline-block;
    width: 33.33%;
    float: left;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    padding: 2px;
}

.widget_gallery li img {
    display: inline-block;
    width: 100%;
}

.widget_gallery li:nth-child(3n-3) {
    margin-right: 0;
}

.widget_gallery li:hover {
    opacity: 0.7;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.widget_gallery a {
    display: inline-block;
}


/* Newslatter */

.widget-newslatter .news-box {
    background-color: #f5f6f7;
    padding: 20px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.widget-newslatter p {
    font-style: italic;
    font-size: 15px;
}

.widget-newslatter .form-control {
    background: #ffffff none repeat scroll 0 0;
    border: 2px solid #d0d0d0;
    height: 45px;
    margin-bottom: 15px;
    padding: 5px 20px;
}

.widget-newslatter .site-button {
    padding: 12px 15px;
    font-weight: 700;
}

.news-box form:after {
    color: #000000;
    content: "Newslatter";
    font-family: montserrat;
    font-size: 50px;
    font-weight: 700;
    opacity: 0.05;
    position: absolute;
    top: -40px;
    z-index: -1;
}

.news-box:before {
    font-family: themify;
    content: "\e75a";
    font-size: 50px;
    font-weight: 700;
    opacity: 0.05;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    opacity: 0.05;
}

.news-box form {
    position: relative;
}


/* About us Widgets */

.widget-about .dlab-title {
    margin: 15px 0 5px 0;
    color: #000;
}

.widget-about p {
    color: #333333;
    font-family: montserrat;
    font-size: 13px;
    line-height: 20px;
}

.widget-about .button-sm {
    font-weight: 700;
    padding: 5px 20px;
}


/* widget testimonial */

.widget-testimonial .testimonial-pic {
    height: 70px;
    width: 70px;
    border: 3px solid #ffffff;
    margin-bottom: 10px;
}

.widget-testimonial .testimonial-name {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    color: #000;
}

.widget-testimonial .testimonial-position {
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    color: #888q;
    font-size: 12px;
    font-style: inherit;
}

.widget-testimonial .testimonial-position p {
    line-height: 26px;
}

.widget-testimonial .testimonial-text {
    color: #505050;
    font-family: montserrat;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px;
    padding: 0;
}


/*---------------------------------------------------------------
23. CUSTOMIZE CSS
	>set-radius
	>transition
	>float-clearfix
---------------------------------------------------------------*/


/*== >set-radius ==*/

.btn,
.panel,
.form-control,
.img-thumbnail,
.panel-head ing,
.dropdown-menu,
.panel-group .panel,
.nav-tabs>li>a,
.modal-content,
.navbar-toggler,
.nav-pills>li>a,
.pager li>a,
.pager li>span,
.well,
.alert,
.list-group-item:first-child,
.list-group-item:last-child,
.input-group-addon,
.btn-group-divides>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle),
.btn-group-divides>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child),
.dlab-booking-form .dlab-extra-services li .icon-bx-lg,
.dlab-booking-form .dlab-extra-services li.active .icon-bx-lg:after,
.widget_tag_cloud a,
.searchform input[type="text"],
.searchform input[type="submit"],
ol.comment-list li.comment .comment-body {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}


/*== >transition ==*/

a,
.dlab-box,
.btn,
.form-control,
.overlay-bx,
.overlay-icon li a i,
.icon-bx-xl,
.icon-bx-lg,
.icon-bx-md,
.icon-bx-sm,
.icon-bx-xs,
.share-social-bx li,
.indicator,
.profile-menu ul li,
.staff-member .member-done,
.how-dlab-work .dlab-box .shadow-bx img,
.how-dlab-work .dlab-box .step-no-bx,
.dlab-info-has,
.dlab-we-find .dlab-box,
.navbar-toggler,
.logo-header,
.header-nav .nav>li>a,
.extra-nav,
.skew-secondry:hover,
.skew-primary:hover,
.skew-secondry:hover::after,
.skew-primary:hover::after {
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.animate-slow {
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.animate-mid {
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    -ms-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
}

.animate-fast {
    -webkit-transition: all 0.8s ease-out;
    -moz-transition: all 0.8s ease-out;
    -o-transition: all 0.8s ease-out;
    -ms-transition: all 0.8s ease-out;
    transition: all 0.8s ease-out;
}


/*== >float-clearfix ==*/

.clearfix:after,
.clearfix:before {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}


/*error for select box*/

.has-error .bootstrap-select .dropdown-toggle,
.has-error .bootstrap-select .dropdown-toggle:hover,
.has-error .bootstrap-select .dropdown-toggle:focus {
    border-color: #a94442 !important;
}


/*succes for select box*/

.has-success .bootstrap-select .dropdown-toggle,
.has-success .bootstrap-select .dropdown-toggle:hover,
.has-success .bootstrap-select .dropdown-toggle:focus {
    border-color: #3c763d !important;
}


/*---------------------------------------------------------------
24. SORTCODES 
	>Buttons
	>Title separators		
	>Deviders	
	>Lists & bullets	
	>Tabs
	>Accordians		
	>Carousels
	>Testimonials	
	>Pricing table
	>Alert box	
	>Modal pop
	>Social icons
	>Breadcrumb
	>Tables
	>Image effects
	>Icon boxes
	>Box New Style 
---------------------------------------------------------------*/


/*==== Shortcode Css ====*/

.sort-title {
    margin-bottom: 40px;
}

.sort-title h4:after {
    left: -160px;
}

.sort-title.text-white h4:after,
.sort-title.text-white h4:before {
    background: #fff;
}

.sort-title h4:after,
.sort-title h4:before {
    background: #000 none repeat scroll 0 0;
    content: "";
    height: 1px;
    position: absolute;
    right: -160px;
    top: 50%;
    width: 150px;
    opacity: 0.15;
}

.sort-title h4 {
    display: inline-block;
    margin: 0;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .sort-title h4:after,
    .sort-title h4:before {
        content: none;
    }
}


/*== >Buttons ======= */


/* owl slider button */

.owl-prev,
.owl-next {
    background-color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    padding: 5px 8px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 13px;
    display: inline-block;
    cursor: pointer;
    border-width: 0px;
    border-style: solid;
}

.owl-prev:hover,
.owl-prev:active,
.owl-prev:focus,
.owl-next:hover,
.owl-next:active,
.owl-next:focus {
    background-color: rgba(0, 0, 0, 0.7);
    border-color: #3e2e23;
    color: #ffffff;
}


/* theme default button */

.button-example .btn,
.button-example .site-button,
.button-example .site-button-link {
    margin: 0 5px 10px 0;
}


/* Bootstrap button */

.btn {
    -moz-user-select: none;
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    padding: 8px 16px;
    text-align: center;
    transition: all 0.2s ease-in-out 0s;
    vertical-align: middle;
    white-space: nowrap;
}

.btn-success {
    background-color: #5cb85c;
    border-color: #5cb85c;
    color: #ffffff;
}

.btn-primary {
    background-color: #0275d8;
    border-color: #0275d8;
    color: #ffffff;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
    background-color: #025aa5;
    border-color: #01549b;
}

.btn-info {
    background-color: #5bc0de;
    border-color: #5bc0de;
    color: #ffffff;
}

.btn-danger {
    background-color: #d9534f;
    border-color: #d9534f;
    color: #ffffff;
}

.btn-secondary {
    background-color: #ffffff;
    border-color: #cccccc;
    color: #292b2c;
}


/* Outline */

.btn-outline-danger {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-color: #d9534f;
    color: #d9534f;
}

.btn-outline-primary {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-color: #0275d8;
    color: #0275d8;
}

.btn-outline-secondary {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-color: #cccccc;
    color: #cccccc;
}

.btn-outline-success {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-color: #5cb85c;
    color: #5cb85c;
}

.btn-outline-info {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-color: #5bc0de;
    color: #5bc0de;
}

.btn-outline-warning {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-color: #f0ad4e;
    color: #f0ad4e;
}


/* Outline Hover */

.btn-outline-primary:hover {
    background-color: hsl(208, 98%, 43%);
    border-color: hsl(208, 98%, 43%);
    color: hsl(0, 0%, 100%);
}

.btn-outline-secondary:hover {
    background-color: hsl(0, 0%, 80%);
    border-color: hsl(0, 0%, 80%);
    color: hsl(0, 0%, 100%);
}

.btn-outline-success:hover {
    background-color: hsl(120, 39%, 54%);
    border-color: hsl(120, 39%, 54%);
    color: hsl(0, 0%, 100%);
}

.btn-outline-info:hover {
    background-color: hsl(194, 66%, 61%);
    border-color: hsl(194, 66%, 61%);
    color: hsl(0, 0%, 100%);
}

.btn-outline-warning:hover {
    background-color: hsl(35, 84%, 62%);
    border-color: hsl(35, 84%, 62%);
    color: hsl(0, 0%, 100%);
}

.btn-outline-danger:hover {
    background-color: hsl(2, 64%, 58%);
    border-color: hsl(2, 64%, 58%);
    color: hsl(0, 0%, 100%);
}


/* Bootstrap Btn Size */

.btn-group-lg>.btn,
.btn-lg {
    border-radius: 4px;
    font-size: 20px;
    padding: 12px 24px;
}

.btn-group-sm>.btn,
.btn-sm {
    border-radius: 3px;
    font-size: 12px;
    padding: 4px 8px;
}

.site-button,
.site-button-secondry {
    color: #fff;
    padding: 10px 20px;
    display: inline-block;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    outline: none;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
    line-height: 1.42857;
    border-radius: 3px;
    font-weight: 600;
    text-align: center;
}

.site-button {
    background-color: #efbb20;
}

.site-button:active,
.site-button:hover,
.site-button:focus,
.active>.site-button {
    background-color: #d8a409;
    color: #fff;
}

.site-button-secondry {
    background-color: #282a3c;
}

.site-button-secondry:active,
.site-button-secondry:hover,
.site-button-secondry:focus,
.active>.site-button-secondry {
    background-color: #3c3e4e;
    color: #fff;
}


/* button text uppercase */

.site-button.text-uppercase {
    text-transform: uppercase;
}


/* button size */

.button-sm {
    padding: 5px 10px;
    font-size: 12px;
}

.button-md {
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 500;
}

.button-lg {
    font-size: 18px;
    font-weight: 600;
    padding: 18px 35px;
}

.button-xl {
    font-size: 22px;
    padding: 25px 35px;
}


/* button rounded */

.radius-no {
    border-radius: 0;
    -webkit-border-radius: 0;
}

.radius-sm {
    border-radius: 4px;
    -webkit-border-radius: 4px;
}

.radius-xl {
    border-radius: 100px;
    -webkit-border-radius: 100px;
}

.radius-md {
    border-radius: 8px;
    -webkit-border-radius: 8px;
}


/* button colors */


/* white */

.site-button.white {
    background-color: #fff;
    color: #777;
}

.site-button.white:hover,
.site-button.white:active,
.site-button.white:focus {
    background-color: #f4f4f4;
    color: #555;
}


/* black */

.site-button.black {
    background-color: #171717;
    color: #fff;
}

.site-button.black:hover,
.site-button.black:active,
.site-button.black:focus {
    background-color: #000;
    color: #fff;
}


/* Gray */

.site-button.gray {
    background-color: #666666;
    color: #fff;
}

.site-button.gray:hover,
.site-button.gray:active,
.site-button.gray.active,
.site-button.gray:focus {
    background-color: #555555;
    color: #fff;
}


/* pink */

.site-button.pink {
    background-color: #ff3c85;
    color: #fff;
}

.site-button.pink:hover,
.site-button.pink:active,
.site-button.pink.active,
.site-button.pink:focus {
    background-color: #ef2c75;
    color: #fff;
}


/* Blue */

.site-button.blue {
    background-color: #00c5dc;
    color: #fff;
}

.site-button.blue:hover,
.site-button.blue:active,
.site-button.blue.active,
.site-button.blue:focus {
    background-color: #00b5cc;
    color: #fff;
}


/* Green */

.site-button.green {
    background-color: #34bfa3;
    color: #fff;
}

.site-button.green:hover,
.site-button.green:active,
.site-button.green.active,
.site-button.green:focus {
    background-color: #24af93;
    color: #fff;
}


/* Orange */

.site-button.orange {
    background-color: #ef9800;
    color: #fff;
}

.site-button.orange:hover,
.site-button.orange:active,
.site-button.orange.active,
.site-button.orange:focus {
    background-color: #df8800;
    color: #fff;
}


/* Red */

.site-button.red {
    background-color: #f0526d;
    color: #fff;
}

.site-button.red:hover,
.site-button.red:active,
.site-button.red.active,
.site-button.red:focus {
    background-color: #f22d4e;
    color: #fff;
}


/* Brown */

.site-button.brown {
    background-color: #484a5c;
    color: #fff;
}

.site-button.brown:hover,
.site-button.brown:active,
.site-button.brown.active,
.site-button.brown:focus {
    background-color: #383a4c;
    color: #fff;
}


/* Yellow */

.site-button.yellow {
    background-color: #ffb822;
    color: #fff;
}

.site-button.yellow:hover,
.site-button.yellow:active,
.site-button.yellow.active,
.site-button.yellow:focus {
    background-color: #efa812;
    color: #fff;
}


/* purple */

.site-button.purple {
    background-color: #8862b9;
    color: #fff;
}

.site-button.purple:hover,
.site-button.purple:active,
.site-button.purple.active,
.site-button.purple:focus {
    background-color: #7852a9;
    color: #fff;
}


/* Button Gradiyant  */


/* white */

.site-button.white {
    background-color: #fff;
    color: #000;
}

.site-button.white:hover,
.site-button.white:active,
.site-button.white:focus {
    background-color: #f4f4f4;
    color: #555;
}


/* black */

.site-button.black.gradient {
    background: #000000;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #000000 0%, #727272 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #000000 0%, #727272 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #000000 0%, #727272 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#727272', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}

.site-button.black.gradient:hover,
.site-button.black.gradient:active,
.site-button.black.gradient:focus {
    background: #1e1e1e;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #1e1e1e 0%, #727272 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #1e1e1e 0%, #727272 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #1e1e1e 0%, #727272 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1e1e1e', endColorstr='#727272', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}


/* Gray */

.site-button.gradient.gray {
    background: #666666;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #666666 0%, #999999 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #666666 0%, #999999 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #666666 0%, #999999 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#666666', endColorstr='#999999', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}

.site-button.gradient.gray:hover,
.site-button.gradient.gray:active,
.site-button.gradient.gray:focus {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4f4f4f+0,999999+100 */
    background: #4f4f4f;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #4f4f4f 0%, #999999 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #4f4f4f 0%, #999999 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #4f4f4f 0%, #999999 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#4f4f4f', endColorstr='#999999', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}


/* pink */

.site-button.gradient.pink {
    background: #ff3c85;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #ff3c85 0%, #ff93bb 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #ff3c85 0%, #ff93bb 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #ff3c85 0%, #ff93bb 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ff3c85', endColorstr='#ff93bb', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}

.site-button.gradient.pink:hover,
.site-button.gradient.pink:active,
.site-button.gradient.pink:focus {
    background: #ff3d81;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #ff3d81 0%, #ff7fae 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #ff3d81 0%, #ff7fae 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #ff3d81 0%, #ff7fae 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ff3d81', endColorstr='#ff7fae', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}


/* Blue */

.site-button.gradient.blue {
    background: #00c5db;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #00c5db 0%, #6dff9e 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #00c5db 0%, #6dff9e 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #00c5db 0%, #6dff9e 99%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00c5db', endColorstr='#6dff9e', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}

.site-button.gradient.blue:hover,
.site-button.gradient.blue:active,
.site-button.gradient.blue:focus {
    background: #00a9bc;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #00a9bc 0%, #54ea86 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #00a9bc 0%, #54ea86 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #00a9bc 0%, #54ea86 99%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00a9bc', endColorstr='#54ea86', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}


/* Green */

.site-button.gradient.green {
    background: #84ba3f;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #84ba3f 0%, #b7ff59 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #84ba3f 0%, #b7ff59 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #84ba3f 0%, #b7ff59 99%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#84ba3f', endColorstr='#b7ff59', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}

.site-button.gradient.green:hover,
.site-button.gradient.green:active,
.site-button.gradient.green:focus {
    background: #6dad1a;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #6dad1a 0%, #90ff00 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #6dad1a 0%, #90ff00 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #6dad1a 0%, #90ff00 99%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#6dad1a', endColorstr='#90ff00', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}


/* Orange */

.site-button.gradient.orange {
    background: #ef940b;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #ef940b 0%, #ffd754 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #ef940b 0%, #ffd754 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #ef940b 0%, #ffd754 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ef940b', endColorstr='#ffd754', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}

.site-button.gradient.orange:hover,
.site-button.gradient.orange:active,
.site-button.gradient.orange:focus {
    background: #d68000;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #d68000 0%, #ffbf00 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #d68000 0%, #ffbf00 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #d68000 0%, #ffbf00 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#d68000', endColorstr='#ffbf00', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}


/* Red */

.site-button.gradient.red {
    background: #ff1437;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #ff1437 0%, #ff5982 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #ff1437 0%, #ff5982 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #ff1437 0%, #ff5982 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ff1437', endColorstr='#ff5982', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}

.site-button.gradient.red:hover,
.site-button.gradient.red:active,
.site-button.gradient.red:focus {
    background: #ff0022;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #ff0022 0%, #ff3866 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #ff0022 0%, #ff3866 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #ff0022 0%, #ff3866 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ff0022', endColorstr='#ff3866', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}

.site-button.gd,
.site-button.gd:active,
.site-button.gd:hover,
.site-button.gd:focus {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1ebbf0+8,39dfaa+100 */
    background: #1ebbf0;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1ebbf0', endColorstr='#39dfaa', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}


/* Brown */

.site-button.gradient.brown {
    background: #303451;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #303451 0%, #7e8196 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #303451 0%, #7e8196 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #303451 0%, #7e8196 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#303451', endColorstr='#7e8196', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}

.site-button.gradient.brown:hover,
.site-button.gradient.brown:active,
.site-button.gradient.brown:focus {
    background: #1d213f;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #1d213f 0%, #777c9b 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #1d213f 0%, #777c9b 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #1d213f 0%, #777c9b 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1d213f', endColorstr='#777c9b', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}


/* Yellow */

.site-button.gradient.yellow {
    background: #ffb52d;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #ffb52d 34%, #ffd684 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #ffb52d 34%, #ffd684 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #ffb52d 34%, #ffd684 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffb52d', endColorstr='#ffd684', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}

.site-button.gradient.yellow:hover,
.site-button.gradient.yellow:active,
.site-button.gradient.yellow:focus {
    background: #e2a336;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #e2a336 34%, #ffc656 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #e2a336 34%, #ffc656 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #e2a336 34%, #ffc656 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#e2a336', endColorstr='#ffc656', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}


/* purple */

.site-button.gradient.purple {
    background: #62369b;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #62369b 0%, #b37cff 87%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #62369b 0%, #b37cff 87%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #62369b 0%, #b37cff 87%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#62369b', endColorstr='#b37cff', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}

.site-button.gradient.purple:hover,
.site-button.gradient.purple:active,
.site-button.gradient.purple:focus {
    background: #814ec4;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #814ec4 0%, #9e4aba 87%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #814ec4 0%, #9e4aba 87%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #814ec4 0%, #9e4aba 87%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#814ec4', endColorstr='#9e4aba', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
}


/* Skew */

.button-skew {
    position: relative;
    text-align: center;
    margin-right: 21px;
    -moz-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    -o-transform: skewX(-20deg);
    -ms-transform: skewX(-20deg);
    transform: skewX(-20deg);
}

.button-skew span {
    display: inline-block;
    -moz-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    -o-transform: skewX(20deg);
    -ms-transform: skewX(20deg);
    transform: skewX(20deg);
}

.button-skew i {
    background-color: inherit;
    position: absolute;
    height: 100%;
    margin-right: -32px;
    right: 0;
    top: 0;
    padding: 12px 12px;
    line-height: normal;
}

.button-skew i:before {
    display: inline-block;
    -moz-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    -o-transform: skewX(20deg);
    -ms-transform: skewX(20deg);
    transform: skewX(20deg);
}

.button-skew.button-sm i {
    padding: 7px 10px;
    margin-right: -27px;
}

.button-skew.button-lg i {
    padding: 25px 15px;
    margin-right: -40px;
}

.button-skew.button-xl i {
    padding: 35px 22px;
    margin-right: -55px;
}


/* Graphical */

.site-button.graphical {
    color: #fff;
    border-radius: 3px;
    text-shadow: 0 -1px rgba(0, 0, 0, 0.4);
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3), 0 1px 3px -1px rgba(45, 60, 72, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.site-button.graphical:active {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25) inset;
}


/* 3D */

.site-button.button-3d {
    color: #fff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    border-bottom: 4px solid rgba(0, 0, 0, 0.2);
    border-left: none;
    border-right: none;
    border-top: none;
}


/* Outline */

.text-white .site-button.outline {
    color: #fff;
}

.site-button.outline {
    color: #888;
    background: none;
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
}

.site-button.outline-2 {
    border-width: 2px;
}

.site-button.outline.radius-xl,
.site-button.outline-2.radius-xl {
    border-radius: 100px;
}


/* white */

.site-button.outline.white {
    color: #e7e7e7;
    border-color: #e7e7e7;
}

.site-button.outline.white:hover {
    color: #666666;
}


/* black */

.site-button.outline.black {
    color: #171717;
    border-color: #171717;
}


/* gray */

.site-button.outline.gray {
    color: #666666;
    border-color: #666666;
}


/* pink */

.site-button.outline.pink {
    color: #e63f75;
    border-color: #e63f75;
}


/* blue */

.site-button.outline.blue {
    color: #42b8d4;
    border-color: #42b8d4;
}


/* green */

.site-button.outline.green {
    color: #35b494;
    border-color: #35b494;
}


/* orange */

.site-button.outline.orange {
    color: #e56713;
    border-color: #e56713;
}


/* red */

.site-button.outline.red {
    color: #d93223;
    border-color: #d93223;
}


/* brown */

.site-button.outline.brown {
    color: #69441f;
    border-color: #69441f;
}


/* yellow */

.site-button.outline.yellow {
    color: #efbb20;
    border-color: #efbb20;
}


/* purple */

.site-button.outline.purple {
    color: #ae1af7;
    border-color: #ae1af7;
}


/* hover */

.site-button.outline:hover {
    border-color: rgba(0, 0, 0, 0);
    color: #fff;
}


/* button text link */

.site-button-link {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 0 none;
    display: inline-block;
    font-weight: 400;
    padding: 3px 5px;
}

.site-button-link i {
    margin-left: 10px;
}


/* white */

.site-button-link.white {
    color: #e7e7e7;
}

.site-button-link.white:hover,
.site-button-link.white:active,
.site-button-link.white:focus {
    color: #ccc;
}


/* black */

.site-button-link.black {
    color: #171717;
}

.site-button-link.black:hover,
.site-button-link.black:active,
.site-button-link.black:focus {
    color: #000;
}


/* Gray */

.site-button-link.gray {
    color: #666666;
}

.site-button-link.gray:hover,
.site-button-link.gray:active,
.site-button-link.gray:focus {
    color: #555555;
}


/* pink */

.site-button-link.pink {
    color: #e63f75;
}

.site-button-link.pink:hover,
.site-button-link.pink:active,
.site-button-link.pink:focus {
    color: #ef2c75;
}


/* Blue */

.site-button-link.blue {
    color: #42b8d4;
}

.site-button-link.blue:hover,
.site-button-link.blue:active,
.site-button-link.blue:focus {
    color: #00b5cc;
}


/* Green */

.site-button-link.green {
    color: #35b494;
}

.site-button-link.green:hover,
.site-button-link.green:active,
.site-button-link.green:focus {
    color: #26a585;
}


/* Orange */

.site-button-link.orange {
    color: #e56713;
}

.site-button-link.orange:hover,
.site-button-link.orange:active,
.site-button-link.orange:focus {
    color: #d55703;
}


/* Red */

.site-button-link.red {
    color: #d93223;
}

.site-button-link.red:hover,
.site-button-link.red:active,
.site-button-link.red:focus {
    color: #c51e0f;
}


/* Brown */

.site-button-link.brown {
    color: #69441f;
}

.site-button-link.brown:hover,
.site-button-link.brown:active,
.site-button-link.brown:focus {
    color: #5f3a15;
}


/* Yellow */

.site-button-link.yellow {
    color: #ecc731;
}

.site-button-link.yellow:hover,
.site-button-link.yellow:active,
.site-button-link.yellow:focus {
    color: ##d4af19;
}


/* purple */

.site-button-link.purple {
    color: #ae1af7;
}

.site-button-link.purple:hover,
.site-button-link.purple:active,
.site-button-link.purple:focus {
    color: #9804e1;
}


/* Dropdown */

.dropdown-menu>li>a {
    padding: 10px 20px;
}


/* Box Shadow */


/* black */

.site-button.box-shadow.black {
    box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.19);
}


/* gray */

.site-button.box-shadow.gray {
    box-shadow: 0 5px 10px 2px rgba(85, 85, 85, 0.19);
}


/* pink */

.site-button.box-shadow.pink {
    box-shadow: 0 5px 10px 2px rgba(239, 44, 177, 0.19);
}


/* blue */

.site-button.box-shadow.blue {
    box-shadow: 0 5px 10px 2px rgba(0, 181, 104, 0.19);
}


/* green */

.site-button.box-shadow.green {
    box-shadow: 0 5px 10px 2px rgba(36, 176, 147, 0.19);
}


/* orange */

.site-button.box-shadow.orange {
    box-shadow: 0 5px 10px 2px rgba(223, 136, 0, 0.19);
}


/* red */

.site-button.box-shadow.red {
    box-shadow: 0 5px 10px 2px rgba(242, 45, 78, 0.19);
}


/* brown */

.site-button.box-shadow.brown {
    box-shadow: 0 5px 10px 2px rgba(56, 58, 76, 0.19);
}


/* yellow */

.site-button.box-shadow.yellow {
    box-shadow: 0 5px 10px 2px rgba(239, 168, 18, 0.19);
}


/* purple */

.site-button.box-shadow.purple {
    box-shadow: 0 5px 10px 2px rgba(120, 82, 169, 0.19);
}


/* Circle */

.site-button.circle-sm {
    width: 28px;
    height: 28px;
    padding: 0;
    border-radius: 40px;
    font-size: 12px;
    line-height: 27px;
}

.site-button.circle {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 40px;
    line-height: 39px;
}

.site-button.circle-lg {
    border-radius: 40px;
    font-size: 20px;
    height: 55px;
    padding: 0;
    width: 55px;
    line-height: 54px;
}

.site-button.sharp-sm {
    width: 28px;
    height: 28px;
    padding: 0;
    font-size: 12px;
    line-height: 27px;
}

.site-button.sharp {
    width: 40px;
    height: 40px;
    padding: 0;
    line-height: 39px;
}

.site-button.sharp-lg {
    font-size: 20px;
    height: 55px;
    padding: 0;
    width: 55px;
    line-height: 54px;
}


/* Social Icon */


/* .site-button.facebook,
.site-button.google-plus,
.site-button.instagram,
.site-button.linkedin,
.site-button.twitter,
.site-button.youtube,
.site-button.whatsapp{
	border-radius:3px;
} */


/* Social icon link */

.site-button-link.facebook {
    color: #3b5998;
}

.site-button-link.google-plus {
    color: #de4e43;
}

.site-button-link.linkedin {
    color: #007bb6;
}

.site-button-link.instagram {
    color: #8a5a4e;
}

.site-button-link.twitter {
    color: #1ea1f3;
}

.site-button-link.youtube {
    color: #ce201f;
}

.site-button-link.whatsapp {
    color: #01c854;
}

.site-button-link.facebook.hover,
.site-button-link.google-plus.hover,
.site-button-link.linkedin.hover,
.site-button-link.instagram.hover,
.site-button-link.twitter.hover,
.site-button-link.youtube.hover,
.site-button-link.whatsapp.hover {
    color: inherit;
}

.site-button-link.facebook.hover:hover,
.site-button-link.facebook.hover:focus,
.site-button-link.facebook.hover:active {
    color: #3b5998;
}

.site-button-link.google-plus.hover:hover,
.site-button-link.google-plus.hover:focus,
.site-button-link.google-plus.hover:active {
    color: #de4e43;
}

.site-button-link.linkedin.hover:hover,
.site-button-link.linkedin.hover:focus,
.site-button-link.linkedin.hover:active {
    color: #007bb6;
}

.site-button-link.instagram.hover:hover,
.site-button-link.instagram.hover:focus,
.site-button-link.instagram.hover:active {
    color: #8a5a4e;
}

.site-button-link.twitter.hover:hover,
.site-button-link.twitter.hover:focus,
.site-button-link.twitter.hover:active {
    color: #1ea1f3;
}

.site-button-link.youtube.hover:hover,
.site-button-link.youtube.hover:focus,
.site-button-link.youtube.hover:active {
    color: #ce201f;
}

.site-button-link.whatsapp.hover:hover,
.site-button-link.whatsapp.hover:focus,
.site-button-link.whatsapp.hover:active {
    color: #01c854;
}

.site-button.facebook {
    background-color: #3b5998;
}

.site-button.facebook:hover,
.site-button.facebook:focus,
.site-button.facebook:active {
    background-color: #4b69a8;
}

.site-button.google-plus {
    background-color: #de4e43;
}

.site-button.google-plus:hover,
.site-button.google-plus:focus,
.site-button.google-plus:active {
    background-color: #fe6e63;
}

.site-button.linkedin {
    background-color: #007bb6;
}

.site-button.linkedin:hover,
.site-button.linkedin:focus,
.site-button.linkedin:active {
    background-color: #209bd6;
}

.site-button.instagram {
    background-color: #8a5a4e;
}

.site-button.instagram:hover,
.site-button.instagram:focus,
.site-button.instagram:active {
    background-color: #aa7a6e;
}

.site-button.twitter {
    background-color: #1ea1f3;
}

.site-button.twitter:hover,
.site-button.twitter:focus,
.site-button.twitter:active {
    background-color: #0e91e3;
}

.site-button.youtube {
    background-color: #ce201f;
}

.site-button.youtube:hover,
.site-button.youtube:focus,
.site-button.youtube:active {
    background-color: #ee403f;
}

.site-button.whatsapp {
    background-color: #01c854;
}

.site-button.whatsapp:hover,
.site-button.whatsapp:focus,
.site-button.whatsapp:active {
    background-color: #00a834;
}


/* Social Icon Outline */

.site-button.facebook.outline,
.site-button.google-plus.outline,
.site-button.instagram.outline,
.site-button.linkedin.outline,
.site-button.twitter.outline,
.site-button.youtube.outline,
.site-button.whatsapp.outline {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
}

.site-button.facebook.outline {
    color: #3b5998;
}

.site-button.google-plus.outline {
    color: #de4e43;
}

.site-button.linkedin.outline {
    color: #007bb6;
}

.site-button.instagram.outline {
    color: #8a5a4e;
}

.site-button.twitter.outline {
    color: #1ea1f3;
}

.site-button.youtube.outline {
    color: #ce201f;
}

.site-button.whatsapp.outline {
    color: #01c854;
}


/* Outline Hover */

.site-button.facebook.outline:hover,
.site-button.facebook.outline:focus,
.site-button.facebook.outline:active {
    background-color: #4b69a8;
    color: #fff;
}

.site-button.google-plus.outline:hover,
.site-button.google-plus.outline:focus,
.site-button.google-plus.outline:active {
    background-color: #fe6e63;
    color: #fff;
}

.site-button.linkedin.outline:hover,
.site-button.linkedin.outline:focus,
.site-button.linkedin.outline:active {
    background-color: #209bd6;
    color: #fff;
}

.site-button.instagram.outline:hover,
.site-button.instagram.outline:focus,
.site-button.instagram.outline:active {
    background-color: #aa7a6e;
    color: #fff;
}

.site-button.twitter.outline:hover,
.site-button.twitter.outline:focus,
.site-button.twitter.outline:active {
    background-color: #0e91e3;
    color: #fff;
}

.site-button.youtube.outline:hover,
.site-button.youtube.outline:focus,
.site-button.youtube.outline:active {
    background-color: #ee403f;
    color: #fff;
}

.site-button.whatsapp.outline:hover,
.site-button.whatsapp.outline:focus,
.site-button.whatsapp.outline:active {
    background-color: #00a834;
    color: #fff;
}

@media only screen and (max-width: 991px) {
    .button-layout .site-button-link,
    .button-layout .site-secondry,
    .button-layout .site-button,
    .button-layout .btn {
        margin-bottom: 10px;
    }
}


/* Social White */

.site-button.white.facebook,
.site-button.white.google-plus,
.site-button.white.instagram,
.site-button.white.linkedin,
.site-button.white.twitter,
.site-button.white.youtube,
.site-button.white.whatsapp {
    background: #fff;
}

.site-button.white.facebook {
    color: #3b5998;
}

.site-button.white.google-plus {
    color: #de4e43;
}

.site-button.white.linkedin {
    color: #007bb6;
}

.site-button.white.instagram {
    color: #8a5a4e;
}

.site-button.white.twitter {
    color: #1ea1f3;
}

.site-button.white.youtube {
    color: #ce201f;
}

.site-button.white.whatsapp {
    color: #01c854;
}

.site-button.white.facebook:hover,
.site-button.white.google-plus:hover,
.site-button.white.instagram:hover,
.site-button.white.linkedin:hover,
.site-button.white.twitter:hover,
.site-button.white.youtube:hover,
.site-button.white.whatsapp:hover {
    color: #fff;
}

.site-button.white.facebook:hover {
    background-color: #3b5998;
}

.site-button.white.google-plus:hover {
    background-color: #de4e43;
}

.site-button.white.linkedin:hover {
    background-color: #007bb6;
}

.site-button.white.instagram:hover {
    background-color: #8a5a4e;
}

.site-button.white.twitter:hover {
    background-color: #1ea1f3;
}

.site-button.white.youtube:hover {
    background-color: #ce201f;
}

.site-button.white.whatsapp:hover {
    background-color: #01c854;
}


/* filter buttons css */

.site-filters {
    margin-bottom: 30px;
}

.site-filters ul {
    margin: 0;
    list-style: none;
}

.site-filters li {
    display: inline-block;
    padding: 0;
    margin-bottom: 3px;
}

.site-filters li.btn {
    box-shadow: none;
    -webkit-box-shadow: none;
    outline: none !important;
}

.site-filters li input {
    display: none;
}

.site-filters a {
    margin: 0 5px 5px 0;
}

.site-filters .active>[class*="site-button"] {
    color: #fff;
    background-color: #efbb20;
}

.site-filters.center {
    text-align: center;
}

.site-filters.center ul {
    display: inline-block;
    margin: auto;
}

.site-filters.center [class*="site-button"] {
    display: inline-block;
    margin: 0 5px 10px;
    text-transform: uppercase;
}

.mas-1 .card-container {
    margin-left: -1px;
}


/* Site Filters Style */


/* Site Filters Style */

.site-filters.style1 li a {
    color: #000316;
    font-family: montserrat;
    font-weight: 400;
    margin: 0;
    padding: 0 15px;
    position: relative;
}

.site-filters.style1 li:last-child a:after {
    position: relative;
}

.site-filters.style1 li a:after {
    background-color: #000316;
    content: "";
    height: 100%;
    opacity: 0.2;
    position: absolute;
    right: -2px;
    top: 0;
    transform: skew(-20deg, -20deg);
    -moz-transform: skew(-20deg, -20deg);
    -webkit-transform: skew(-20deg, -20deg);
    -o-transform: skew(-20deg, -20deg);
    width: 2px;
}

.site-filters.style1 li.active a,
.site-filters.style1.white li.active a {
    color: #19bb9f;
}

.site-filters.style1.white li a:after {
    background-color: #fff;
}

.site-filters.style1.white li a {
    color: #bfbfbf;
}

.site-filters .site-button.outline {
    border-color: #1abc9c;
}

.site-filters.filters-sm li a {
    padding: 5px 20px;
    font-size: 13px;
    font-weight: 500;
}

.site-filters.gray li a {
    background-color: #e0e3f6;
    color: #000;
}

.site-filters .button-sm {
    font-size: 13px;
    padding: 5px 20px;
}

@media only screen and (max-width: 480px) {
    .site-filters.center [class*="site-button"] {
        margin: 0 1px 5px;
        padding: 5px 10px;
        font-size: 12px;
    }
}


/*== >Title separators ====== */

.dlab-separator-outer {
    overflow: hidden;
}

.dlab-separator {
    display: inline-block;
    height: 2px;
    width: 80px;
    margin-bottom: 10px;
    position: relative;
}

.dlab-separator.style-liner {
    width: 20px;
}

.dlab-separator.style-icon {
    width: 30px;
    height: auto;
    text-align: center;
    font-size: 20px;
}

.dlab-separator[class*="style-"]:after,
.dlab-separator[class*="style-"]:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 40px;
    width: 70px;
    height: 2px;
    background: #eee;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.dlab-separator[class*="style-"]:before {
    left: auto;
    right: 40px;
}

.dlab-separator.style-skew {
    width: 15px;
    height: 10px;
    margin-left: 1px;
    margin-right: 1px;
    -moz-transform: skewX(-10deg);
    -webkit-transform: skewX(-10deg);
    -o-transform: skewX(-10deg);
    -ms-transform: skewX(-10deg);
    transform: skewX(-10deg);
}

.dlab-separator.style-skew[class*="style-"]:after,
.dlab-separator.style-skew[class*="style-"]:before {
    width: 80px;
    height: 4px;
    left: 20px;
    -moz-transform: translateY(-50%) skewX(-10deg);
    -webkit-transform: translateY(-50%) skewX(-10deg);
    -o-transform: translateY(-50%) skewX(-10deg);
    -ms-transform: translateY(-50%) skewX(-10deg);
    transform: translateY(-50%) skewX(-10deg);
}

.dlab-separator.style-skew[class*="style-"]:before {
    right: 20px;
    left: auto;
}

.dlab-separator.bnr-title {
    height: 1px;
    width: 155px;
    opacity: 0.5;
}

.dlab-separator.bnr-title:before {
    height: inherit;
    right: -80px;
    width: 25px;
}

.dlab-separator.bnr-title:after {
    height: inherit;
    right: -90px;
    top: 0;
    width: 6px;
}

.dlab-separator.bnr-title:before,
.dlab-separator.bnr-title:after {
    position: absolute;
    content: "";
    background-color: inherit;
}

.dlab-separator.bnr-title i {
    background-color: inherit;
    display: block;
    height: inherit;
    position: absolute;
    right: -50px;
    width: 45px;
}


/*== >Deviders ====== */

.dlab-divider {
    height: 1px;
    position: relative;
    margin: 30px 0;
}

.dlab-divider.tb20 {
    margin: 20px 0;
}

.dlab-divider.tb15 {
    margin: 15px 0;
}

.dlab-divider.tb10 {
    margin: 10px 0;
}

.dlab-divider.tb0 {
    margin: 0;
}

.dlab-divider.divider-2px {
    height: 2px;
}

.dlab-divider.divider-3px {
    height: 3px;
}

.dlab-divider.divider-4px {
    height: 4px;
}

.dlab-divider i {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: block;
    border-radius: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.dlab-divider.icon-left {
    margin-left: 40px;
}

.dlab-divider.icon-left i {
    left: -40px;
}

.dlab-divider.icon-right {
    margin-right: 40px;
}

.dlab-divider.icon-right i {
    left: auto;
    right: -40px;
}

.dlab-divider.icon-center i {
    left: 50%;
    margin-left: -5px;
}


/*== >Lists & bullets ====== */

.list-circle,
.list-angle-right,
.list-arrow,
.list-check,
.list-checked,
.list-icon-box,
.list-chevron-circle,
.list-hand-point,
.list-cup,
.list-pen,
.list-heart,
.list-star {
    margin: 0 0 20px 0;
    padding: 0;
    list-style: none;
}

.list-circle li,
.list-angle-right li,
.list-arrow li,
.list-check li,
.list-checked li,
.list-icon-box li,
.list-chevron-circle li,
.list-hand-point li,
.list-cup li,
.list-pen li,
.list-heart li,
.list-star li {
    padding: 5px 5px 5px 30px;
    position: relative;
}

.list-circle li:before,
.list-angle-right li:before,
.list-arrow li:before,
.list-check li:before,
.list-checked li:before,
.list-icon-box li:before,
.list-chevron-circle li:before,
.list-hand-point li:before,
.list-cup li:before,
.list-pen li:before,
.list-heart li:before,
.list-star li:before {
    font-family: "themify";
    position: absolute;
    left: 0;
    top: 5px;
    display: block;
    font-size: 15px;
    color: #777;
}

ul[class*="list-"] {
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
}

.list-circle li:before {
    font-size: 16px;
}

.list-circle li:before {
    content: "\e724";
}

.list-angle-right li:before {
    content: "\e628";
}

.list-arrow li:before {
    content: "\e661";
}

.list-check li:before {
    content: "\e64c";
}

.list-checked li:before {
    content: "\e64d";
    font-family: "themify";
    font-size: 15px;
}

.list-icon-box li:before {
    content: "\e6e8";
}

.list-chevron-circle li:before {
    content: "\e65d";
}

.list-hand-point li:before {
    content: "\e71b";
}

.list-star li:before {
    content: "\e60a";
}

.list-cup li:before {
    content: "\e641";
}

.list-pen li:before {
    content: "\e61c";
}

.list-heart li:before {
    content: "\e634";
}


/*listing before icon color css*/

.primary li:before {
    color: #efbb20;
}

.secondry li:before {
    color: #77c04b;
}

.black li:before {
    color: #000;
}

.white li:before {
    color: #fff;
}

.orange li:before {
    color: #ff6600;
}

.green li:before {
    color: #00cc00;
}

.red li:before {
    color: #ff3737;
}

.no-margin {
    margin: 0 0 0 !important;
    list-style: none;
}


/*listing before numbers*/

.list-num-count {
    counter-reset: li;
    padding-left: 0;
}

.list-num-count>li {
    position: relative;
    margin: 0 0 6px 30px;
    padding: 4px 8px;
    list-style: none;
}

.list-num-count>li:before {
    content: counter(li, decimal);
    counter-increment: li;
    position: absolute;
    top: 0;
    left: -28px;
    width: 28px;
    height: 28px;
    padding: 5px;
    color: #fff;
    background: #efbb20;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
    -webkit-border-radius: 100px;
    border-radius: 100px;
}

.list-num-count.lower-alpha>li:before {
    content: counter(li, lower-alpha);
}

.list-num-count.upper-alpha>li:before {
    content: counter(li, upper-alpha);
}

.list-num-count.upper-roman>li:before {
    content: counter(li, upper-roman);
}

.list-num-count.no-round>li:before {
    background-color: rgba(0, 0, 0, 0);
    color: #333333;
    font-size: 15px;
    font-weight: normal;
    height: auto;
    left: -30px;
    margin: 0;
    width: auto;
}

.rounded[class*="list-"] li:before {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}

.rounded[class*="list-"] li:hover:before {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -o-transform: scale(1.5);
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.reverse.primary[class*="list-"] li:hover:before {
    background-color: #1abc9c;
    color: #fff;
}

.rounded[class*="list-"] li {
    padding: 10px 0 10px 45px;
}

.rounded[class*="list-"] li {
    padding: 10px 0 10px 45px;
}

.rounded.border[class*="list-"] li:before {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid;
    line-height: 28px;
}


/* List Style Box */

[class*="list-"].list-box>li {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 4px;
    color: #000000;
    margin-bottom: 6px;
    padding: 10px 20px 10px 50px;
}

[class*="list-"].list-box>li:before {
    background: #1abc9c none repeat scroll 0 0;
    border-radius: 4px 0 0 4px;
    color: #ffffff;
    display: table-cell;
    height: 100%;
    padding: 10px 0;
    text-align: center;
    top: 0;
    vertical-align: middle;
    width: 40px;
}

[class*="list-"].list-box>li {
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}

[class*="list-"].style1.list-box>li {
    margin-left: 42px;
    border-radius: 0 4px 4px 0;
    padding: 10px 20px 10px 20px;
}

[class*="list-"].style1.list-box>li:before {
    left: -42px;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

[class*="list-"].style2.list-box>li {
    padding: 10px 20px 10px 60px;
}

[class*="list-"].style2.list-box>li:after {
    font-family: FontAwesome;
    content: "\f0da";
    left: 39px;
    position: absolute;
    top: 50%;
    color: #1abc9c;
    font-size: 18px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

[class*="list-"].list-box>li:hover,
[class*="list-"].style2.list-box>li:hover {
    transform: scale(1.05);
    -moz-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -o-transform: scale(1.05);
}

[class*="list-"].style1.list-box>li:hover {
    transform: scale(1);
    -o-transform: scale(1);
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
}

[class*="list-"].style1.list-box>li:hover:before {
    transform: scale(1.2);
    -o-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
}

[class*="list-"].list-box.reverse>li:before {
    color: #1abc9c;
    background: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}


/*== >Tabs ========= */


/*tabs style 1 [ defult ]*/

.dlab-tabs .tab-pane {
    padding: 20px 0;
}

.dlab-tabs .nav-tabs>li>a {
    background: #f6f7f8;
    color: #3d474a;
    -webkit-border-radius: 0;
    border-radius: 0;
    font-family: montserrat;
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 3px;
    display: inline-block;
}

.dlab-tabs .nav-tabs>li>a.active,
.dlab-tabs .nav-tabs>li>a.active:focus,
.dlab-tabs .nav-tabs>li>a.active:hover {
    background-color: #fff;
    border-color: #ebedf2;
    border-bottom: 1px solid #fff;
}

.dlab-tabs .nav>li>a:focus,
.dlab-tabs .nav>li>a:hover {
    background-color: transparent;
}

.dlab-tabs .nav li a:hover {
    border: 1px solid transparent;
}

.dlab-tabs .nav-tabs>li>a i {
    color: #efbb20;
    margin-right: 5px;
}


/*tabs bg  [ tabs background ]*/

.dlab-tabs.bg-tabs .nav-tabs>li>a {
    border: 1px solid #ebedf2;
    background-color: #efefef;
    margin-right: 2px;
}

.dlab-tabs.bg-tabs .nav-tabs>li>a.active {
    border-bottom: 1px solid transparent;
    background-color: #fff;
}

.dlab-tabs.vertical.bg-tabs .nav-tabs>li>a {
    border: 1px solid #ebedf2;
}

.dlab-tabs.vertical.bg-tabs .nav-tabs>li>a.active {
    border-right: 1px solid transparent;
}

.dlab-tabs.vertical.right.bg-tabs .nav-tabs>li>a {
    border: 1px solid #ebedf2;
}

.dlab-tabs.vertical.right.bg-tabs .nav-tabs>li>a.active {
    border-left: 1px solid transparent;
}


/*tabs bg & top border  [ tabs background & top border in active ]*/

.dlab-tabs.border-top .nav-tabs>li>a.active {
    border-top: 2px solid #efbb20;
    padding: 9px 15px 10px;
}


/*tabs style 2 [ content with border outer ]*/

.dlab-tabs.border .tab-pane {
    padding: 20px;
    border: 1px solid #ebedf2;
    margin-top: -1px;
}


/*tabs style 3  [ left-nav ]*/

.dlab-tabs.vertical .nav-tabs {
    float: left;
    width: 170px;
    border-bottom: none;
    border-right: 1px solid #ebedf2;
}

.dlab-tabs.vertical .tab-pane {
    padding: 10px 0 10px 20px;
}

.dlab-tabs.vertical .nav-tabs li {
    float: none;
    margin-right: -1px;
}

.dlab-tabs.vertical .nav-tabs li a {
    margin-right: 0;
    border-right: none;
}

.dlab-tabs.vertical .nav-tabs li a.active {
    border-bottom: 1px solid #ebedf2;
}

.dlab-tabs.vertical .tab-content {
    border-left: 1px solid #ebedf2;
    margin-left: 169px;
}


/*tabs style 4  [ left-nav & content with border ]*/

.dlab-tabs.vertical.border .tab-pane {
    padding: 20px;
    margin-left: -1px;
}


/*tabs style 5  [ right-nav ]*/

.dlab-tabs.vertical.right .nav-tabs {
    border-left: 1px solid #ebedf2;
    border-right: none;
    float: right;
}

.dlab-tabs.vertical.right .nav-tabs li {
    margin-right: 0;
    margin-left: -1px;
}

.dlab-tabs.vertical.right .nav-tabs li a {
    border-right: 1px solid transparent;
    border-left: none;
}

.dlab-tabs.vertical.right .nav-tabs li a.active {
    border-right: 1px solid #ebedf2;
    border-left: none;
}

.dlab-tabs.vertical.right .tab-content {
    border-left: none;
    border-right: 1px solid #ebedf2;
    margin-right: 169px;
    margin-left: 0;
}

.dlab-tabs.vertical.right .tab-pane {
    padding: 10px 20px 10px 0;
}


/* Tabs Style Button  */

.tabs-site-button .nav-tabs {
    border: 0;
}

.tabs-site-button .nav-tabs li a {
    padding: 13px 20px;
}

.tabs-site-button .nav-tabs li a,
.tabs-site-button .nav-tabs li a:hover,
.tabs-site-button .nav-tabs li a:focus {
    background: #f8f8f8 none repeat scroll 0 0;
    border: 0 none;
}

.tabs-site-button .nav-tabs li a.active:focus,
.tabs-site-button .nav-tabs li a.active:hover,
.tabs-site-button .nav-tabs li a.active {
    background-color: #1abc9c;
    color: #fff;
    border: 0;
}

.tabs-site-button .nav-tabs li a.active i {
    color: #fff;
}

@media only screen and (max-width: 767px) {
    .tabs-site-button .nav-tabs li a {
        padding: 13px 20px;
    }
}


/*tabs style 6  [ right-nav & content with border ]*/

.dlab-tabs.vertical.right.border .tab-pane {
    padding: 20px;
    margin-right: -1px;
}


/*== >Accordians =====*/

.panel {
    box-shadow: none;
    -webkit-box-shadow: none;
}

.dlab-accordion .panel {
    border: none;
    border-radius: 0;
    margin-bottom: -1px;
}

.acod-head {
    position: relative;
}

.acod-title {
    margin-top: 0;
    margin-bottom: 0;
}

.acod-head .fa {
    margin-right: 5px;
}

.acod-head a {
    display: block;
    padding: 17px 40px 17px 25px;
    border: 1px solid #dddddd;
    border-radius: 4px;
}

.acod-head a,
.acod-head a:hover,
.acod-head a.collapsed:hover {
    color: #efbb20;
}

.acod-head a.collapsed,
.acod-head a.collapsed:after {
    color: #3d474a;
}

.acod-head a:after {
    font-family: "themify";
    content: "\e622";
    float: right;
    color: #efbb20;
    font-size: 16px;
    letter-spacing: -2px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.acod-head a.collapsed:after {
    content: "\e61a";
    font-size: 16px;
    font-family: "themify";
}

.acod-body {
    border-left: 1px solid #ebedf2;
    border-right: 1px solid #ebedf2;
    border-bottom: 1px solid #ebedf2;
    border-top: 1px solid transparent;
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
}

.acod-content {
    margin: 20px;
}


/* accordian Style  */

.accdown1 .acod-head a:after {
    content: "\e64b";
}

.accdown1 .acod-head a.collapsed:after {
    content: "\e649";
}


/*== accordian rounded corners ==*/

.dlab-accordion.rounded .panel:first-child .acod-head a {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.dlab-accordion.rounded .panel:last-child .acod-head a.collapsed {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dlab-accordion.rounded .panel:last-child .acod-body {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dlab-accordion.rounded .panel:last-child .acod-body.in {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}


/*== accordian space between panel ==*/

.dlab-accordion.space .panel {
    margin-bottom: 5px;
}

.dlab-accordion.rounded.space .panel .acod-head a {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.dlab-accordion.rounded.space .panel .acod-head a.collapsed {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dlab-accordion.rounded.space .panel .acod-body {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}


/*== accordian background for title ==*/

.dlab-accordion.bg-title .acod-head a {
    background-color: #f7f8fa;
}


/*== accordian no cover border with content area ==*/

.dlab-accordion.no-cover .acod-body {
    border: none;
}

.dlab-accordion.no-cover .acod-content {
    padding-left: 20px;
    margin-right: 10px;
    border-left: 2px solid #e8e8e8;
    position: relative;
}

.dlab-accordion.no-cover .acod-content:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -5px;
    width: 8px;
    height: 8px;
    background-color: #fff;
    border: 2px solid #e8e8e8;
}


/*== accordian title bottom bordered ==*/

.dlab-accordion.border-bottom .acod-head a {
    border-left: none;
    border-right: none;
    border-top: none;
    padding-left: 0;
}


/* Accordion Color and Background Grediyant */

.dlab-accordion.primary .acod-head a {
    background-color: #0046c6;
    color: #fff;
    border: 0;
}

.dlab-accordion.primary .acod-head a:hover {
    color: #fff;
}

.dlab-accordion.primary .acod-head a:after {
    color: #fff;
}

.dlab-accordion.primary.active-bg .acod-head .collapsed {
    background-color: #fff;
}

.dlab-accordion.primary.active-bg .acod-head a.collapsed:after,
.dlab-accordion.primary.active-bg .acod-head .collapsed {
    color: #000;
}

.dlab-accordion.primary.active-bg .acod-head .collapsed {
    border: 1px solid #ebedf2;
}


/* gradient1 */

.dlab-accordion.gradient-1 .acod-head a {
    background: #0046c6;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #0046c6 0%, #c927f1 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #0046c6 0%, #c927f1 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #0046c6 0%, #c927f1 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#0046c6', endColorstr='#c927f1', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
    border: 0;
}

.dlab-accordion.gradient-2 .acod-head a {
    background: #ff90e1;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #ff90e1 0%, #28b1ff 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #ff90e1 0%, #28b1ff 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #ff90e1 0%, #28b1ff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ff90e1', endColorstr='#28b1ff', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    color: #fff;
    border: 0;
}

.dlab-accordion.gradient-1 .acod-head a:hover,
.dlab-accordion.gradient-2 .acod-head a:hover {
    color: #fff;
}

.dlab-accordion.gradient-1 .acod-head a:after,
.dlab-accordion.gradient-2 .acod-head a:after {
    color: #fff;
}

.dlab-accordion.gradient-1.active-bg .acod-head .collapsed,
.dlab-accordion.gradient-2.active-bg .acod-head .collapsed {
    background: #fff;
}

.dlab-accordion.gradient-1.active-bg .acod-head a.collapsed:after,
.dlab-accordion.gradient-2.active-bg .acod-head a.collapsed:after,
.dlab-accordion.gradient-1.active-bg .acod-head .collapsed,
.dlab-accordion.gradient-2.active-bg .acod-head .collapsed {
    color: #000;
}

.dlab-accordion.gradient-1.active-bg .acod-head .collapsed,
.dlab-accordion.gradient-2.active-bg .acod-head .collapsed {
    border: 1px solid #ebedf2;
}


/* Green */

.dlab-accordion.green .acod-head a {
    background-color: #24a88d;
    color: #fff;
    border: 0;
}

.dlab-accordion.green .acod-head a:hover {
    color: #fff;
}

.dlab-accordion.green .acod-head a:after {
    color: #fff;
}

.dlab-accordion.green.active-bg .acod-head .collapsed {
    background-color: #fff;
}

.dlab-accordion.green.active-bg .acod-head a.collapsed:after,
.dlab-accordion.green.active-bg .acod-head .collapsed {
    color: #000;
}

.dlab-accordion.green.active-bg .acod-head .collapsed {
    border: 1px solid #ebedf2;
}


/* Yellow */

.dlab-accordion.yellow .acod-head a {
    background-color: #efb822;
    color: #fff;
    border: 0;
}

.dlab-accordion.yellow .acod-head a:hover {
    color: #fff;
}

.dlab-accordion.yellow .acod-head a:after {
    color: #fff;
}

.dlab-accordion.yellow.active-bg .acod-head .collapsed {
    background-color: #fff;
}

.dlab-accordion.yellow.active-bg .acod-head a.collapsed:after,
.dlab-accordion.yellow.active-bg .acod-head .collapsed {
    color: #000;
}

.dlab-accordion.yellow.active-bg .acod-head .collapsed {
    border: 1px solid #ebedf2;
}


/* red */

.dlab-accordion.red .acod-head a {
    background-color: #ef516c;
    color: #fff;
    border: 0;
}

.dlab-accordion.red .acod-head a:hover {
    color: #fff;
}

.dlab-accordion.red .acod-head a:after {
    color: #fff;
}

.dlab-accordion.red.active-bg .acod-head .collapsed {
    background-color: #fff;
}

.dlab-accordion.red.active-bg .acod-head a.collapsed:after,
.dlab-accordion.red.active-bg .acod-head .collapsed {
    color: #000;
}

.dlab-accordion.red.active-bg .acod-head .collapsed {
    border: 1px solid #ebedf2;
}


/* blue */

.dlab-accordion.blue .acod-head a {
    background-color: #36a3f7;
    color: #fff;
    border: 0;
}

.dlab-accordion.blue .acod-head a:hover {
    color: #fff;
}

.dlab-accordion.blue .acod-head a:after {
    color: #fff;
}

.dlab-accordion.blue.active-bg .acod-head .collapsed {
    background-color: #fff;
}

.dlab-accordion.blue.active-bg .acod-head a.collapsed:after,
.dlab-accordion.blue.active-bg .acod-head .collapsed {
    color: #000;
}

.dlab-accordion.blue.active-bg .acod-head .collapsed {
    border: 1px solid #ebedf2;
}


/*== accordian title bottom bordered with colored ==*/

.dlab-accordion.border-bottom.no-border .acod-head a {
    border: none;
}

.dlab-accordion.no-border .acod-content {
    margin-top: 10px;
    margin-bottom: 10px;
}


/* No Gap */

.no-gap .acod-title a {
    border: 0 none;
    padding: 10px 0;
}

.no-gap .collapse {
    border: 0 none;
}

.no-gap .acod-content {
    margin: 10px 0 10px;
}


/* accordian size  */

.acco-sm .acod-head a {
    font-size: 12px;
    padding: 5px 30px 5px 15px;
}

.acco-sm .acod-head a:after {
    font-size: 16px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.acco-lg .acod-head a {
    padding: 18px 40px 18px 25px;
    font-size: 18px;
}

.acco-lg .acod-head a:after {
    font-size: 34px;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.acco-xl .acod-head a {
    font-size: 22px;
    padding: 25px 50px 25px 30px;
}

.acco-xl .acod-head a:after {
    font-size: 34px;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}


/*== >Carousels ======*/


/* Blog carousel */

.ow-post-info {
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ebebeb;
}

.ow-post-title .post-title {
    margin: 0 0 10px;
}

.ow-post-text {
    margin-bottom: 10px;
}

.ow-post-text p:last-child {
    margin: 0;
}

.ow-post-readmore {
    margin-bottom: 10px;
}

.ow-post-meta {
    margin-bottom: 10px;
}

.ow-post-meta ul {
    margin: 0 -4px;
    list-style: none;
}

.ow-post-meta ul li {
    padding: 0;
    display: inline-block;
}

.ow-post-meta li:after {
    content: "/";
    display: inline-block;
    font-weight: normal;
    margin-left: 5px;
    opacity: 0.5;
}

.ow-post-meta li:last-child:after {
    display: none;
}

.ow-post-meta a {
    color: #a9a9a9;
}

.ow-post-meta li i {
    color: #7b7b7b;
    margin: 0 5px;
}

.ow-post-tags {
    border-top: 1px solid #e9e9e9;
    padding-top: 10px;
}

.ow-post-tags .post-comment {
    float: left;
    font-weight: bold;
    text-transform: uppercase;
}

.ow-post-tags .post-comment a {
    color: #a9a9a9;
}

.ow-post-tags .post-tags {
    margin: 0 -3px;
    list-style: none;
}

.ow-post-tags .post-tags a {
    border: 1px solid #ebebeb;
    padding: 2px 8px 1px;
    color: #777;
    margin: 0 3px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 11px;
}

.ow-post-tags .post-tags a:hover,
.ow-post-tags .post-tags a:active,
.ow-post-tags .post-tags a:focus {
    border: 1px solid #0098a6;
    color: #0098a6;
}


/* empty media */

.no-image-blog.date-style-2 .ow-post-info {
    padding-top: 70px;
}


/*Event carousel */

.ow-event-info {
    position: relative;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ebebeb;
}

.ow-event-title .event-title {
    margin: 0 0 10px;
}

.ow-event-text {
    margin-bottom: 10px;
}

.ow-event-text p:last-child {
    margin: 0;
}

.ow-event-readmore {
    margin-bottom: 10px;
}

.ow-event-meta ul {
    margin: 0;
}

.ow-event-meta ul li {
    color: #a9a9a9;
    font-weight: 600;
    display: inline-block;
    padding-left: 20px;
}

.ow-event-meta ul li i {
    color: #7b7b7b;
}


/* empty media */

.no-image-event.date-style-2 .ow-post-info {
    padding-top: 70px;
}


/*Client carousel */

.ow-client-logo {
    background-color: #fff;
    display: table;
    width: 100%;
}

.client-logo {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 10px;
}

.owl-carousel .ow-client-logo img {
    height: 80px;
    max-height: 100%;
    width: auto;
    display: inline-block;
}


/* Blog New Add */

.blog-info {
    background-color: rgba(0, 0, 0, 0.9);
    left: 0;
    list-style: outside none none;
    margin: 0;
    padding: 7px 15px;
    position: absolute;
    top: -34px;
    width: 100%;
}

.blog-info li {
    display: inline-block;
    padding: 0 5px 0px 5px;
    line-height: 14px;
    border-right: 1px solid;
}

.blog-info li:hover,
.blog-info li a:hover {
    color: #fff;
}


/*== >Testimonials ======*/

.testimonial-pic {
    background: #fff;
    width: 100px;
    height: 100px;
    position: relative;
    display: inline-block;
    border: 5px solid #fff;
}

.testimonial-pic.radius {
    border-radius: 100%;
    -webkit-border-radius: 100%;
}

.testimonial-pic.radius img {
    width: 100%;
    height: 100;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}

.testimonial-pic.shadow {
    -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
    -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
    box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}

.testimonial-pic.style1 {
    border: 0 none;
    height: 100px;
    width: 100px;
}

.quote-left,
.quote-right {
    position: relative;
}

.quote-left:before,
.quote-right:after {
    font-family: "FontAwesome";
    position: absolute;
    z-index: 10;
    top: 15px;
}

.quote-left:before {
    content: "\f10d";
    left: 0;
}

.quote-right:after {
    content: "\f10e";
    left: auto;
    right: 0;
}

.testimonial-text {
    padding: 15px;
    position: relative;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

.testimonial-detail {
    padding: 5px;
}

.testimonial-name {
    font-family: montserrat;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

.testimonial-position {
    font-family: montserrat;
    font-size: 12px;
    font-style: inherit;
    text-transform: uppercase;
}

.testimonial-name,
.testimonial-position {
    display: block;
}

.testimonial-text p:last-child {
    margin: 0;
}


/*testimonial with background image*/

.testimonial-bg {
    color: #fff;
}


/*testimonial 1*/

.testimonial-1 {
    text-align: center;
}

.testimonial-1 .testimonial-position {
    color: #efbb20;
}

.testimonial-1 .testimonial-name,
.testimonial-1 .testimonial-position {
    display: block;
}

.testimonial-1 .quote-left:before {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    background: #efbb20;
    color: #fff;
    text-align: center;
    left: 0;
    top: 65px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}


/*testimonial 2*/

.testimonial-2 .testimonial-text,
.testimonial-8 .testimonial-text {
    background-color: #f4f5f7;
    padding: 30px;
}

.testimonial-2 .testimonial-text:after,
.testimonial-8 .testimonial-text:after {
    content: "";
    position: absolute;
    bottom: -15px;
    left: 50px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #f4f5f7;
}

.testimonial-2 .testimonial-detail,
.testimonial-8 .testimonial-detail {
    padding: 25px 0 20px 20px;
}

.testimonial-2 .testimonial-pic,
.testimonial-8 .testimonial-pic {
    float: left;
    margin-right: 15px;
    width: 80px;
    height: 80px;
}

.testimonial-2 .quote-left:before {
    width: 36px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    background: #efbb20;
    color: #fff;
    text-align: center;
    left: 0;
    top: 65px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
}

.testimonial-2 .testimonial-name,
.testimonial-8 .testimonial-name,
.testimonial-2 .testimonial-position,
.testimonial-8 .testimonial-position {
    padding: 2px 0;
}

.testimonial-2 .testimonial-name,
.testimonial-8 .testimonial-name {
    padding-top: 12px;
}

.testimonial-2 .testimonial-position {
    color: #efbb20;
}

.testimonial-2 .quote-left:before {
    top: 50px;
}


/*testimonial 2 with background image*/

.testimonial-2.testimonial-bg .testimonial-text {
    color: #777;
    background-color: rgba(255, 255, 255, 0.9);
}

.testimonial-2.testimonial-bg .testimonial-text:after {
    border-top-color: rgba(255, 255, 255, 0.9);
}


/*testimonial 3*/

.testimonial-3 {
    text-align: center;
}

.testimonial-3 .quote-left:before {
    position: static;
    font-size: 40px;
    color: #efbb20;
}

.testimonial-3 .testimonial-position {
    color: #efbb20;
}

.testimonial-3 .testimonial-name,
.testimonial-3 .testimonial-position {
    display: inline-block;
}


/*testimonial 4*/

.testimonial-4 {
    background-color: #282a3c;
    position: relative;
    padding: 30px 40px 30px 10px;
    margin-left: 140px;
    min-height: 150px;
}

.testimonial-4:after {
    background-color: #282a3c;
    border-left-width: 4px;
    border-left-style: solid;
    border-left-color: #efbb20;
    content: "";
    position: absolute;
    left: -80px;
    top: 0;
    height: 100%;
    width: 80%;
    z-index: -1;
    -moz-transform: skewX(-25deg);
    -webkit-transform: skewX(-25deg);
    -o-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.testimonial-4 .testimonial-pic {
    position: absolute;
    top: 30px;
    left: 0;
    margin-left: -110px;
}

.testimonial-4 [class*="quote-"] {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px;
    border-color: #efbb20;
    border-top-color: transparent !important;
    border-left-color: transparent !important;
}

.testimonial-4 [class*="quote-"]:after,
.testimonial-4 [class*="quote-"]:before {
    top: 0;
    right: -20px;
    font-size: 20px;
    color: #fff;
}

.testimonial-4 .testimonial-text,
.testimonial-4 .testimonial-detail {
    padding: 0;
}

.testimonial-4 .testimonial-text {
    margin-bottom: 10px;
}

.testimonial-4 .testimonial-name,
.testimonial-4 .testimonial-position {
    display: inline-block;
}

.testimonial-4 .testimonial-name:after {
    content: "/";
    color: #efbb20;
    margin: 0 2px 0 5px;
}

.testimonial-4 .testimonial-name {
    text-transform: uppercase;
}

.testimonial-4 .testimonial-position {
    font-style: italic;
    color: #bebebe;
}

.testimonial-4 .testimonial-pic {
    border-color: #efbb20;
    border-width: 3px;
    width: 90px;
    height: 90px;
}

@media only screen and (max-width: 480px) {
    .testimonial-4 .testimonial-pic {
        height: 80px;
        width: 80px;
    }
    .testimonial-4:after {
        transform: skewX(-10deg);
        -moz-transform: skewX(-10deg);
        -webkit-transform: skewX(-10deg);
        -o-transform: skewX(-10deg);
    }
    .testimonial-4 {
        padding: 25px 10px 25px 0px;
        margin-left: 110px;
    }
}


/*testimonial 5*/

.testimonial-5 .testimonial-text {
    border: 2px solid #e6e6e6;
    padding: 30px;
}

.testimonial-5 .testimonial-detail {
    padding: 0 0 0 20px;
    margin-top: -35px;
}

.testimonial-5 .testimonial-pic {
    box-shadow: 0 0 0 2px #e6e6e6;
    width: 70px;
    height: 70px;
}

.testimonial-5 .testimonial-text p {
    font-size: 16px;
    line-height: 30px;
}

.testimonial-5 .info p {
    display: inline-block;
    position: relative;
    top: -10px;
    padding: 0 10px;
}


/*testimonial 6*/

.testimonial-6 .quote-left:before {
    left: 15px;
}

.testimonial-6 .quote-left:before,
.testimonial-6 .quote-right:after {
    color: #b5b5b5;
    font-size: 18px;
}


/* Testimonial Text */

.testimonial-6 .testimonial-text {
    padding: 25px 35px 70px 40px;
}

.testimonial-6 .testimonial-detail {
    position: relative;
    padding: 10px 15px;
    top: -30px;
    width: 85%;
}

.testimonial-6 .testimonial-pic {
    position: absolute;
    right: 20px;
    top: -50px;
    z-index: 2;
}

.testimonial-6 .quote-left:before,
.testimonial-6 .quote-right:after {
    color: #b5b5b5;
    font-size: 18px;
}

.testimonial-6 .quote-left:before {
    left: 15px;
}

.testimonial-6 .quote-right:after {
    right: 15px;
    bottom: 20px;
    top: auto;
}

.testimonial-6 .testimonial-detail:after {
    background-color: inherit;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: -23px;
    top: 0;
    transform: skewX(35deg);
    -moz-transform: skewX(35deg);
    -webkit-transform: skewX(35deg);
    -o-transform: skewX(35deg);
    width: 50px;
    z-index: 1;
}


/* testimonial 7 */

.testimonial-section {
    position: relative;
    z-index: 1;
}

.testimonial-section:after {
    background: #f2f2f2 none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
}

.testimonial-section .col-md-6 {
    padding-left: 30px;
    padding-right: 30px;
}

.testimonial-section .row {
    margin-left: -30px;
    margin-right: -30px;
}

.testimonial-7 .testimonial-text {
    border: 2px solid #e6e6e6;
    padding: 18px;
}

.testimonial-7 .testimonial-detail {
    padding: 0 0 0 20px;
    margin-top: -35px;
}

.testimonial-7 .testimonial-pic {
    box-shadow: 0 0 0 2px #e6e6e6;
    width: 70px;
    height: 70px;
}

.testimonial-7 .testimonial-text p {
    font-size: 16px;
    line-height: 30px;
}

.testimonial-7 .info p {
    display: inline-block;
    position: relative;
    top: -10px;
    padding: 0 10px;
}

.testimonial-7 .owl-controls .owl-nav {
    bottom: 0;
    margin-top: 0;
    position: absolute;
    right: 0;
}

.testimonial-7 .owl-prev,
.testimonial-7 .owl-next {
    background-color: #cccccc;
    border: 0 none;
}

@media only screen and (max-width: 991px) {
    .testimonial-section:after {
        left: 100%;
    }
    .testimonial-section {
        color: #fff;
    }
    .testimonial-section .col-md-6 {
        padding-left: 15px;
        padding-right: 15px;
    }
    .testimonial-section .row {
        margin-left: -15px;
        margin-right: -15px;
    }
}

@media only screen and (max-width: 767px) {
    .testimonial-section:after {
        content: none;
    }
}


/* Testimonial 8 */

.testimonial-8 .testimonial-text {
    background: #ffffff none repeat scroll 0 0;
    border-radius: 6px;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.testimonial-8 .testimonial-text:after {
    border-top: 15px solid #fff;
    border-left: 0 solid rgba(0, 0, 0, 0);
}

.testimonial-8 {
    padding: 15px;
}

.testimonial-box.owl-theme .owl-dots {
    margin-top: 30px;
    position: unset;
    right: auto;
    text-align: center;
    top: auto;
    transform: translateY(-50%);
    width: auto;
}

.owl-item.active.center .testimonial-text {
    background-color: #ff3b68;
    color: #fff;
}

.owl-item.active.center .testimonial-8 .testimonial-text:after {
    border-top: 15px solid #ff3b68;
}

.testimonial-8 .testimonial-text:before {
    content: "\e67e";
    bottom: -10px;
    color: #000000;
    font-family: "themify";
    font-size: 80px;
    opacity: 0.1;
    position: absolute;
    right: 10px;
}


/* testimonial-9 */

.testimonial-9 {
    background-color: #fff;
    padding: 50px 25px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.testimonial-9 .testimonial-text {
    padding: 0;
}

.testimonial-9 .testimonial-name,
.testimonial-10 .testimonial-name {
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    color: #000;
}

.testimonial-9 .testimonial-position,
.testimonial-10 .testimonial-position {
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    color: #888q;
    font-size: 12px;
    font-style: inherit;
}

.testimonial-9 .testimonial-position p,
.testimonial-10 .testimonial-position p {
    line-height: 26px;
}

.testimonial-9 .testimonial-text,
.testimonial-10 .testimonial-text {
    color: #505050;
    font-family: montserrat;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px;
}

.testimonial-9 .testimonial-pic {
    margin-bottom: 15px;
}


/* testimonial-10 */

.testimonial-10 {
    margin: auto;
    max-width: 600px;
    padding-left: 150px;
    position: relative;
}

.testimonial-10 .testimonial-text {
    padding: 0;
}

.testimonial-10 .testimonial-pic {
    border: 0 none;
    height: 130px;
    left: 0;
    position: absolute;
    width: 130px;
}

.testimonial-seven.owl-btn-3 .owl-prev,
.testimonial-seven.owl-btn-3 .owl-next {
    margin: 0 !important;
}


/*== >Pricing table  ===*/

.pricingtable-inner {
    text-align: center;
}

.pricingtable-price {
    padding: 20px 20px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
}

.pricingtable-bx {
    color: #282a3c;
    font-family: montserrat;
    font-size: 45px;
    font-weight: 500;
}

.pricingtable-type {
    font-size: 20px;
    text-transform: uppercase;
    font-size: 16px;
}

.pricingtable-type:before {
    content: "/";
    margin-right: 3px;
}

.pricingtable-title {
    background-color: #fff;
    padding: 20px;
    text-transform: uppercase;
}

.pricingtable-title * {
    margin: 0;
    color: #fff;
    font-weight: 800;
}

.pricingtable-features {
    margin: 0;
    padding: 0;
    list-style: none;
    border: 1px solid #e9e9e9;
}

.pricingtable-features li {
    padding: 12px;
    border-bottom: 1px solid #e9e9e9;
}

.pricingtable-features li i {
    margin: 0 3px;
}

.pricingtable-features li:nth-child(even) {
    background-color: #fff;
}

.pricingtable-features li:last-child {
    border-bottom: none;
}

.pricingtable-footer {
    margin-top: -1px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #e9e9e9;
}

.pricingtable-highlight {
    margin: -20px 0;
    -webkit-box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 99;
}

.pricingtable-highlight .pricingtable-price {
    padding: 30px 20px;
}

.pricingtable-highlight .pricingtable-footer {
    padding: 30px 20px;
}

.no-col-gap .pricingtable-wrapper {
    margin-left: -1px;
}


/* pricingtable Style 1 */

.pricingtable-wrapper.style1 {
    border: 2px solid #d3d3d3;
    padding: 10px 10px 40px;
    font-family: montserrat;
    margin-left: -1px;
}

.pricingtable-wrapper.style1 .pricingtable-icon i {
    color: #000000;
    font-size: 48px;
    line-height: 60px;
}

.pricingtable-wrapper.style1 .pricingtable-price {
    background-color: #fff;
    border: 0;
}

.pricingtable-wrapper.style1 .pricingtable-bx {
    font-size: 35px;
    font-weight: 800;
}

.pricingtable-wrapper.style1 .pricingtable-features {
    border: 0;
}

.pricingtable-wrapper.style1 .pricingtable-features li {
    background: #ffffff none repeat scroll 0 0;
    border: 0 none;
    color: #000;
    font-family: montserrat;
    font-weight: 500;
    padding: 8px 0;
    text-align: left;
}

.pricingtable-wrapper.style1 .pricingtable-features {
    width: 80%;
    margin: auto;
}

.pricingtable-wrapper.style1 .pricingtable-features li i {
    border: 2px solid;
    border-radius: 20px;
    display: block;
    float: right;
    font-size: 11px;
    height: 20px;
    line-height: 16px;
    text-align: center;
    width: 20px;
}

.pricingtable-wrapper.style1 .button-md {
    font-weight: 400;
    padding: 12px 35px;
}

.pricingtable-wrapper.style1 .pricingtable-type {
    text-transform: capitalize;
}

.pricingtable-wrapper.style1.active {
    transform: translateY(-25px);
    -ms-transform: translateY(-25px);
    -moz-transform: translateY(-25px);
    -webkit-transform: translateY(-25px);
    -o-transform: translateY(-25px);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    z-index: 2;
    border: 0;
    padding: 50px 20px;
    margin: 0 -5px;
    position: relative;
    background: #fff;
}

.pricingtable-wrapper.style2 {
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
}

.pricingtable-wrapper.style2 .pricingtable-price {
    padding: 0;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
}

.pricingtable-wrapper.style2 .pricingtable-bx {
    background: rgba(0, 0, 0, 0.02) none repeat scroll 0 0;
    border-radius: 10px;
    color: #a0a0a0;
    display: inline-block;
    font-size: 13px;
    margin-top: 20px;
    padding: 15px 25px;
    margin-bottom: 10px;
}

.pricingtable-wrapper.style2 .pricingtable-bx span {
    font-size: 22px;
}

.pricingtable-wrapper.style2.active {
    margin: -30px -10px 0;
    padding: 60px 30px;
    position: relative;
    z-index: 1;
}

.pricingtable-wrapper.style2.active .pricingtable-bx {
    color: #fff;
}


/*== COUNTERUP ===*/

.counter {
    position: relative;
}

.counter-style-1 .counter,
.counter-style-2 .counter,
.counter-style-3 .counter {
    font-size: 50px;
    font-weight: 700;
}

.counter-style-1 .counter-text,
.counter-style-2 .counter-text {
    font-size: 16px;
    font-weight: 500;
}

.counter-style-1 .icon {
    font-size: 45px;
    margin-right: 10px;
}

.counter-style-3.rounded {
    border: 5px solid;
    border-radius: 200px;
    height: 200px;
    width: 200px;
}

.counter-style-3 .counter-text {
    font-size: 18px;
    font-weight: 400;
    margin: 0 auto;
}

.counter-style-4 {
    border: 5px solid rgba(0, 0, 0, 0.1);
    padding: 10px 10px 20px;
}

.counter-style-4 .counter {
    font-size: 70px;
    font-weight: 500;
}

.counter-style-4 .counter-text {
    font-size: 18px;
    font-weight: 500;
}

.counter-style-4 .icon {
    font-size: 45px;
    margin-right: 10px;
}


/*== >Alert box =====*/

.alert {
    font-family: montserrat;
    border-radius: 4px;
    padding: 15px 20px;
}

.alert.alert-sm {
    padding: 5px 15px;
    font-size: 12px;
}

.alert.alert-lg {
    padding: 25px 15px;
    font-size: 16px;
}

.alert.alert-xl {
    padding: 35px 15px;
    font-size: 18px;
}

.alert[class*="alert-"] i {
    margin-right: 8px;
}

.alert.no-radius {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
}

.alert.no-bg {
    background-color: transparent;
    border-width: 2px;
}

.alert[class*="alert-"] ul {
    padding-left: 25px;
    margin-top: 10px;
}

.alert[class*="alert-"] ul li:before {
    color: #a94442;
}


/* Alert Color */

.alert-warning {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
}

.alert-success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
}

.alert-info {
    background-color: #cce5ff;
    border-color: #b8daff;
    color: #004085;
}

.alert-danger {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
}

.close {
    color: #000000;
    float: right;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    opacity: 0.3;
    text-shadow: none;
    vertical-align: middle;
}


/*== >Modal pop =====*/

.modal-header {
    border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
}

.modal-footer {
    border-radius: 0 0 3px 3px;
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
}

.modal-header {
    background: #efbb20;
    padding: 15px 25px;
}

.modal-header .modal-title {
    color: #fff;
}

.modal .modal-body {
    padding: 25px;
}

.modal-xlg {
    width: 1000px;
}

.provider_registration .bootstrap-select div.dropdown-menu ul li a {
    font-weight: 600;
}

.provider_registration .bootstrap-select div.dropdown-menu ul li a span.childcat {
    font-weight: 400;
    color: #7c7c7c;
}

@media only screen and (max-width: 1024px) {
    .modal-xlg {
        width: auto;
        margin-left: 15px;
        margin-right: 15px;
    }
}


/*== >Social icons ===*/


/* social icon default */

.dlab-social-icon {
    display: inline-block;
    margin: 0 -3px;
    padding: 0;
    text-align: center;
}

.dlab-social-icon li {
    display: inline-block;
    padding: 0;
    font-size: 12px;
}

.dlab-social-icon li a {
    display: inline-block;
    width: 24px;
    height: 24px;
    padding: 4px;
    font-size: 18px;
    color: #fff;
}

.dlab-social-icon li a:hover {
    opacity: 0.8;
    color: #fff;
}

.dlab-social-icon li .fa {
    vertical-align: middle;
}


/* social icon with border */

.dlab-social-icon.border li {
    padding: 0 3px;
}

.dlab-social-icon.border li a {
    border: 1px solid rgba(255, 255, 255, 0.2);
}


/* social icon dark */

.dlab-social-links.dark li {
    padding: 0 3px;
}

.dlab-social-icon.dark li a {
    border: 1px solid #777777;
    color: #777777;
    border-radius: 2px;
    -webkit-border-radius: 2px;
}


/* social share icon style 1*/

.dlab-share-icon li {
    border: none;
    width: 36px;
    display: block;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 0;
}

.dlab-share-icon li a {
    color: #fff;
}

.dlab-share-icon li i {
    border-right: none;
    font-size: 14px;
    color: #fff;
    width: 36px;
    height: 34px;
    line-height: 34px;
    padding: 0;
    text-align: center;
}

.dlab-share-icon li.fb {
    background: #354d89;
}

.dlab-share-icon li.fb i {
    background: #3a5799;
}

.dlab-share-icon li.gp {
    background: #d34b2b;
}

.dlab-share-icon li.gp i {
    background: #e35736;
}

.dlab-share-icon li.tw {
    background: #029fdf;
}

.dlab-share-icon li.tw i {
    background: #00abf0;
}

.dlab-share-icon li.dig {
    background: #1d61aa;
}

.dlab-share-icon li.dig i {
    background: #2b6fb8;
}

.dlab-share-icon li.lin {
    background: #0176be;
}

.dlab-share-icon li.lin i {
    background: #0082ca;
}

.dlab-share-icon li.pin {
    background: #ac0104;
}

.dlab-share-icon li.pin i {
    background: #bd0e15;
}

.dlab-share-icon li:hover {
    width: 90px;
}


/*== >Breadcrumb ====*/

.breadcrumb-row {
    background: #f9f9f9;
    padding: 20px 0;
}

.breadcrumb-row ul {
    margin: 0;
}

.breadcrumb-row ul li {
    padding: 0;
    margin-right: 3px;
    color: #fff;
    display: inline-block;
}

.breadcrumb-row ul li:after {
    content: "\f105";
    margin-left: 7px;
    font-family: fontawesome;
}

.breadcrumb-row ul li:last-child {
    color: #676767;
}

.breadcrumb-row ul li:last-child:after {
    display: none;
}


/*== >Tables ====*/

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding: 10px;
}

.table.borderless>tbody>tr>td,
.table.borderless>tbody>tr>th {
    border: none;
}


/*data table*/

.dataTable .sorting_asc .checkbox {
    margin-right: 0;
}


/*== >Image effects ==*/


/*use for section*/

.overlay-black-light,
.overlay-black-middle,
.overlay-black-dark,
.overlay-gradient-light,
.overlay-gradient-middle,
.overlay-gradient-dark,
.overlay-white-light,
.overlay-white-middle,
.overlay-white-dark,
.overlay-primary-light,
.overlay-primary-middle,
.overlay-primary-dark,
.overlay-primary {
    position: relative;
}

.overlay-black-light:after,
.overlay-black-middle:after,
.overlay-black-dark:after,
.overlay-gradient-light:after,
.overlay-gradient-middle:after,
.overlay-gradient-dark:after,
.overlay-white-light:after,
.overlay-white-middle:after,
.overlay-white-dark:after,
.overlay-primary-light:after,
.overlay-primary-middle:after,
.overlay-primary-dark:after,
.overlay-primary:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.overlay-black-light:after,
.overlay-black-middle:after,
.overlay-black-dark:after {
    background: #000;
}

.overlay-gradient-light:after,
.overlay-gradient-middle:after,
.overlay-gradient-dark:after {
    background: -moz-linear-gradient( top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient( top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient( to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
    /* IE6-9 */
}

.overlay-white-light:after,
.overlay-white-middle:after,
.overlay-white-dark:after {
    background: #fff;
}

.overlay-primary-light:after,
.overlay-primary-middle:after,
.overlay-primary-dark:after,
.overlay-primary:after {
    background: ;
}

.overlay-black-light:after {
    opacity: 0.3;
}

.overlay-black-middle:after {
    opacity: 0.5;
}

.overlay-black-dark:after {
    opacity: 0.8;
}

.overlay-gradient-light:after {
    opacity: 0.3;
}

.overlay-gradient-middle:after {
    opacity: 0.5;
}

.overlay-gradient-dark:after {
    opacity: 0.8;
}

.overlay-white-light:after {
    opacity: 0.5;
}

.overlay-white-middle:after {
    opacity: 0.7;
}

.overlay-white-dark:after {
    opacity: 0.8;
}

.overlay-white-dark.op95:after {
    opacity: 0.95;
}

.overlay-primary-light:after {
    opacity: 0.3;
}

.overlay-primary-middle:after {
    opacity: 0.5;
}

.overlay-primary-dark:after,
.overlay-primary:after {
    opacity: 0.8;
}

.overlay-black-light .container,
.overlay-black-middle .container,
.overlay-black-dark .container,
.overlay-white-light .container,
.overlay-white-middle .container,
.overlay-white-dark .container,
.overlay-primary-light .container,
.overlay-primary-middle .container,
.overlay-primary-dark .container,
.overlay-primary .container,
.overlay-black-light .container-fluid,
.overlay-black-middle .container-fluid,
.overlay-black-dark .container-fluid,
.overlay-white-light .container-fluid,
.overlay-white-middle .container-fluid,
.overlay-white-dark .container-fluid,
.overlay-primary-light .container-fluid,
.overlay-primary-middle .container-fluid,
.overlay-primary-dark .container-fluid,
.overlay-primary .container-fluid {
    position: relative;
    z-index: 1;
}


/*use for box*/

.overlay-bx {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
}

.overlay-icon,
.overlay-bx .align-m {
    height: auto;
    left: 50%;
    list-style: outside none none;
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    width: 100%;
    padding: 0 10px;
}

.overlay-bx .align-b {
    height: auto;
    left: 0;
    list-style: outside none none;
    margin: 0;
    position: absolute;
    top: auto;
    bottom: 0;
    width: 100%;
    transform: translate(0);
    -o-transform: translate(0);
    -moz-transform: translate(0);
    -webkit-transform: translate(0);
}

.overlay-icon a {
    display: inline-block;
    padding: 0;
    margin: 0 2px;
}

.overlay-icon a i {
    background-color: #fff;
}

.overlay-bx:hover a>i,
.dlab-media:hover .overlay-bx a>i,
.dlab-box-bx:hover .overlay-bx a>i {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.overlay-bx:hover,
.dlab-media:hover .overlay-bx,
.dlab-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
    opacity: 1;
    visibility: visible;
}


/* show for text */

.overlay-text {
    width: 100%;
    position: absolute;
    padding: 20px;
    color: #fff;
    left: 0;
    bottom: 20px;
}

.dlab-address-bx {
    display: table;
    text-align: left;
    padding: 20px 20px 20px 25px;
    width: 100%;
    height: 100%;
    position: relative;
    font-size: 14px;
    vertical-align: middle;
    font-weight: 600;
}

.dlab-address-bx .fa {
    position: absolute;
    top: 22px;
    left: 0;
    font-size: 22px;
}

.col-md-3 .overlay-text {
    padding: 5px;
}

.col-md-4 .overlay-text {
    padding: 20px;
}

.dlab-address2-bx {
    display: table;
    background: #eaeaea;
    padding: 5px 10px;
    margin-bottom: 10px;
}


/* == >Icon boxes ==*/

.icon-bx-xl,
.icon-bx-lg,
.icon-bx-md,
.icon-bx-sm,
.icon-bx-xs {
    display: inline-block;
    text-align: center;
    border-radius: 3px;
}

.icon-bx-xl {
    width: 150px;
    height: 150px;
    line-height: 150px;
}

.icon-bx-lg {
    width: 120px;
    height: 120px;
    line-height: 120px;
}

.icon-bx-md {
    width: 100px;
    height: 100px;
    line-height: 100px;
}

.icon-bx-sm {
    width: 80px;
    height: 80px;
    line-height: 80px;
}

.icon-bx-xs {
    width: 40px;
    height: 40px;
    line-height: 40px;
}

.icon-bx-xl.radius,
.icon-bx-lg.radius,
.icon-bx-md.radius,
.icon-bx-sm.radius,
.icon-bx-xs.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}

.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
    border-style: solid;
}

.border-1 {
    border-width: 1px;
}

.border-2 {
    border-width: 2px;
}

.border-3 {
    border-width: 3px;
}

.border-4 {
    border-width: 4px;
}

.border-5 {
    border-width: 5px;
}


/* Border Color */

.br-col-w1,
.dlab-info.br-col-w1[class*="border-"],
.icon-bx-wraper.bx-style-1.br-col-w1,
.icon-bx-wraper.bx-style-2.br-col-w1 {
    border-color: rgba(255, 255, 255, 0.15);
}

.br-col-w2,
.dlab-info.br-col-w2[class*="border-"],
.icon-bx-wraper.bx-style-1.br-col-w2,
.icon-bx-wraper.bx-style-2.br-col-w2 {
    border-color: rgba(255, 255, 255, 0.3);
}

.br-col-w3,
.dlab-info.br-col-w3[class*="border-"],
.icon-bx-wraper.bx-style-1.br-col-w3,
.icon-bx-wraper.bx-style-2.br-col-w3 {
    border-color: rgba(255, 255, 255, 0.5);
}

.br-col-w4,
.dlab-info.br-col-w4[class*="border-"],
.icon-bx-wraper.bx-style-1.br-col-w4,
.icon-bx-wraper.bx-style-2.br-col-w4 {
    border-color: rgba(255, 255, 255, 0.7);
}

.br-col-w5,
.dlab-info.br-col-w5[class*="border-"],
.icon-bx-wraper.bx-style-1.br-col-w5,
.icon-bx-wraper.bx-style-2.br-col-w5 {
    border-color: rgba(255, 255, 255, 0.9);
}

.br-col-b1,
.dlab-info.br-col-b1[class*="border-"],
.icon-bx-wraper.bx-style-1.br-col-b1,
.icon-bx-wraper.bx-style-2.br-col-b1 {
    border-color: rgba(0, 0, 0, 0.15);
}

.br-col-b2,
.dlab-info.br-col-b2[class*="border-"],
.icon-bx-wraper.bx-style-1.br-col-b2,
.icon-bx-wraper.bx-style-2.br-col-b2 {
    border-color: rgba(0, 0, 0, 0.3);
}

.br-col-b3,
.dlab-info.br-col-b3[class*="border-"],
.icon-bx-wraper.bx-style-1.br-col-b3,
.icon-bx-wraper.bx-style-2.br-col-b3 {
    border-color: rgba(0, 0, 0, 0.5);
}

.br-col-b4,
.dlab-info.br-col-b4[class*="border-"],
.icon-bx-wraper.bx-style-1.br-col-b4,
.icon-bx-wraper.bx-style-2.br-col-b4 {
    border-color: rgba(0, 0, 0, 0.7);
}

.br-col-b5,
.dlab-info.br-col-b5[class*="border-"],
.icon-bx-wraper.bx-style-1.br-col-b5,
.icon-bx-wraper.bx-style-2.br-col-b5 {
    border-color: rgba(0, 0, 0, 0.9);
}


/* opacity */

.op0 {
    opacity: 0;
}

.op1 {
    opacity: 0.1;
}

.op2 {
    opacity: 0.2;
}

.op3 {
    opacity: 0.3;
}

.op4 {
    opacity: 0.4;
}

.op5 {
    opacity: 0.5;
}

.op6 {
    opacity: 0.6;
}

.op7 {
    opacity: 0.7;
}

.op8 {
    opacity: 0.8;
}

.op9 {
    opacity: 0.9;
}

.icon-bx-xl i,
.icon-bx-lg i,
.icon-bx-md i,
.icon-bx-sm i,
.icon-bx-xs i {
    vertical-align: middle;
}

.icon-bx-xl i {
    font-size: 80px;
}

.icon-bx-lg i {
    font-size: 60px;
}

.icon-bx-md i {
    font-size: 45px;
}

.icon-bx-sm i {
    font-size: 30px;
}

.icon-bx-xs i {
    font-size: 20px;
}

.icon-bx-xl img,
.icon-bx-lg img,
.icon-bx-md img,
.icon-bx-sm img,
.icon-bx-xs img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
}

.icon-bx-xl img {
    height: 80px;
}

.icon-bx-lg img {
    height: 55px;
}

.icon-bx-md img {
    height: 40px;
}

.icon-bx-sm img {
    height: 30px;
}

.icon-bx-xs img {
    height: 20px;
}


/*== 11. ICON ==*/

.icon-xl,
.icon-lg,
.icon-md,
.icon-sm,
.icon-xs {
    display: inline-block;
    text-align: center;
}

.icon-xl {
    width: 100px;
}

.icon-lg {
    width: 80px;
}

.icon-md {
    width: 60px;
}

.icon-sm {
    width: 40px;
}

.icon-xs {
    width: 30px;
}

.icon-xl i,
.icon-lg i,
.icon-md i,
.icon-sm i,
.icon-xs i {
    vertical-align: middle;
}

.icon-xl i {
    font-size: 80px;
}

.icon-lg i {
    font-size: 60px;
}

.icon-md i {
    font-size: 45px;
}

.icon-sm i {
    font-size: 30px;
}

.icon-xs i {
    font-size: 20px;
}

.icon-xl img,
.icon-lg img,
.icon-md img,
.icon-sm img,
.icon-xs img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
}

.icon-xl img {
    height: 80px;
}

.icon-lg img {
    height: 70px;
}

.icon-md img {
    height: 50px;
}

.icon-sm img {
    height: 30px;
}

.icon-xs img {
    height: 20px;
}


/*== place icon with box ==*/

.icon-bx-wraper {
    position: relative;
}

.icon-bx-wraper .dlab-tilte {
    margin-top: 0;
}

.icon-bx-wraper .after-titile-line {
    margin-bottom: 10px;
}

.icon-bx-wraper p:last-child {
    margin: 0;
}

.icon-content {
    overflow: hidden;
}


/* icon box left aligh */

.icon-bx-wraper.left .icon-bx-xl,
.icon-bx-wraper.left .icon-bx-lg,
.icon-bx-wraper.left .icon-bx-md,
.icon-bx-wraper.left .icon-bx-sm,
.icon-bx-wraper.left .icon-bx-xs {
    float: left;
    margin-right: 20px;
}

.icon-bx-wraper.left .icon-xl,
.icon-bx-wraper.left .icon-lg,
.icon-bx-wraper.left .icon-md,
.icon-bx-wraper.left .icon-sm,
.icon-bx-wraper.left .icon-xs {
    float: left;
    margin-right: 10px;
}


/* icon box right aligh */

.icon-bx-wraper.right {
    text-align: right;
}

.icon-bx-wraper.right .icon-bx-xl,
.icon-bx-wraper.right .icon-bx-lg,
.icon-bx-wraper.right .icon-bx-md,
.icon-bx-wraper.right .icon-bx-sm,
.icon-bx-wraper.right .icon-bx-xs {
    float: right;
    margin-left: 20px;
}

.icon-bx-wraper.right .icon-xl,
.icon-bx-wraper.right .icon-lg,
.icon-bx-wraper.right .icon-md,
.icon-bx-wraper.right .icon-sm,
.icon-bx-wraper.right .icon-xs {
    float: right;
    margin-left: 10px;
}


/* icon box center aligh */

.icon-bx-wraper.center {
    text-align: center;
}

[class*="icon-bx-"][class*="bg-"] a {
    color: #fff;
}

[class*="icon-bx-"].bg-white a {
    color: inherit;
}

[class*="icon-bx-"][class*="border-"] {
    display: table;
    line-height: normal;
    margin-left: auto;
    margin-right: auto;
}

[class*="icon-bx-"][class*="border-"] .icon-cell {
    display: table-cell;
    vertical-align: middle;
}

.icon-bx-wraper.bx-style-1,
.icon-bx-wraper.bx-style-2 {
    border-width: 1px;
    border-style: solid;
    border-color: #ebedf2;
}

.icon-bx-wraper.bx-style-2.center [class*="icon-bx-"],
.icon-bx-wraper.bx-style-2.left [class*="icon-bx-"],
.icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
    position: absolute;
}

.icon-bx-wraper.bx-style-2.center [class*="icon-bx-"] {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.icon-bx-wraper.bx-style-2.center .icon-bx-xl {
    margin-left: -75px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-lg {
    margin-left: -60px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-md {
    margin-left: -50px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-sm {
    margin-left: -40px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-xs {
    margin-left: -20px;
}

.icon-bx-wraper.bx-style-2.left [class*="icon-bx-"] {
    position: absolute;
    top: auto;
    left: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
    position: absolute;
    top: auto;
    right: 0;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}


/*== media with content box css ==*/

.dlab-box,
.dlab-info,
.dlab-tilte,
.dlab-tilte-inner {
    position: relative;
}

.dlab-tilte-inner {
    display: inline-block;
}

.dlab-tilte-inner.skew-title:after {
    content: "";
    position: absolute;
    right: -15px;
    top: 0;
    width: 50px;
    height: 100%;
    z-index: -1;
    -moz-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
    -o-transform: skewX(20deg);
    -ms-transform: skewX(20deg);
    transform: skewX(20deg);
}

.dlab-box[class*="border-"],
.dlab-info[class*="border-"] {
    border-color: #eee;
}

.dlab-info.border-1,
.dlab-info.border-2,
.dlab-info.border-3,
.dlab-info.border-4,
.dlab-info.border-5 {
    border-top: none;
}


/*== before & after for line css ==*/

.left-border,
.right-border {
    position: relative;
}

.left-border:before,
.right-border:before {
    content: "";
    position: absolute;
    top: 5px;
    width: 1px;
    height: 90%;
    background: #ccc;
}

.right-border:before {
    right: 0;
}


/*==for images==*/

.dlab-media,
.dlab-media,
.dlab-post-media {
    position: relative;
}

.dlab-media img,
.dlab-post-media img {
    width: 100%;
    height: auto;
}


/* Box Background */

.dlab-box-bg {
    overflow: hidden;
}

.dlab-box-bg .site-button {
    overflow: unset;
}

.dlab-box-bg .glyph-icon {
    font-size: 50px;
}

.dlab-box-bg .icon-bx-wraper {
    background-color: #f8f8f8;
}

.icon-box-btn .site-button {
    background-color: #eeeeee;
    padding: 15px 20px;
    text-transform: uppercase;
    font-size: 15px;
}

.dlab-box-bg {
    background-size: cover;
}

.dlab-box-bg.active .icon-bx-wraper {
    background-color: rgba(0, 0, 0, 0.7);
}

.dlab-box-bg .text-primary,
.dlab-box-bg .icon-content .dlab-tilte,
.dlab-box-bg .icon-content p,
.dlab-box-bg .icon-box-btn .site-button {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.dlab-box-bg.active .text-primary,
.dlab-box-bg.active .icon-content .dlab-tilte,
.dlab-box-bg.active .icon-content p,
.dlab-box-bg.active .icon-box-btn .site-button {
    color: #fff;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.dlab-box-bg .icon-box-btn .site-button {
    border-radius: 0;
    color: #1abc9c;
    font-weight: 600;
    margin-bottom: 0;
}


/* >Box New Style */

.content-box-head {
    border-radius: 50px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    font-family: "Open Sans", sans-serif;
    margin-bottom: 20px;
    margin-top: -46px;
    padding: 12px 0;
    position: relative;
    z-index: 1;
}


/*---------------------------------------------------------------
25. PAGINATION
---------------------------------------------------------------*/


/* pagination style-1 */

.pagination-bx .pagination,
.cvf-universal-pagination .pagination {
    margin: 0;
}

.pagination {
    padding: 10px 0;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin-left: 0;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.pagination>li>a,
.pagination>li>span {
    background-color: #fff;
    border: 1px solid #efefef;
    color: #767676;
    padding: 8px 14px;
    font-weight: 400;
    font-family: montserrat;
    font-size: 14px;
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
    border-color: transparent;
    color: #fff;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    border-color: transparent;
}

.pagination>.previous>a,
.pagination>.next>a {
    padding: 8px 14px;
    font-size: 14px;
    font-weight: 500;
}

.pagination-sm .pagination>li>a,
.pagination-sm .pagination>li>span .pagination-sm .pagination>.previous>a,
.pagination-sm .pagination>.next>a {
    font-size: 12px;
    padding: 6px 12px;
}

.pagination-lg .pagination>li>a,
.pagination-lg .pagination>li>span .pagination-lg .pagination>.previous>a,
.pagination-lg .pagination>.next>a {
    font-size: 18px;
    padding: 10px 20px;
}


/* pagination color */

.pagination-bx.primary {}

.pagination-bx.primary .pagination>li>a,
.pagination-bx.primary .pagination>li>span {
    background-color: #1abc9c;
    color: #fff;
    margin: 0 1px;
    border: 0;
}

.pagination-bx.primary .pagination>li>a:hover,
.pagination-bx.primary .pagination>li>span:hover,
.pagination-bx.primary .pagination>li.active>span,
.pagination-bx.primary .pagination>li.active>a {
    background-color: #148f77;
}

.pagination-bx.rounded .pagination>li>a,
.pagination-bx.rounded .pagination>li>span {
    border-radius: 30px;
    margin: 0 1px;
}

.pagination-bx.rounded-sm .pagination>li>a,
.pagination-bx.rounded-sm .pagination>li>span {
    border-radius: 4px;
    margin: 0 1px;
}

.pagination-bx .pull-right {
    float: right;
}

.pagination-bx .pagination {
    width: 100%;
}

.pagination-bx.gray .pagination>li>a,
.pagination-bx.gray .pagination>li>span {
    background-color: #f6f7f8;
    margin: 0 1px;
    border: 0;
}

.pagination-bx.gray .pagination>li>a:hover,
.pagination-bx.gray .pagination>li>span:hover,
.pagination-bx.gray .pagination>li.active>span,
.pagination-bx.gray .pagination>li.active>a {
    background-color: #1abc9c;
    box-shadow: 2px 2px 8px 0 rgba(26, 188, 156, 0.5);
    color: #fff;
}

@media only screen and (max-width: 991px) {
    .pagination-bx {
        margin-bottom: 30px;
    }
}


/*DataTable  pagination */

.dataTables_paginate ul.pagination {
    margin: 0;
}

.dataTables_paginate .pagination>li {
    padding: 0;
}


/*---------------------------------------------------------------
26. FOOTER
---------------------------------------------------------------*/


/*newslatter*/


/* footer fixed on bottom css*/

.footer-fixed .site-footer {
    position: fixed;
    width: 100%;
    bottom: 0;
}

.footer-fixed .page-content {
    position: relative;
    z-index: 1;
    background-color: #fff;
}

footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6,
footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a {
    color: #b0b0b0;
}

footer p,
footer strong,
footer b,
footer {
    color: #b0b0b0;
}

footer h1 a,
footer h2 a,
footer h3 a,
footer h4 a,
footer h5 a,
footer h6 a,
footer p a {
    color: #b0b0b0;
}

footer a,
footer a:visited {
    color: #b0b0b0;
}

footer p a {
    color: #b0b0b0;
}

footer a:active,
footer a:focus,
footer a:hover {
    color: #b0b0b0;
}


/* widget color */

footer .widget_categories ul li a,
footer .widget_archive ul li a,
footer .widget_meta ul li a,
footer .widget_pages ul li a,
footer .widget_recent_comments ul li a,
footer .widget_nav_menu li a,
footer .widget_recent_entries ul li a,
footer .widget_services ul li a {
    color: #b0b0b0;
}

footer.text-white .widget_categories ul li a,
footer.text-white .widget_archive ul li a,
footer.text-white .widget_meta ul li a,
footer.text-white .widget_pages ul li a,
footer.text-white .widget_recent_comments ul li a,
footer.text-white .widget_nav_menu li a,
footer.text-white .widget_recent_entries ul li a,
footer.text-white .widget_services ul li a,
footer.text-white a,
footer.text-white .footer-bottom,
footer.text-white p,
footer.text-white strong,
footer.text-white b,
footer.text-white .widget .post-title,
footer.text-white .widget-about .dlab-title,
footer.text-white {
    color: #fff;
}

footer p {
    margin-bottom: 10px;
}

footer p,
footer li {
    font-size: 15px;
    line-height: 22px;
}

footer#footer {
    background-position: center;
    background-size: cover;
}

footer p {
    line-height: 24px;
    margin-bottom: 10px;
}

footer .widget ul {
    list-style: none;
    margin-top: 5px;
}


/*widget li in footer*/

footer .widget_categories ul li,
footer .widget_archive ul li,
footer .widget_meta ul li,
footer .widget_pages ul li,
footer .widget_recent_comments ul li,
footer .widget_nav_menu li,
footer .widget_recent_entries ul li,
footer .widget_services ul li {
    border-bottom: 1px dashed rgba(102, 102, 102, 0.3);
}

footer .widget_services ul li {
    transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
    -moz-transition: all 1s;
    -webkit-transition: all 1s;
    padding: 10px 0px 10px 15px;
}

footer .widget_services ul li:hover {
    transform: translateX(10px);
    -moz-transform: translateX(10px);
    -webkit-transform: translateX(10px);
    -o-transform: translateX(10px);
    -ms-transform: translateX(10px);
}


/*widget Getintuch*/

.footer-top {
    background: #000;
    background-size: cover;
    background-position: center;
    padding: 80px 0 20px;
}

.footer-bottom {
    background-color: #000;
    padding: 25px 0;
    color: #b0b0b0;
    border-top: 1px solid rgba(255, 255, 255, 0.05);
}

.footer-bottom ul {
    margin: 0;
}


/* Footer Full */

.footer-full .container-fluid {
    padding-left: 50px;
    padding-right: 50px;
}

@media only screen and (max-width: 991px) {
    .col-md-3.col-sm-6.footer-col-4:nth-child(3) {
        clear: both;
    }
    .footer-full .text-left,
    .footer-full .text-right,
    .footer-full .text-center {
        text-align: center;
        margin-bottom: 30px;
    }
    .footer-full .text-right {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 767px) {
    .footer-clear {
        display: block !important;
        clear: both;
    }
    #footer .footer-4-col {
        width: 100%;
    }
    .footer-bottom [class*="clo-"] {
        width: 100%;
    }
}


/* Subscribe Form */

.subscribe-form input {
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 30px !important;
    height: 50px;
    padding: 10px 20px;
    margin-right: 5px;
    color: #fff;
    font-size: 15px;
}

.subscribe-form .site-button {
    border-radius: 0px 3px 3px 0px;
}

.subscribe-form input.radius-no {
    border-radius: 0 !important;
}

.subscribe-form .site-button {
    height: 50px;
}

.subscribe-form .input-group-btn {
    padding-left: 10px;
}


/*  map footer */

.map-footer .footer-top {
    padding: 0;
}


/* scroll top btn css */

button.scroltop {
    background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
    border-color: #efbb20;
    border-radius: 20px;
    border-style: solid;
    border-width: 0;
    bottom: 15px;
    box-shadow: 2px 2px 12px -5px #000000;
    color: #efbb20;
    cursor: pointer;
    display: none;
    height: 40px;
    line-height: 26px;
    margin: 0;
    position: fixed;
    right: 15px;
    text-align: center;
    width: 40px;
    z-index: 999;
}


/* Footer White */

.footer-white {
    border-top: 0px solid #e0e0e0;
}

.footer-white .form-control {
    border-color: rgba(0, 0, 0, 0.1);
}

.footer-white .footer-top {
    background-color: #fdfdfd;
}

.footer-white .footer-bottom {
    background-color: #efefef;
}

footer.footer-white h1,
footer.footer-white h2,
footer.footer-white h3,
footer.footer-white h4,
footer.footer-white h5,
footer.footer-white h6,
footer.footer-white h1 a,
footer.footer-white h2 a,
footer.footer-white h3 a,
footer.footer-white h4 a,
footer.footer-white h5 a,
footer.footer-white h6 a,
footer.footer-white.site-footer .widget_services ul li a,
footer.footer-white p,
footer.footer-white strong,
footer.footer-white b,
footer.footer-white,
footer.footer-white .footer-bottom,
footer.footer-white .footer-bottom a {
    color: #000;
}

.footer-white .footer-bottom {
    border: 0;
}

.footer-white .widget_getintuch i {
    background-color: rgba(0, 0, 0, 0);
    border: 0 solid rgba(0, 0, 0, 0);
    font-size: 20px;
}

.footer-contact-info {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}


/* Footer Color */

.footer-gray .footer-top,
.footer-gray .footer-bottom {
    background-color: #292929;
}

.footer-gray-1 .footer-top,
.footer-gray-1 .footer-bottom {
    background-color: #1a1a1a;
}

.site-footer .icon-bx-wraper {
    overflow: hidden;
}

.site-footer .icon-content {
    overflow: unset;
}

.site-footer .dlab-tilte {
    position: inherit;
}

.site-footer .site-button.circle {
    color: #fff;
    font-size: 16px;
}

@media only screen and (max-width: 480px) {
    footer .footer-info-bar .col-xs-6,
    footer .footer-contact-info .col-xs-6 {
        width: 100%;
    }
}


/*---------------------------------------------------------------
27. PAGE-CONTENT
---------------------------------------------------------------*/

.page-wraper {
    background: #f8f8f8;
}

.page-content {
    margin-top: 0;
    padding-bottom: 50px;
}

.content-area {
    padding-top: 60px;
}

.section-full {
    position: relative;
}

.section-full:last-child {
    margin-bottom: -50px;
}


/*= Section Head Part =*/

.section-head {
    margin-bottom: 50px;
}

.section-head.no-margin {
    margin-bottom: 0;
}

.section-head h1,
.section-head h2,
.section-head h3 {
    margin-top: 0;
}

.title-small {
    display: block;
    color: #494949;
    margin-bottom: 15px;
}

.section-head p {
    padding-top: 10px;
    font-size: 16px;
    color: #0f192d;
}

.text-center.section-head p {
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
}

.text-white {
    color: #fff;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white .title-small {
    color: #fff;
}


/*= Section Content Part =*/

.section-content {}

@media only screen and (max-width: 1024px) {
    .section-full {
        background-attachment: scroll !important;
    }
}

@media only screen and (max-width: 991px) {
    .page-content {
        margin-top: 0;
    }
}


/*---------------------------------------------------------------
28. INNER-CONTENT
---------------------------------------------------------------*/


/*page not found*/

.page-notfound {
    padding: 50px 0;
}

.page-notfound strong {
    font-size: 200px;
    font-weight: 900;
    line-height: 140px;
    display: block;
}

.page-notfound strong i {
    font-size: 190px;
    margin: 0 10px;
}

.page-notfound .searchform {
    position: relative;
    text-align: left;
    max-width: 420px;
    margin: 30px auto;
}

@media only screen and (max-width: 767px) {
    .page-notfound strong i,
    .page-notfound strong {
        font-size: 120px;
    }
}


/* required classes */

.sticky {
    clear: both;
}

.gallery-caption {
    clear: both;
}

.bypostauthor {
    clear: both;
}


/*Text meant only for screen readers. */

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #21759b;
    display: block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
}


/*===============================================

	gallery pages css

=============================================== */

.dlab-gallery-listing {
    list-style: none;
    margin-bottom: 0;
}


/*===============================================

	Layout boxed css

=============================================== */

#bg {
    background-attachment: fixed;
    background-size: cover;
}

.boxed .page-wraper {
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.boxed .tp-banner-container,
.boxed .rev_slider_wrapper {
    left: 0 !important;
    width: 100% !important;
}

.boxed .tp-rightarrow {
    right: 0 !important;
}

.boxed .tp-leftarrow {
    left: 0 !important;
}

.boxed.footer-fixed .site-footer {
    left: 50%;
    width: 1200px;
    margin: 0 -600px;
}

.boxed .is-fixed .main-bar {
    left: 50%;
    width: 1200px;
    margin: 0 -600px;
}

.boxed .is-fixed.header-curve .logo-header:after {
    right: auto;
    left: -15px;
    width: 90%;
}


/* Frame */

.frame {
    padding: 30px;
}

.frame .page-wraper {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.frame .tp-banner-container,
.frame .rev_slider_wrapper {
    left: 0 !important;
    width: 100% !important;
}

.frame .tp-rightarrow {
    right: 0 !important;
}

.frame .tp-leftarrow {
    left: 0 !important;
}

.frame .is-fixed .main-bar {
    left: 0;
    width: 100%;
    margin: 0;
}

.frame .is-fixed.header-curve .logo-header:after {
    right: auto;
    left: -15px;
    width: 90%;
}

.frame button.scroltop {
    right: 50px;
    bottom: 50px;
}

@media only screen and (max-width: 1024px) {
    .frame {
        padding: 0;
    }
    .frame button.scroltop {
        right: 15px;
        bottom: 15px;
    }
}

.owl-imgbx,
.ow-portfolio-img {
    position: relative;
}

.ow-entry-content {
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #eee;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 5px;
}

.ow-entry-title {
    font-size: 16px;
    font-weight: 600;
    padding: 5px 0;
}

.ow-entry-title,
.ow-entry-title a {
    color: #3d474a;
}

.ow-entry-text p:last-child {
    margin: 0;
}


/*product detail page css*/


/* Product Details*/

.btn-quantity {
    width: 150px;
}

.product-description.dex-tabs.border-top .nav-tabs>li.active>a {
    border-top: 2px solid #efbb20;
}

.product-description.dex-tabs .nav-tabs>li>a i {
    color: #efbb20;
}

.dex-tabs.bg-tabs .nav-tabs>li>a {
    background-color: #fcfcfc;
}

.btn-quantity.style-1 {
    width: 130px;
}

.btn-quantity.style-1 input {
    background: #f8f8f8 none repeat scroll 0 0;
    border: 0 none;
    font-size: 14px;
    padding: 0 45px;
    text-align: center;
}

.btn-quantity.style-1 .input-group-btn-vertical button:last-child,
.btn-quantity.style-1 .input-group-btn-vertical button:first-child {
    background: #ebebeb none repeat scroll 0 0;
    border: 0 none;
    position: absolute;
    height: 40px;
    top: 0;
    width: 40px;
    padding: 0;
    z-index: 2;
    margin: 0;
}

.btn-quantity.style-1 .input-group-btn-vertical button:hover {
    background-color: #d1d1d1;
}

.btn-quantity.style-1 .input-group-btn-vertical button:first-child {
    right: 0;
}

.btn-quantity.style-1 .input-group-btn-vertical button:last-child {
    left: 0;
}

.btn-quantity.style-1 .bootstrap-touchspin .input-group-btn-vertical i {
    color: #909090;
    font-size: 16px;
    position: unset;
}

.btn-quantity.style-1 .input-group {
    position: relative;
}

.btn-quantity.style-1 .bootstrap-touchspin .input-group-btn-vertical {
    position: unset;
}


/*Product Review*/

ol.commentlist {
    list-style: none;
    margin: 0;
}

ol.commentlist li {
    position: relative;
    padding: 0;
    margin-bottom: 20px;
    background: #f7f8fa;
    padding: 20px;
    border: 1px dashed #eeeeee;
}

ol.commentlist li img {
    float: left;
    border: 5px solid #fff;
    width: 80px;
    height: auto;
}

ol.commentlist li .comment-text {
    padding-left: 100px;
}

ol.commentlist li .meta {
    margin-bottom: 5px;
}

ol.commentlist li .meta strong {
    font-size: 16px;
}

ol.commentlist li .meta .time {
    color: #999;
    display: block;
    font-size: 14px;
}

ol.commentlist li .description p {
    margin: 0;
}

ol.commentlist li .star-rating {
    position: absolute;
    top: 20px;
    right: 20px;
}

.comment-reply-title {
    margin-top: 0;
}

.comment-form [class*="comment-form"] {
    width: 49%;
}

.comment-form label {
    display: block;
}

.comment-form [class*="comment-form"] input,
.comment-form [class*="comment-form"] textarea {
    border: 1px solid #ccc;
    padding: 8px 10px;
    width: 100%;
}

.comment-form {
    margin: 0 -15px;
}

.comment-form [class*="comment-form"],
.comment-form .form-submit {
    margin-bottom: 20px;
    padding: 0 15px;
}

.comment-form .form-submit input:active,
.comment-form .form-submit input:focus,
.comment-form .form-submit input:hover {
    background: #037a85;
}

.dlab-quik-search {
    background-color: rgba(0, 0, 0, 0.9) !important;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: none;
    overflow: hidden;
}

.dlab-quik-search.On form {
    transition: all 0.5s ease 0.5s;
    -moz-transition: all 0.5s ease 0.5s;
    -webkit-transition: all 0.5s ease 0.5s;
    max-width: 1200px;
    opacity: 1;
}

.dlab-quik-search form {
    width: 100%;
    max-width: 100px;
    margin: auto;
    position: relative;
    top: 50%;
    transition: all 0.5s;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transform: translate(0px, -50%);
    -moz-transform: translate(0px, -50%);
    -o-transform: translate(0px, -50%);
    -webkit-transform: translate(0px, -50%);
    opacity: 0;
}

.dlab-quik-search .form-control {
    padding: 15px 60px 15px 15px;
    width: 100%;
    height: 90px;
    border: none;
    background: none;
    color: #fff;
    font-size: 20px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.dlab-quik-search .form-control::-moz-placeholder {
    color: #fff;
}

.dlab-quik-search .form-control:-moz-placeholder {
    color: #fff;
}

.dlab-quik-search .form-control:-ms-input-placeholder {
    color: #fff;
}

.dlab-quik-search .form-control::-webkit-input-placeholder {
    color: #fff;
}

.dlab-quik-search span {
    position: absolute;
    right: 15px;
    top: 50%;
    margin: -15px 0;
    height: 25px;
    font-size: 20px;
    cursor: pointer;
}


/* form control style */

.form-box1 .form-control {
    height: 50px;
    padding: 15px 20px;
    font-size: 16px;
    text-transform: capitalize;
    border: 2px solid rgba(0, 0, 0, 0.05);
}

.form-box1 textarea.form-control {
    height: 150px;
}

.form-box1 .form-control:focus,
.form-box1 .form-control:hover {
    border: 2px solid rgba(0, 0, 0, 0.2);
}

#gmap_canvas {
    width: 100%;
    height: 400px;
}

#gmap_canvas img {
    max-width: none !important;
    background: none !important;
}


/* sosiyal btn color */

.fb-btn {
    background-color: #4867aa;
}

.tw-btn {
    background-color: #1da1f2;
}

.gplus-btn {
    background-color: #dc4a38;
}

.pin-btn {
    background-color: #cc2127;
}

.link-btn {
    background-color: #0274b3;
}

.dlab-social-icon.dlab-social-icon-lg li a {
    font-size: 14px;
    height: 35px;
    line-height: 34px;
    padding: 0;
    width: 35px;
}


/* Team Section */

.dlab-media.dlab-media-left .dlab-info-has,
.dlab-media.dlab-media-top .dlab-info-has,
.dlab-media.dlab-media-right .dlab-info-has {
    bottom: auto;
    margin-bottom: 0;
    top: 0;
}


/* Top Side Icon */

.dlab-media.dlab-media-top .dlab-info-has {
    margin-top: -100px;
}

.dlab-media.dlab-media-top:hover .dlab-info-has,
.dlab-box:hover .dlab-media.dlab-media-top .dlab-info-has {
    margin-top: 0;
}


/* Left Side Icon */

.dlab-media.dlab-media-left .dlab-info-has {
    left: 0;
    padding: 15px;
    width: auto;
    margin-left: -60px;
}

.dlab-media.dlab-media-left:hover .dlab-info-has {
    margin-left: 0px;
}

.dlab-media.dlab-media-left .dlab-social-icon li {
    display: block;
    margin: 3px 0;
}


/* Right Side Icon */

.dlab-media.dlab-media-right .dlab-info-has {
    right: 0;
    padding: 15px;
    width: auto;
    left: auto;
    margin-right: -60px;
}

.dlab-media.dlab-media-right:hover .dlab-info-has {
    margin-right: 0px;
}

.dlab-media.dlab-media-right .dlab-social-icon li {
    display: block;
    margin: 3px 0;
}


/* Team 4 */

.rounded-team .round-box {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    left: 0px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    width: 195px;
    height: 195px;
    border-radius: 100%;
    overflow: hidden;
    padding: 10px;
    display: block;
    border: 1px solid #fff;
}

.rounded-team .team-mamber {
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow: hidden;
    border-radius: 100%;
    background-color: #fff;
}

.rounded-team .team-mamber img {
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
}

.rounded-team .sosial-icon-team {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    border-radius: 100%;
    display: block;
    height: 195px;
    overflow: hidden;
    position: absolute;
    right: 50%;
    top: 0;
    width: 195px;
    margin-right: -97px;
}

.rounded-team .sosial-icon-team ul.rounde-team-social-icon li a,
.rounded-team .sosial-icon-team ul li {
    width: 100%;
    display: block;
    color: #fff;
}

.rounded-team .sosial-icon-team ul.rounde-team-social-icon {
    margin-right: -5px;
    margin-top: 28px;
    width: 80px;
    float: right;
}

.rounded-team {
    position: relative;
}

.rounded-team:hover .sosial-icon-team {
    margin-right: -117px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.rounded-team:hover .round-box {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    left: -20px;
}

.rounded-team .team-info {
    border-color: #d3d3d3;
    margin-top: -27px;
}

.rounded-team .team-social-btn ul li a {
    background-color: #c0c0c0;
}

.rounded-team .rounde-team-social-icon li a {
    color: #ffffff;
    font-size: 14px;
    height: 35px;
    line-height: 34px;
    padding: 0;
    width: 35px;
}


/* team 5 */

.team-box-type {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.team-mamber-img,
.team-info-box {
    width: 50%;
}

.team-info-box {
    padding: 20px;
}

.team-mamber-img,
.team-info-box {
    display: table-cell;
    vertical-align: middle;
}

.team-box-type,
.team-info-box {
    overflow: hidden;
}

.team-p-info {
    list-style: none;
    padding: 0;
    margin: 0;
}

.team-p-info li {
    display: block;
    width: 100%;
    font-size: 15px;
    padding: 5px 0;
    color: #404040;
}

@media only screen and (max-width: 767px) {
    .team-mamber-img,
    .team-info-box {
        width: 100%;
        display: block;
    }
    .team-mamber-img,
    .team-info-box {}
}


/*---------------------------------------------------------------
29. SIDE-NAV
---------------------------------------------------------------*/

.sidenav.cs-sidenav {
    width: 800px;
    right: -820px;
    left: auto;
}

.sidenav {
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: 9;
    top: 0;
    left: -320px;
    background-color: #fff;
    overflow-x: hidden;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
    border: 0 none;
    border-radius: 0;
}

.sidenav>ul li a {
    padding: 10px 15px;
    display: block;
    border-color: #ebebeb;
    border-image: none;
    border-style: solid;
    border-width: 1px 0;
    display: block;
    margin-bottom: -1px;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 600;
}

.sidenav>ul li a:hover {
    color: #fff;
}

.sidenav .logo-header {
    height: auto;
    padding: 15px;
    width: 100%;
}

.button-side-nav {
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 999;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.4);
    font-size: 17px;
    cursor: pointer;
}

.sidenav .closebtn {
    color: #ffffff;
    font-size: 24px;
    height: 35px;
    line-height: 35px;
    padding: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 35px;
    z-index: 9;
}

#main {
    transition: margin-left 0.5s;
    -moz-transition: margin-left 0.5s;
    -webkit-transition: margin-left 0.5s;
    -o-transition: margin-left 0.5s;
}

@media only screen and (max-width: 800px) {
    .sidenav.cs-sidenav {
        width: 100%;
    }
}


/*==== Particles ====*/

#particles-js .container {
    z-index: 2;
}

.particles-js-canvas-el {
    position: absolute;
    top: 0;
    z-index: 1;
}


/*==== LOADING ====*/


/* #loading-area {
    width: 100%;
    height: 100%;
    background-color: #f7ffec;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 1;
    z-index: 999999999;
    background-image: url(../images/loading.svg);
    background-repeat: no-repeat;
    background-size: 60px;
    background-position: center;
} */


/* ------------------------------------------------
30. CONTACT
------------------------------------------------ */

.dlab-social-icon.dlab-social-icon-lg li a {
    color: #ffffff;
    font-size: 14px;
    height: 35px;
    line-height: 34px;
    padding: 0;
    width: 35px;
}

.dlab-social-icon-lg.dlab-social-icon li {
    display: inline-block;
    font-size: 12px;
    padding: 0;
}

.dlab-social-icon-lg.dlab-social-icon.border li a {
    border: 1px solid rgba(255, 255, 255, 0.05);
}

.contact-style-1 input {
    height: 50px;
}

.contact-style-1 .site-button {
    height: 50px;
    width: 100%;
}

.contact-style-1 .form-control {
    padding: 10px 20px;
}

.contact-style-1 .form-control:focus,
.contact-style-1 .form-control:hover {
    border-color: #c0c0c0;
}

.contact-style-1 h2 {
    margin-top: 0;
}

.contact-style-1 .border-1 {
    border: 1px solid #eee;
}

.contact-style-1 .dlab-social-icon-lg {
    border-top: 1px solid #eeeeee;
    padding-top: 20px;
    text-align: left;
    width: 100%;
    margin: 0;
}

.contact-style-1 .dlab-social-icon-lg a {
    text-align: center;
}

.contact-style-1 .dlab-social-icon-lg li a:hover {
    background: #fff;
    color: #000;
    border: 1px solid #eee;
}

.contact-style-1 .form-group {
    margin-bottom: 25px;
}


/* ------------------------------------------------
31. SHOPPING
------------------------------------------------ */


/* 
	1.0 Checkout 
	
*/


/*===== 1.0 Checkout =====*/

.shop-form .form-control,
.shop-form .bootstrap-select .dropdown-toggle {
    border-width: 2px;
    border-radius: 0;
}

.shop-form .bootstrap-select .dropdown-toggle {
    border-width: 2px !important;
}

.shop-form p {
    font-weight: 500;
    color: #a3a3a3;
}

.shop-form textarea {
    height: 150px;
}

.shop-form .form-control:hover,
.shop-form .form-control:focus,
.shop-form .bootstrap-select:focus .dropdown-toggle,
.shop-form .bootstrap-select:hover .dropdown-toggle {
    border-color: #636363 !important;
}

.check-tbl img {
    width: 80px;
}

.check-tbl th {
    font-weight: 700;
    font-size: 15px;
    padding: 10px;
}

.check-tbl td {
    font-weight: 600;
    padding: 10px;
}

.check-tbl.table>tbody>tr>td,
.check-tbl.table>tbody>tr>th,
.check-tbl.table>tfoot>tr>td,
.check-tbl.table>tfoot>tr>th,
.check-tbl.table>thead>tr>td,
.check-tbl.table>thead>tr>th {
    vertical-align: middle;
}


/* shop-price */

.product-price,
.check-tbl .product-price,
.product-price-total,
.check-tbl .product-price-total,
.check-tbl .product-item-price {
    font-weight: 700;
}


/* Cart */

.product-item-close a {
    background: #ff3131;
    color: #fff;
    padding: 8px;
    border-radius: 30px;
}

.product-item-close a:hover {
    opacity: 0.5;
}


/* Product Item */

.item-img {
    overflow: hidden;
    position: relative;
}

.item-img img {
    width: 100%;
}

.item-info-in {
    bottom: -20%;
    padding: 15px;
    position: absolute;
    text-align: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.2s;
    opacity: 0;
}

.item-box:hover .item-info-in {
    opacity: 1;
    bottom: 0;
}

.item-info-in ul li {
    display: inline-block;
}

.item-info-in ul li a {
    color: #ffffff;
    display: inline-block;
    font-size: 18px;
    padding: 5px 10px;
}

.item-info-in ul,
.item-review {
    margin: auto;
    padding: 0;
    list-style: none;
}

.item-review li {
    display: inline-block;
    font-size: 15px;
}

.item-price del {
    font-size: 16px;
    font-weight: 400;
    opacity: 0.4;
}

.item-price {
    font-family: open sans;
    font-size: 18px;
    font-weight: 600;
}

.item-info-in.center {
    bottom: auto;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
}

.item-box:hover .item-info-in.center {
    top: 50%;
    bottom: auto;
}

.item-info-in.no-bg {
    background-color: rgba(0, 0, 0, 0);
}

.item-info-in.no-bg.tb a {
    color: #000;
}

.item-info-in.no-bg.tb a:hover {
    color: #000;
    opacity: 0.8;
}


/* Dir */

.item-info-in.left {
    width: auto;
    padding: 10px;
}

.item-info-in.left ul li {
    display: block;
}

.item-info-in.right {
    width: auto;
    padding: 10px;
    right: 0;
    left: auto;
}

.item-info-in.right ul li {
    display: block;
}

.item-info-in.top {
    width: auto;
    padding: 10px;
    top: -20%;
    left: 0;
    bottom: auto;
    height: auto;
}

.item-box:hover .item-info-in.top,
.item-img:hover .item-info-in.top,
.item-box:hover .item-info-in.top.right,
.item-img:hover .item-info-in.top.right {
    top: 0;
}

.item-img:hover .item-info-in.top,
.item-box:hover .item-info-in.top {
    bottom: auto;
}

.item-info-in.top.right ul li {
    display: block;
}

.item-info-in.top.right {
    width: auto;
    padding: 10px;
    top: -20%;
    right: 0;
    bottom: auto;
    left: auto;
}

.item-info-in.full {
    height: 100%;
}

.item-info-in.full ul li {
    display: block;
    width: 100%;
}

.item-info-in.full ul,
.item-info-in.full .center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}


/* item add */

.item-add {
    outline: 8px solid rgba(255, 255, 255, 0.5);
    outline-offset: -20px;
    position: relative;
    overflow: hidden;
}

.add-box {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
}

.add-box h2 {
    font-size: 50px;
    line-height: 50px;
    font-weight: 700;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.add-box h5 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.item-add.add-style-2 {
    outline: 8px solid rgba(0, 0, 0, 0.1);
}

.item-add.add-style-2 img {
    opacity: 0.05;
}

.item-add.add-style-2 .add-box h2,
.item-add.add-style-2 .add-box h5 {
    text-shadow: none;
}

.item-add.add-style-3 {
    outline: none;
}

.item-add.add-style-3 h5,
.item-add.add-style-3 h2 {
    text-shadow: none;
}

.item-add.add-style-3 h2 {
    font-size: 30px;
    margin: 0;
}

.item-add.add-style-3 .add-box .add-box-in {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px 30px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.item-add.add-style-3 .add-box .add-box-in.black {
    background-color: rgba(0, 0, 0, 0.8);
}

.item-add.add-style-3:hover .add-box-in {
    padding: 30px 40px;
}


/* Product Details Item */

.shop-item-rating {
    position: absolute;
    top: 0;
    right: 0;
}

.shop-item-rating span {
    display: block;
    text-align: right;
    margin-bottom: 10px;
}

.shop-item-tage span {
    font-size: 15px;
    font-weight: 600;
}

.shop-item-tage a {
    font-weight: 600;
    margin-left: 7px;
}


/* Hover Style  */

.outline-hv {
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    outline: 10px solid rgba(255, 255, 255, 0.5);
}

.item-box:hover .outline-hv,
.outline-hv:hover {
    outline: 10px solid rgba(255, 255, 255, 0.5);
    outline-offset: -30px;
}


/* Product Item */

.product-item .dlab-info {
    border-top: 1px solid #e9e9e9;
}

.product-item .dlab-box {
    border: 1px solid #e9e9e9;
    position: relative;
}

.product-item .sale:after {
    border-right: 5px solid rgba(0, 0, 0, 0);
    border-top: 4px solid #8f0000;
    bottom: -4px;
    content: "";
    position: absolute;
    right: 0;
}

.product-item .sale {
    position: absolute;
    right: -5px;
    top: 10px;
    z-index: 2;
}

.product-item .overlay-icon {
    bottom: 0px;
    top: auto;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.product-item:hover .overlay-icon {
    bottom: 40px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.product-item:hover img {
    transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.product-item .overlay-icon a i {
    background-color: #efbb20;
    color: #fff;
}

.product-item:hover .dlab-box,
.product-item:hover .dlab-info {
    border-color: #d0d0d0;
}

.add-product .dlab-info-has.bg-black {
    background-color: rgba(0, 0, 0, 0.4);
}

.add-product .dlab-info-has {
    top: 0;
}

.add-product del {
    opacity: 0.6;
}

.product-service .icon-bx-wraper {
    border: 0;
}

.product-item del {
    color: #b0b0b0;
    font-size: 18px;
    margin-top: 0;
    position: relative;
    top: -1px;
}


/* Item Widgets */

.item-widgets-box,
.item-widgets-body {
    overflow: hidden;
}

.item-widgets-box {
    margin-top: 15px;
}

.item-widgets-box:first-child {
    margin-top: 0;
}

.item-widgets-left {
    padding-right: 15px;
}

.item-widgets-left img {
    width: 80px;
}

.item-widgets-left,
.item-widgets-body,
.item-widgets-right {
    display: table-cell;
    vertical-align: middle;
}

.item-widgets-body .item-title,
.item-widgets-body .item-price del {
    font-size: 15px;
}


/* product item size */

.product-item-size .btn {
    background-color: #f3f3f3;
    border: 0px solid #e0e0e0;
    border-radius: 0;
    font-size: 14px;
    height: 40px;
    line-height: 38px;
    padding: 0;
    text-align: center;
    width: 40px;
    margin-right: 4px;
}

.product-item-size .btn.active {
    background-color: #1abc9c;
    box-shadow: none;
    color: #fff;
}

.product-item-size.size-sm .btn {
    font-size: 11px;
    height: 25px;
    line-height: 23px;
    width: 25px;
}

.product-item-size.size-lg .btn {
    font-size: 18px;
    height: 50px;
    line-height: 48px;
    width: 50px;
}


/* product-item-color */

.product-item-color .btn {
    border: 0 none;
    border-radius: 30px !important;
    height: 30px;
    opacity: 0.5;
    padding: 0;
    width: 30px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    margin-right: 5px;
}

.product-item-color .btn.active {
    border: 2px solid rgba(0, 0, 0, 0.3);
    box-shadow: none;
    opacity: 1;
}

.product-item-color.size-sm .btn {
    height: 20px;
    width: 20px;
}


/* Product Gallery */

.product-gallery.on-show-slider {
    position: relative;
}

.sticky-top,
.sticky-top>.side-bar {
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
}


/* Download File */

.download-file ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.download-file ul li {
    display: block;
    margin-bottom: 10px;
}

.download-file ul li a {
    background: #e0e0e0 none repeat scroll 0 0;
    color: #000000;
    display: block;
    padding: 15px 20px;
}

.download-file ul li a span {
    margin-right: 10px;
}


/* =============================
	Spacing End Alignment	
============================= */

.site-footer .widget_getintuch {
    padding-top: 0;
}

.content-inner {
    padding-top: 80px;
    padding-bottom: 50px;
}

.content-inner-1 {
    padding-top: 80px;
    padding-bottom: 0px;
}

.content-inner-2 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.testimonial-five .owl-controls {
    margin-top: 0px;
}

.dis-tbl {
    display: table;
}

.dis-tbl-cell {
    display: table-cell;
    vertical-align: middle;
}

.dis-tbl-cell-t {
    display: table-cell;
    vertical-align: top;
}

.dis-tbl-cell-b {
    display: table-cell;
    vertical-align: bottom;
}

.img-cover {
    object-fit: cover;
    width: 100%;
}


/* sp */

.row.sp20,
.sp20 {
    margin-left: -9px;
    margin-right: -9px;
}

.row.sp20 [class*="col-"],
.sp20 [class*="col-"] {
    padding-left: 9px;
    padding-right: 9px;
}

.row.sp15,
.sp15 {
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.row.sp15 [class*="col-"],
.sp15 [class*="col-"] {
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.row.sp10,
.sp10 {
    margin-left: -5px;
    margin-right: -5px;
}

.row.sp10 [class*="col-"],
.sp10 [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}


/* Mobile Spacing */

@media only screen and (max-width: 991px) {
    .sticky-top {
        position: unset;
    }
}

@media only screen and (max-width: 767px) {
    .content-area {
        padding-top: 30px;
    }
    .page-content {
        padding-bottom: 30px;
    }
    .footer-top {
        padding-top: 30px;
    }
    .site-footer .widget {
        margin-bottom: 25px;
    }
    .section-full:last-child {
        margin-bottom: -30px;
    }
    .content-inner {
        padding-bottom: 20px;
        padding-top: 30px;
    }
    .content-inner-1 {
        padding-top: 30px;
        padding-bottom: 0px;
    }
    .content-inner-2 {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .section-head {
        margin-bottom: 25px;
    }
    .dlab-project-left {
        padding-top: 30px;
    }
    .testimonial-section {
        padding-top: 30px;
    }
    .testimonial-section .owl-controls {
        margin-top: 20px;
    }
    .section-head p {
        padding-top: 0;
    }
    .h3 {
        font-size: 28px;
        margin-top: 10px;
    }
    .about-service .icon-bx-wraper:nth-child(2) {
        margin-top: 0;
    }
    .side-bar {
        margin-bottom: 30px;
    }
    .dlab-post-title .post-title {
        margin-top: 10px;
    }
    .footer-info-box .newsletter {
        margin-bottom: 10px;
    }
    ol.commentlist li .star-rating {
        position: unset;
    }
    .comment-form [class*="comment-form"] {
        width: 100%;
    }
    .pricingtable-wrapper {
        margin-bottom: 30px;
    }
    .pricingtable-highlight {
        margin: 0;
    }
    .disnone-md {
        display: none;
    }
}


/* Owl Slider */

.slide-item {
    position: relative;
}

.slide-item .slide-content {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.slide-item-img img {
    object-fit: cover;
}

.slide-content-box {
    position: relative;
    top: 30%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transition: all 1s ease 0.5s;
    -webkit-transition: all 1s ease 0.5s;
    -o-transition: all 1s ease 0.5s;
    -moz-transition: all 1s ease 0.5s;
}

.owl-slider-banner .owl-theme .owl-dots {
    margin-top: 0 !important;
    position: absolute;
    right: 50px;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 25px;
    color: rgba(255, 255, 255, 0.5);
}

.owl-slider-banner .owl-theme .owl-dots .owl-dot span {
    height: 9px;
    width: 9px;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.2);
}

.owl-slider-banner .owl-theme .owl-dots .owl-dot {
    border: 1px solid;
    border-radius: 20px;
    display: inline-block;
    margin: 4px;
    padding: 4px !important;
}

.owl-slider-banner .owl-theme .owl-dots .owl-dot.active,
.owl-slider-banner .owl-theme .owl-dots .owl-dot:hover,
.owl-slider-banner .owl-theme .owl-dots .owl-dot:focus {
    border: 1px solid #ff3b68;
    outline: none;
}

.owl-slider-banner .owl-item .slide-content-box {
    opacity: 0;
}

.owl-slider-banner .owl-item.active .slide-content-box {
    opacity: 1;
    transition: all 0.5s ease 0.5s;
    -moz-transition: all 0.5s ease 0.5s;
    -webkit-transition: all 0.5s ease 0.5s;
    -o-transition: all 0.5s ease 0.5s;
    top: 50%;
}


/* Shop Categories */

.shop-categories .acod-head a {
    border-width: 0 0 2px;
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 0;
    text-transform: uppercase;
    border-radius: 0;
}

.shop-categories .acod-body {
    border-width: 0;
}

.shop-categories .acod-content {
    margin: 15px 0 15px;
}

.shop-categories .collapsed {}

.shop-categories .acod-head a.collapsed:after,
.shop-categories .acod-head a:after {
    font-family: open sans;
    font-weight: 700;
    color: #000;
    font-size: 18px;
    right: 5px;
}

.shop-categories .acod-head a:after {
    content: "--";
}

.shop-categories .acod-head a.collapsed:after {
    content: "+";
}

.product-brand .search-content {
    display: block;
    margin-bottom: 0;
    padding: 10px 0;
}

.product-brand .search-content .search-content-area {
    margin-bottom: 0;
}


/* Price */

.price .amount {
    border: 0 none;
    color: #404040;
    font-weight: 700;
    margin-bottom: 15px;
    padding: 0;
    text-align: left;
    width: 100%;
}

.price .ui-widget-content {
    margin: 0 8px;
}

.container-fluid.wide {
    padding-left: 30px;
    padding-right: 30px;
}


/* Shop Account */

.shop-account .form-control {
    background: #efefef none repeat scroll 0 0;
    border: 0 none;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.05);
    font-size: 14px;
    height: 50px;
    padding: 0 20px;
}

.shop-account .site-button.button-lg {
    font-size: 14px;
    font-weight: 700;
    padding: 14px 30px;
    text-transform: uppercase;
}

.shop-account .border-1 {
    border-color: #dfdfdf;
}

.shop-account .form-control:focus {
    box-shadow: 2px 2px 14px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
}


/* slider btn style */

.errow-style-1 .tparrows:before {
    font-size: 60px;
    opacity: 0.7;
}

.errow-style-1 .tparrows:before:hover {
    opacity: 1;
}

.errow-style-1 .tparrows,
.errow-style-1 .tparrows:hover {
    background: rgba(0, 0, 0, 0);
}

@media only screen and (max-width: 1200px) {
    .owl-slider.style-1 .slide-content-box h2 {
        font-size: 28px;
        line-height: 32px;
    }
    .owl-slider.style-1 .slide-content-box p {
        display: none;
    }
    .owl-slider.style-1 .slide-content-box .button-lg {
        padding: 10px 20px;
    }
}

@media only screen and (max-width: 767px) {
    .owl-slider.style-1 .slide-content-box h2 {
        font-size: 18px;
        line-height: 22px;
    }
    .owl-slider.style-1 .slide-content-box .button-lg {
        display: none;
    }
    .owl-slider-banner .owl-theme .owl-dots {
        right: 15px;
    }
}

.btn-play:after,
.btn-play:before {
    content: "";
    border: 1px solid;
    border-color: inherit;
    width: 150%;
    height: 150%;
    -webkit-border-radius: 100%;
    border-radius: 100%;
    position: absolute;
    left: -25%;
    top: -25%;
    opacity: 1;
    -webkit-animation: 1s videomodule-anim linear infinite;
    animation: 1s videomodule-anim linear infinite;
}

.btn-play:before {
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

.btn-play .icon {
    color: #505cfd !important;
}

.btn-play {
    position: relative;
}

@media screen and (max-width: 1200px) {
    .bg-md-hd {
        background-image: none !important;
    }
}

@media screen and (max-width: 768px) {
    .btn-play {
        margin-top: 0px;
    }
    .bg-xs-hd {
        background-image: none !important;
    }
}

@-webkit-keyframes videomodule-anim {
    0% {
        -webkit-transform: scale(0.68);
        transform: scale(0.68);
    }
    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }
}

@keyframes videomodule-anim {
    0% {
        -webkit-transform: scale(0.68);
        transform: scale(0.68);
    }
    100% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
        opacity: 0;
    }
}


/* Rev Slider */

.short-edit-box {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.08) inset;
    padding: 8px;
    margin-bottom: 15px;
}

.short-edit-box h5 a {
    color: #fff;
    font-weight: 700;
}


/* .site-filters a */

.site-filters .filter-curs .owl-item {
    margin-left: -1px;
}

.site-filters .filter-curs {
    overflow: hidden;
}

.site-filters .filter-curs a {
    background: #2d2d2d none repeat scroll 0 0;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    color: #ffffff;
    display: block;
    margin: 0;
    padding: 10px 15px;
    opacity: 0.8;
}

.site-filters .filter-curs li {}

.site-filters .filter-curs li.active a {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1ebbf0+8,39dfaa+100 */
    background: #1ebbf0;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    /* FF3.6-15 */
    background: -Winkit-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1ebbf0', endColorstr='#39dfaa', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    opacity: 1;
    box-shadow: 2px 2px 4px 0 rgba(53, 218, 179, 0.5);
}


/*  Trigger tTop */

.filter-curs.owl-btn-center-lr .owl-nav .owl-prev,
.filter-curs.owl-btn-center-lr .owl-nav .owl-next {
    background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    color: #000000;
    font-size: 28px;
    height: 60px;
    line-height: 60px;
    margin: 0 -10px;
    padding: 0;
    width: 60px;
}

.filter-curs.owl-btn-center-lr .owl-nav .owl-prev:hover,
.filter-curs.owl-btn-center-lr .owl-nav .owl-next:hover {
    background: #fff;
}

.trigger-top+.page-wraper {
    padding-top: 50px;
    background-color: #fff;
}

.trigger-top {
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    z-index: 999;
}

.buy-now {
    float: right;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding: 6px 15px;
}

.buy-now a {
    background-color: #00bb27;
    border-radius: 2px;
    font-weight: 700;
    padding: 9px 30px;
    text-transform: uppercase;
}

.buy-now a:hover {}

.trigger-logo {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    height: 50px;
    padding: 10px 15px;
    width: 115px;
}

.trigger-logo,
.trigger-down {
    float: left;
}

.trigger-down {
    width: 300px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.trigger-down .bootstrap-select .dropdown-toggle.btn {
    border: 0 !important;
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    padding: 10px 20px;
}

.trigger-down .bootstrap-select.btn-group .dropdown-toggle .caret {
    border: 0 none;
    height: 15px;
    opacity: 1;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    width: 15px;
}

.trigger-down .bootstrap-select.btn-group .dropdown-toggle .caret:after {
    font-family: "themify";
    content: "\e64b";
    font-size: 15px;
    font-weight: 100;
}


/* Animation */

.animation-effects .wow-effects {
    display: table;
    margin: 0 auto 30px;
    padding: 0 15px;
    float: left;
    width: 50%;
}

.animation-effects .wow-effects .wow {
    background: #f0f0f0 none repeat scroll 0 0;
    border-radius: 4px;
    color: #505050;
    display: table-cell;
    font-size: 18px;
    font-weight: 600;
    height: 200px;
    text-align: center;
    text-transform: capitalize;
    vertical-align: middle;
}


/* Code Labs */

.code-lab {
    border-bottom: 2px solid #c0c0c0;
    border-radius: 2px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1) inset;
    margin: 0 auto auto;
    min-height: 400px;
    overflow: hidden;
    padding: 20px;
    position: relative;
    width: 100%;
}

.code-lab .container {
    min-height: 400px;
    position: relative;
}

.site-button.live-demo {
    background: #20b995 none repeat scroll 0 0;
    border-radius: 2px;
    bottom: 0px;
    box-shadow: 4px 4px 8px 0 rgba(53, 217, 81, 0.2);
    font-size: 12px;
    font-weight: 700;
    padding: 8px 15px;
    position: absolute;
    right: 15px;
    text-transform: uppercase;
}

.ui-resizable:after,
.ui-resizable:before {
    background: #606060 none repeat scroll 0 0;
    bottom: 3px;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    width: 30px;
    z-index: 1;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}

.ui-resizable:before {
    width: 20px;
    bottom: 8px;
    height: 1px;
}

@media screen and (max-width: 767px) {
    .buy-now a {
        padding: 10px 10px;
        font-size: 12px;
    }
    .trigger-logo {
        padding: 15px 10px;
        width: 80px;
    }
    .trigger-down .bootstrap-select .dropdown-toggle.btn {
        padding: 10px 10px;
        font-size: 12px;
    }
    .trigger-down {
        border-right: 0;
        width: 160px;
    }
}


/* portfolio */

.portfolio .icon-bx-xs {
    border-radius: 50%;
}

.portfolio .icon-bx-xs {
    width: auto;
    height: auto;
    line-height: unset;
    background: transparent;
    font-size: inherit;
}

.portfolio .portfolio-fullscreen {
    font-size: 30px;
    position: absolute;
    right: 15px;
    bottom: 15px;
    line-height: 30px;
    color: #fff;
    opacity: 0.5;
}

.portfolio .portfolio-fullscreen:hover {
    opacity: 1;
}

.portfolio-mas a,
.portfolio-ic a {
    color: #000;
}

.portfolio-mas .dez-info a:hover,
.portfolio-ic .dez-info a:hover {
    color: #24c4dd;
}

.portfolio-mas a:hover,
.portfolio-ic a:hover {
    color: #fff;
}

.portfolio-mas a:hover i,
.portfolio-ic a:hover i {
    background: transparent;
    border: 2px solid #fff;
}

.portfolio-mas .overlay-icon a,
.portfolio-ic .overlay-icon a {
    margin: 0px 7px;
}

.portfolio .portfolio-metro .dlab-img-overlay1::before {
    width: 90%;
    height: 90%;
    opacity: 0;
    top: 5%;
    left: 5%;
}

.portfolio .owl-prev:hover,
.portfolio .owl-next:hover,
.portfolio .owl-prev,
.portfolio .owl-next {
    background-color: #fff;
    color: #000;
    padding: 17px 17px;
}

.port-style1 .overlay-icon {
    padding: 15px;
}

.port-style1 .port-box {
    padding-left: 10px;
    border-left: 3px solid #1abc9c;
}

.port-style1 .align-m {
    text-align: center;
}

.port-style1 .overlay-icon p {
    margin-bottom: 0;
}


/* .g-recaptcha {
  transform-origin: left top;
} */


/* Google Recaptcha */

.g-recaptcha,
#rc-imageselect {
    transform: scale(0.99);
    -webkit-transform: scale(0.99);
    -moz-transform: scale(0.99);
    -o-transform: scale(0.99);
    transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}

@media screen and (max-width: 575px) {
    #rc-imageselect,
    .g-recaptcha {
        transform: scale(0.77);
        -moz-transform: scale(0.77);
        -o-transform: scale(0.77);
        -webkit-transform: scale(0.77);
        transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -o-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}

.recaptcha-box {
    height: 60px;
}

.g-recaptcha>div {
    width: 250px !important;
}


/* Google Recaptcha */

@media screen and (max-width: 991px) {
    .mo-left .header-nav.nav-dark {
        background-color: #202020;
    }
    .mo-left .header-nav {
        overflow: scroll;
        position: fixed;
        width: 60px;
        left: -280px;
        height: 100% !important;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        top: 0;
        background-color: #fff;
        margin: 0;
        z-index: 99;
    }
    .mo-left .header-nav li.open a {
        position: relative;
    }
    .navbar-nav {
        overflow-x: unset;
        height: 100%;
    }
    .mo-left .header-nav.show {
        left: -1px;
        transition: all 0.8s;
        -webkit-transition: all 0.8s;
        -moz-transition: all 0.8s;
        -o-transition: all 0.8s;
        margin: 0;
        width: 280px;
    }
    .mo-left .is-fixed .header-nav .nav {
        height: auto;
    }
    .mo-left .navbar-toggler.open:after {
        background-color: rgba(0, 0, 0, 0.6);
        content: "";
        height: 100%;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        transform: scale(100);
        -o-transform: scale(100);
        -moz-transform: scale(100);
        -webkit-transform: scale(100);
        width: 100%;
        z-index: -1;
        transition: all 0.5s;
    }
    .mo-left .header-nav .logo-header {
        display: block;
        float: none;
        height: auto;
        max-width: 100%;
        padding: 30px 15px;
        width: 100%;
    }
    .mo-left .header-nav .logo-header img {
        max-width: unset;
        width: 130px;
    }
    .mo-left .navbar-toggler.open span {
        background: #fff;
    }
}


/* Plugine box */

.plugine-box {}


/* 	Hover Effects 
	> Box Shadow Hover

*/

.fly-box-ho,
.fly-box,
.fly-box-ho:hover,
.fly-box:hover,
.hwp {
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

.fly-box {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
}

.icon-bx-wraper:hover .fly-box-ho,
.fly-box-ho:hover,
.icon-bx-wraper:hover .fly-box,
.fly-box:hover {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    -o-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.hwp {
    background-color: #fff;
    color: #1abc9c;
}

.hwp a {
    color: #1abc9c;
}

.icon-bx-wraper:hover .hwp,
.hwp:hover {
    background-color: #1abc9c;
    color: #fff;
}

.icon-bx-wraper:hover .hwp a,
.hwp:hover a {
    color: #fff;
}


/* nav */

.special-tabs ul {
    padding-bottom: 60px;
}

.header-nav .nav>li .mega-menu>li.special-nav {
    bottom: 0;
    clear: both;
    display: block;
    float: left;
    left: 0;
    position: absolute;
    width: 100%;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0da8dc+8,1abc9c+100 */
    background: #0da8dc;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #0da8dc 8%, #1abc9c 100%);
    /* FF3.6-15 */
    background: -Winkit-linear-gradient(45deg, #0da8dc 8%, #1abc9c 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #0da8dc 8%, #1abc9c 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#0da8dc', endColorstr='#1abc9c', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    border-radius: 0 0px 9px 9px;
    padding: 0;
}

.header-nav .nav>li .mega-menu>li.special-nav ul {
    margin-top: 0;
}

.header-nav .nav>li .mega-menu>li.special-nav ul li {
    width: 20%;
    float: left;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0;
}

.line-height {
    background-color: #6cc000 !important;
    justify-content: center;
    align-items: center;
    padding: 11px;
}


/* .toggleContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
} */

.toggleContainer .toggleOption {
    line-height: normal;
    height: auto;
    justify-content: center;
    align-items: center;
    padding: 11px;
}


/* .toggle{
    transform:translateX()
} */

.header-nav .nav>li .mega-menu>li.special-nav ul li a {
    border: 0 none;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: 300;
    vertical-align: middle;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    padding: 20px;
}

.header-nav .nav>li .mega-menu>li.special-nav ul li a i {
    font-size: 30px;
    margin-right: 10px;
    width: auto;
}

.header-nav .nav>li .mega-menu>li.special-nav ul li a span {
    float: right;
}

.header-nav .nav>li .mega-menu>li.special-nav ul li a span i {
    font-size: 15px;
}

.header-nav .nav>li .mega-menu>li.special-nav ul li a {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
}

.header-nav .nav>li .mega-menu>li.special-nav ul li a:hover {
    background-color: rgba(0, 0, 0, 0);
}

.special-nav ul li a:after {
    color: #000;
    content: "";
    width: 0;
    opacity: 0;
    height: 100%;
    background: #fff;
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.special-nav ul li a:hover:after {
    width: 100%;
    opacity: 0.2;
}

.faa-horizontal.animated {
    -webkit-animation: horizontal 5s ease infinite;
    animation: horizontal 5s ease infinite;
}

@media screen and (max-width: 1200px) {
    .header-nav .nav>li .mega-menu>li.special-nav ul li a {
        font-size: 14px;
    }
    .header-nav .nav>li .mega-menu>li.special-nav ul li a i {
        font-size: 16px;
    }
}

@media screen and (max-width: 991px) {
    .special-tabs ul {
        padding-bottom: 0;
    }
}

@keyframes horizontal {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    6% {
        -webkit-transform: translate(20px, 0);
        transform: translate(20px, 0);
    }
    12% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    18% {
        -webkit-transform: translate(20px, 0);
        transform: translate(20px, 0);
    }
    24% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    30% {
        -webkit-transform: translate(20px, 0);
        transform: translate(20px, 0);
    }
    100%,
    36% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@media screen and (max-width: 991px) {
    .header-nav .nav>li .mega-menu>li.special-nav {
        display: none;
    }
}


/* Support and Buy Button */

.theme-btn {
    background-color: #ffffff;
    border-radius: 40px;
    bottom: 10px;
    color: #ffffff;
    display: table;
    height: 50px;
    left: 10px;
    min-width: 50px;
    position: fixed;
    text-align: center;
    z-index: 9999;
}

.theme-btn i {
    font-size: 22px;
    line-height: 50px;
}

.theme-btn.bt-support-now {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1ebbf0+8,39dfaa+100 */
    background: #1ebbf0;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1ebbf0', endColorstr='#39dfaa', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    bottom: 70px;
}

.theme-btn.bt-buy-now {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1fdf61+0,88BA46+100 */
    background: #1fdf61;
    /* Old browsers */
    background: -moz-linear-gradient(top, #a3d179 0%, #88ba46 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #a3d179 0%, #88ba46 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #a3d179 0%, #88ba46 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1fdf61', endColorstr='#88BA46', GradientType=0);
    /* IE6-9 */
}

.theme-btn:hover {
    color: #fff;
    padding: 0 20px;
}

.theme-btn span {
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
    letter-spacing: -15px;
    opacity: 0;
    line-height: 50px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    text-transform: uppercase;
}

.theme-btn:hover span {
    opacity: 1;
    letter-spacing: 1px;
    padding-left: 10px;
}

.at-expanding-share-button[data-position="bottom-left"] {
    bottom: 130px !important;
}


/* Support and Buy Button END */

.dzSubscribeMsg p {
    margin: 0;
    position: absolute;
    top: 15px;
}


/* ================================
	Shop Filter Mobile 
================================ */

.filter-mobile {
    display: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
    text-align: center;
    position: sticky;
    top: 80px;
    background: #fff;
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.2);
}

.filter-mobile .filter-bx:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.filter-mobile .filter-bx {
    width: 50%;
    padding: 10px;
}

.filter-btn {
    color: #404040;
    font-weight: 600;
    border: 2px solid rgba(0, 0, 0, 0.1);
    padding: 3px 10px;
    border-radius: 4px;
}

.filter-btn:hover {
    background-color: #000;
    color: #fff;
}

.filter-btn i {
    margin-right: 5px;
}

.filter-bx .bootstrap-select .dropdown-toggle {
    max-width: 100px;
}

.filter-bx .bootstrap-select .dropdown-toggle {
    border: 2px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px;
    padding: 2px 10px;
    color: #404040;
    font-weight: 600;
    height: 32px;
}

.filter-bx .bootstrap-select.btn-group .dropdown-toggle .caret {
    top: 9px;
}

.filter-btn.close {
    display: none;
    opacity: 0;
}

@media only screen and (max-width: 991px) {
    .item-info-in ul li a {
        font-size: 14px;
        padding: 5px;
    }
    .item-review li {
        font-size: 13px;
    }
    .filter-on .filter-btn.close {
        border: 0;
        z-index: 1;
        position: fixed;
        right: 10px;
        top: 10px;
        color: #fff;
        opacity: 1;
        font-size: 18px;
        display: block;
        transition: all 0.5s;
        -moz-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        opacity: 1;
    }
    .filter-btn.close:hover {
        background: rgba(255, 255, 255, 0.1);
    }
    .filter-mobile {
        display: flex;
        margin-bottom: 30px;
    }
    .filter-left {
        padding: 20px 20px;
        width: 280px;
        position: fixed !important;
        left: -310px;
        top: 0;
        background: #fff;
        z-index: 9999999;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
        overflow-y: scroll;
        height: 100%;
        transition: all 0.5s;
        -moz-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
    }
    .filter-left.filter-on {
        left: 0;
    }
    .filter-bar {
        top: 80px;
        position: sticky;
        z-index: 999;
    }
    .filter-bar.sticky-top .side-bar {
        z-index: 9;
        top: 0;
        position: unset;
    }
    .sticky-top.filter-bar {
        z-index: 99999999;
        top: 80px;
        position: sticky;
    }
    .filter-left:after {
        content: "";
        position: fixed;
        width: 0;
        background: #000;
        height: 100%;
        z-index: -1;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all 0.5s;
        -moz-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
    }
    .filter-left.filter-on:after {
        width: 1000px;
        left: 280px;
        opacity: 0.8;
    }
}

@media only screen and (max-width: 767px) {
    .sp10-sm .row,
    .row.sp10-sm,
    .sp10-sm {
        margin-left: -8px;
        margin-right: -8px;
    }
    .row.sp10-sm [class*="col-"],
    .sp10-sm [class*="col-"] {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.header-nav .nav>li .mega-menu>li.special-nav ul li a {
    border: 0 none;
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-weight: 300;
    vertical-align: middle;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    padding: 20px;
}

.header-nav .nav>li .mega-menu>li.special-nav ul li a i {
    font-size: 30px;
    margin-right: 10px;
    width: auto;
}

.header-nav .nav>li .mega-menu>li.special-nav ul li a span {
    float: right;
}

.header-nav .nav>li .mega-menu>li.special-nav ul li a span i {
    font-size: 15px;
}

.header-nav .nav>li .mega-menu>li.special-nav ul li a {
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
}

.header-nav .nav>li .mega-menu>li.special-nav ul li a:hover {
    background-color: rgba(0, 0, 0, 0);
}

.special-nav ul li a:after {
    color: #000;
    content: "";
    width: 0;
    opacity: 0;
    height: 100%;
    background: #fff;
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 0px;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
}

.special-nav ul li a:hover:after {
    width: 100%;
    opacity: 0.2;
}

.faa-horizontal.animated {
    -webkit-animation: horizontal 5s ease infinite;
    animation: horizontal 5s ease infinite;
}

@media screen and (max-width: 1200px) {
    .header-nav .nav>li .mega-menu>li.special-nav ul li a {
        font-size: 14px;
    }
    .header-nav .nav>li .mega-menu>li.special-nav ul li a i {
        font-size: 16px;
    }
}

@media screen and (max-width: 991px) {
    .special-tabs ul {
        padding-bottom: 0;
    }
}

@keyframes horizontal {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    6% {
        -webkit-transform: translate(20px, 0);
        transform: translate(20px, 0);
    }
    12% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    18% {
        -webkit-transform: translate(20px, 0);
        transform: translate(20px, 0);
    }
    24% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    30% {
        -webkit-transform: translate(20px, 0);
        transform: translate(20px, 0);
    }
    100%,
    36% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@media screen and (max-width: 991px) {
    .header-nav .nav>li .mega-menu>li.special-nav {
        display: none;
    }
}


/* Support and Buy Button */

.theme-btn {
    background-color: #ffffff;
    border-radius: 40px;
    bottom: 10px;
    color: #ffffff;
    display: table;
    height: 50px;
    left: 10px;
    min-width: 50px;
    position: fixed;
    text-align: center;
    z-index: 9999;
}

.theme-btn i {
    font-size: 22px;
    line-height: 50px;
}

.theme-btn.bt-support-now {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1ebbf0+8,39dfaa+100 */
    background: #1ebbf0;
    /* Old browsers */
    background: -moz-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(45deg, #1ebbf0 8%, #39dfaa 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1ebbf0', endColorstr='#39dfaa', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    bottom: 70px;
}

.theme-btn.bt-buy-now {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1fdf61+0,88BA46+100 */
    background: #1fdf61;
    /* Old browsers */
    background: -moz-linear-gradient(top, #a3d179 0%, #88ba46 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #a3d179 0%, #88ba46 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #a3d179 0%, #88ba46 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1fdf61', endColorstr='#88BA46', GradientType=0);
    /* IE6-9 */
}

.theme-btn:hover {
    color: #fff;
    padding: 0 20px;
}

.theme-btn span {
    display: table-cell;
    vertical-align: middle;
    font-size: 16px;
    letter-spacing: -15px;
    opacity: 0;
    line-height: 50px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    text-transform: uppercase;
}

.theme-btn:hover span {
    opacity: 1;
    letter-spacing: 1px;
    padding-left: 10px;
}

.at-expanding-share-button[data-position="bottom-left"] {
    bottom: 130px !important;
}


/* Support and Buy Button END */

.dzSubscribeMsg p {
    margin: 0;
    position: absolute;
    top: 15px;
}


/* ================================
	Shop Filter Mobile 
================================ */

.filter-mobile {
    display: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
    text-align: center;
    position: sticky;
    top: 80px;
    background: #fff;
    box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.2);
}

.filter-mobile .filter-bx:first-child {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.filter-mobile .filter-bx {
    width: 50%;
    padding: 10px;
}

.filter-btn {
    color: #404040;
    font-weight: 600;
    border: 2px solid rgba(0, 0, 0, 0.1);
    padding: 3px 10px;
    border-radius: 4px;
}

.filter-btn:hover {
    background-color: #000;
    color: #fff;
}

.filter-btn i {
    margin-right: 5px;
}

.filter-bx .bootstrap-select .dropdown-toggle {
    max-width: 100px;
}

.filter-bx .bootstrap-select .dropdown-toggle {
    border: 2px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 4px;
    padding: 2px 10px;
    color: #404040;
    font-weight: 600;
    height: 32px;
}

.filter-bx .bootstrap-select.btn-group .dropdown-toggle .caret {
    top: 9px;
}

.filter-btn.close {
    display: none;
    opacity: 0;
}

@media only screen and (max-width: 991px) {
    .item-info-in ul li a {
        font-size: 14px;
        padding: 5px;
    }
    .item-review li {
        font-size: 13px;
    }
    .filter-on .filter-btn.close {
        border: 0;
        z-index: 1;
        position: fixed;
        right: 10px;
        top: 10px;
        color: #fff;
        opacity: 1;
        font-size: 18px;
        display: block;
        transition: all 0.5s;
        -moz-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        opacity: 1;
    }
    .filter-btn.close:hover {
        background: rgba(255, 255, 255, 0.1);
    }
    .filter-mobile {
        display: flex;
        margin-bottom: 30px;
    }
    .filter-left {
        padding: 20px 20px;
        width: 280px;
        position: fixed !important;
        left: -310px;
        top: 0;
        background: #fff;
        z-index: 9999999;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
        overflow-y: scroll;
        height: 100%;
        transition: all 0.5s;
        -moz-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
    }
    .filter-left.filter-on {
        left: 0;
    }
    .filter-bar {
        top: 80px;
        position: sticky;
        z-index: 999;
    }
    .filter-bar.sticky-top .side-bar {
        z-index: 9;
        top: 0;
        position: unset;
    }
    .sticky-top.filter-bar {
        z-index: 99999999;
        top: 80px;
        position: sticky;
    }
    .filter-left:after {
        content: "";
        position: fixed;
        width: 0;
        background: #000;
        height: 100%;
        z-index: -1;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all 0.5s;
        -moz-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
    }
    .filter-left.filter-on:after {
        width: 1000px;
        left: 280px;
        opacity: 0.8;
    }
}

@media only screen and (max-width: 767px) {
    .sp10-sm .row,
    .row.sp10-sm,
    .sp10-sm {
        margin-left: -8px;
        margin-right: -8px;
    }
    .row.sp10-sm [class*="col-"],
    .sp10-sm [class*="col-"] {
        padding-left: 8px;
        padding-right: 8px;
    }
}